import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import { clearChats } from "store/messaging/messagingSlice";
import { ReactComponent as Plus } from "assets/Add_Plus_Circle.svg";
import { setLoginModalVisible } from "store/auth/authSlice";
import { resetPage } from "store/community/communitySlice";
import SearchBar from "components/Search";
import Sidebar from "components/SideBar";
import {
  getCommunityList,
  getPrivateChatList,
  joinCommunity,
} from "store/community/action";

import miscsStyles from "styles/miscs/miscs.module.scss";
import ImageHolder from "components/ImagePlaceholder";
import ChatModal from "components/ChatModal";
import Loader from "components/Loader";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
import CommunicationChatModal from "components/CommunicationChatModal";
import { resetCommunityChatNotifications, resetPrivateChatNotifications } from "store/notifications/notificationSlice";


interface Chat {
  _id: string;
  user: {
    _id: string;
    username: string;
    profilePicture: string;
  };
  latest: {
    user: string;
    message: string;
    _id: string;
    time: string;
    giftId?: Number;
    balance?: Number;
  };
}

export default function CommunicationScreen({ }) {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = useAppSelector((state: RootState) => state.auth);
  const user = useAppSelector((state: RootState) => state.user?.user);
  const authUser = useAppSelector((state: RootState) => state.auth);
  const { unreadNotifications } = useAppSelector((state: RootState) => state.notification);
  const [selected, setSelected] = useState("COMMUNITIES");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [chatSearchResults, setChatSearchResults] = useState<any[]>([]);
  const [communitySearchResults, setCommunitySearchResults] = useState<any[]>(
    []
  );
  const { communityId } = useParams();
  const { chatList, loader } = useAppSelector(
    (state: RootState) => state.community
  );
  const communityList = useAppSelector(
    (state: RootState) => state.community.communities
  );
  const communityPage = useAppSelector(
    (state: RootState) => state.community.page
  );
  const chatPage = useAppSelector(
    (state: RootState) => state.community.chatPage
  );
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const unreadCommunities = communityList.filter((community) =>
    unreadNotifications?.comminityNotifications?.some(
      (unread) => unread.communityId === community._id
    )
  );

  const prioritizedCommunities = [
    ...unreadCommunities,
    ...communityList.filter(
      (community) =>
        !unreadNotifications?.comminityNotifications?.some(
          (unread) => unread.communityId === community._id
        )
    ),
  ];

  const unreadPrivateChats = chatList.filter((chat) =>
    unreadNotifications?.privateNotifications?.some(
      (unread) => unread.senderId === chat?.user?._id
    )
  );

  const prioritizedChats = [
    ...unreadPrivateChats,
    ...chatList.filter(
      (chat) =>
        !unreadNotifications?.privateNotifications?.some(
          (unread) => unread.senderId === chat?.user?._id
        )
    ),
  ];

  useEffect(() => {
    const communityData: any = communityList.filter(
      (data) => data._id === communityId
    );
    if (communityId) {
      setSelectedChat(communityData[0]);
      setModalOpen(true);
    }
  }, [communityId]);

  useEffect(() => {
    dispatch(getPrivateChatList(1));
    dispatch(getCommunityList(1));
  }, [dispatch, selectedChat, modalOpen]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(getPrivateChatList(value));
    dispatch(getCommunityList(value));
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const handleSearchChats = (matchItem: any) => {
    dispatch(clearChats());
    setSelectedChat(matchItem);
    setModalOpen(true);
  };

  const handleSearchCommunities = (matchItem: any) => {
    if (!matchItem.joined)
      dispatch(
        joinCommunity({
          payload: matchItem._id,
          callback: () => {
            setSelectedChat(matchItem);
            setModalOpen(true);
          },
        })
      );
    else {
      setSelectedChat(matchItem);
      setModalOpen(true);
    }
  };

  function formatTimeDifference(timestamp: string): string {
    const now = new Date();
    const messageTime = new Date(timestamp);
    const diffInMilliseconds = now.getTime() - messageTime.getTime();

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
    } else {
      return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"
        } ago`;
    }
  }

  const renderChats = (chat: Chat, index: number) => {
    const hasUnreadMessages = unreadNotifications.privateNotifications?.filter((item) => item?.senderId === chat?.user?._id);
    const isGiftedChat = chat?.latest?.giftId;
    return (
      <div
        className="flex flex-row w-full rounded-xl bg-borderColor mt-4 px-5 py-2 hover:ring-1 hover:ring-primary"
        key={chat._id}
        onClick={() => {
          setSelectedChat(chat);
          setModalOpen(true);
          dispatch(resetPrivateChatNotifications(chat.user._id));
        }}
      >
        <div className="flex flex-row max-md:w-full items-center justify-between gap-5 ">
          <ImageHolder
            className="flex w-[50px] h-[50px] rounded-full p-1"
            uri={chat.user?.profilePicture}
          />
          <div className="flex flex-col max-md:w-full w-[20vw]">
            <div className="flex flex-row w-full justify-between">
              <span className="text-primary text-sm">{chat.user?.username}</span>
              <span className="text-darkGray text-right text-xs">
                {formatTimeDifference(chat.latest?.time)}
              </span>
            </div>
            {isGiftedChat ? (<span className="text-white text-xs">
              {`${chat.user.username} has sent you ...`}
            </span>) :
              (<span className="text-white text-xs">
                {chat.latest?.message?.slice(0, 25)}
                {chat.latest?.message?.length > 25 && '...'}
              </span>)}
          </div>
          {hasUnreadMessages && hasUnreadMessages?.length > 0 &&
            <div className=" bg-red-600 rounded-full w-5 h-5 items-center justify-center flex mb-5 ml-5">
              <span className="text-primary">{hasUnreadMessages?.length}</span>
            </div>}
        </div>
      </div>
    );
  };

  const renderCommunities = (community: any, index: number) => {
    const hasUnreadMessages = unreadNotifications.comminityNotifications?.filter((item) => item.communityId === community._id);
    return (
      <div
        className="flex flex-row w-full rounded-xl bg-borderColor mt-4 px-4 py-2 hover:ring-1 hover:ring-primary"
        key={community._id}
        onClick={() => {
          if (!userAuth.userToken) return dispatch(setLoginModalVisible(true));
          setSelectedChat(community);
          setModalOpen(true);
          dispatch(resetCommunityChatNotifications(community._id));
        }}
      >
        <div className="flex flex-row items-center justify-between gap-5 ">
          <ImageHolder
            className="flex w-[70px] h-[50px] rounded-xl p-1"
            uri={community?.coverImage}
          />
          <div className="flex flex-col max-md:w-full w-[20vw]">
            <div className="flex flex-row w-full justify-between">
              <span className="text-white text-sm">{community.name}</span>
              <span className="text-darkGray text-xs text-right">
                {formatTimeDifference(community.updatedAt)}
              </span>
            </div>
          </div>
          {hasUnreadMessages && hasUnreadMessages?.length > 0 &&
            <div className=" bg-red-600 rounded-full w-5 h-5 items-center justify-center flex mb-5 ml-5">
              <span className="text-primary">{hasUnreadMessages?.length}</span>
            </div>}
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto ">
        <div className="flex flex-row max-md:flex-col justify-between items-center  w-[calc(100vw-300px)]">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Communication
          </span>
          <div className="flex flex-row max-md:flex-col gap-4 z-20">
            {!isMobile && <div className="flex flex-row gap-10 items-center right-5">
              {!userAuth?.userToken ? (
                <button
                  className="p-2 rounded-lg bg-primaryActive w-[145px] h-[48px] flex items-center justify-center"
                  onClick={() => navigate("/login")}
                >
                  <span className="text-sm text-black">Sign in</span>
                </button>
              ) : (
                <div className="flex flex-row items-center gap-5">
                  <span className="text-lg font-semibold text cursor-pointer">
                    Hi, {user?.username}
                  </span>
                  <button
                    onClick={() => {
                      navigate("/profile");
                    }}
                    disabled={!userAuth?.userToken}>
                    <ImageHolder
                      uri={user?.profilePicture}
                      className="flex w-[50px] h-[50px] rounded-full "
                    />
                  </button>
                </div>
              )}
            </div>}
          </div>
        </div>


        <div className="flex flex-col md:p-2 gap-[2em] max-md:gap-8  md:gap-[5em] md:backdrop-blur-sm con rounded-xl w-[calc(100vw-300px)] h-[80vh] max-md:w-[95vw] max-md:h-full">
          <div className="flex md:flex-row md:mt-4  md:gap-5 max-md:gap-8 max-md:justify-center ">
            <button
              className={`max-md:w-[60vw] h-12 w-44 bg-borderColor rounded-xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482] ${selected === "COMMUNITIES" ? "bg-primary" : ""
                }`}
              onClick={() => { setSelected("COMMUNITIES"); setSelectedChat(null) }}
            >
              <span
                className={`text-l font-medium max-md:text-lg ${selected === "COMMUNITIES" ? " text-black" : " text-white"
                  }`}
              >
                Communities
              </span>
            </button>
            <button
              className={`max-md:w-[60vw] h-12 w-44 bg-borderColor rounded-xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482] ${selected === "CHAT" ? " bg-primary" : ""
                }`}
              onClick={() => { setSelected("CHAT"); setSelectedChat(null) }}
            >
              <span
                className={`text-l font-medium max-md:text-lg ${selected === "CHAT" ? " text-black" : " text-white"
                  }`}
              >
                Chats
              </span>
            </button>
          </div>
          {!isMobile && <div className="border-darkGray w-full border-[1px] md:my-[-4em]" />}
          <div className="md:grid md:grid-cols-[2fr_3fr]">
            {selected === "CHAT" && (
              <div className="flex items-center flex-col">
                <span className="text-white text-xl text-center max-md:text-xl md:my-5">
                  Your Chats
                </span>
                <div className="absolute mt-6 md:mt-20 md:w-[25vw] text-white">
                  <SearchBar
                    searchIcon
                    placeholder="Search for a Chat"
                    type="chats"
                    setResults={(v) => setChatSearchResults(v)}
                    onClick={handleSearchChats}
                  />
                </div>
                <div
                  className={`p-1 max-md:w-full mt-20 h-[calc(68vh-240px)] max-md:h-[55vh] ${miscsStyles.beautifulScrollbarHidden}`}
                >
                  {prioritizedChats && prioritizedChats.length > 0 ? (
                    prioritizedChats.map((chat: Chat, index: number) =>
                      renderChats(chat, index)
                    )
                  ) : (
                    <p className="text-white mt-10 text-center">
                      No chats available.
                    </p>
                  )}
                </div>
                {chatPage > 1 && (
                  <div className="flex justify-center mt-2 bottom-[30px]">
                    <ThemeProvider theme={darkTheme}>
                      <Pagination
                        count={chatPage}
                        variant="outlined"
                        shape="rounded"
                        color="standard"
                        onChange={handlePageChange}
                      />
                    </ThemeProvider>
                  </div>
                )}
              </div>
            )}
            {selected === "COMMUNITIES" && (
              <div className="flex items-center flex-col">
                <span className="text-white text-xl text-center max-md:text-xl md:my-5">
                  Your communities
                </span>
                <button
                  className="max-md:w-full "
                  onClick={() => {
                    if (!authUser.userToken) return dispatch(setLoginModalVisible(true));
                    dispatch(resetPage());
                    navigate("/communication/community-rules");
                  }}
                >
                  <div className="flex gap-2 flex-row w-full rounded-lg bg-borderColor max-md:mt-4 md:mb-4 py-5 px-5 hover:ring-1 hover:ring-primary justify-center">
                    <Plus />
                    <span className="text-primary">Create a community</span>
                  </div>
                </button>
                <div className="absolute mt-[100px] md:mt-40 md:w-[25vw] text-white">
                  <SearchBar
                    searchIcon
                    placeholder="Search for a community"
                    type="communities"
                    setResults={(v) => setCommunitySearchResults(v)}
                    onClick={handleSearchCommunities}
                  />
                </div>
                <div
                  className={`p-1 max-md:w-full mt-20 h-[calc(68vh-340px)] max-md:h-[40vh] ${miscsStyles.beautifulScrollbarHidden}`}
                >
                  {prioritizedCommunities && prioritizedCommunities.length > 0 ? (
                    prioritizedCommunities.map((community: Chat, index: number) =>
                      renderCommunities(community, index)
                    )
                  ) : (
                    <p className="text-white mt-10 text-center">
                      No communities available.
                    </p>
                  )}
                </div>
                {communityPage > 1 && (
                  <div className="flex justify-center mt-2 bottom-[30px]">
                    <ThemeProvider theme={darkTheme}>
                      <Pagination
                        count={communityPage}
                        variant="outlined"
                        shape="rounded"
                        color="standard"
                        onChange={handlePageChange}
                      />
                    </ThemeProvider>
                  </div>
                )}
              </div>
            )}
            {!isMobile && <div className="">
              <CommunicationChatModal
                chat={selectedChat}
                closeModal={() => {
                  setModalOpen(false);
                  dispatch(clearChats());
                }}
                type={selected}
              />
            </div>}
          </div>
          {isMobile && modalOpen && (
            <ChatModal
              modal={modalOpen}
              chat={selectedChat}
              closeModal={() => {
                setModalOpen(false);
                dispatch(clearChats());
              }}
              type={selected}
            />
          )}
        </div>
      </div>
      {/* <Loader loading={loader} /> */}
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
