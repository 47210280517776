import CloseIcon from "assets/close-button.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import Logo from "assets/success.svg";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";
import { handleNotifications } from "store/notifications/actions";
import React from "react";
import ConfirmationAlert from "components/ConfiamationAlert";
import { toast } from "react-toastify";

export default function RequestApproveScreen({ }) {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
    const dispatch: AppDispatch = useAppDispatch();
    const sendAmount = data?.amount;
    const receiver = data?.username;
    const actionData = data?.actionData;
    const { loader } = useAppSelector(
        (state: RootState) => state.notification
    );
    const [visibleConfirmBox, setVisibleConfirmBox] = React.useState(false);


    const onCancel = () => {
        navigate("/wallet/main");
    };

    const onPressAccept = () => {
        setVisibleConfirmBox(true);
    };

    const handleConfirm = () => {
        setVisibleConfirmBox(false);
        dispatch(
            handleNotifications({
                payload: {
                    actionData: actionData,
                    actionType: "REQUEST_MONEY",
                    actionState: true,
                },
                callback: () => {
                    toast.success('Money request accepted.');
                    navigate("/wallet/main");
                }
            }))
    }

    const onPressDecline = () => {
        dispatch(
            handleNotifications({
                payload: {
                    actionData: actionData,
                    actionType: "REQUEST_MONEY",
                    actionState: false,
                },
                callback: onCancel
            }))
    };

    return (
        <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
            <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
                <div
                    className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh] flex flex-col items-center"
                >
                    <div className="flex flex-col gap-[1em] justify-center h-full">
                        <div className="w-full rounded flex items-center justify-between">
                            <div className="flex flex-row gap-4 items-center justify-center">
                                <span className="text-3xl text-white items-center font-normal">
                                    Transaction Alert
                                </span>
                            </div>
                            <button
                                className=" w-[25px] h-[25px] absolute right-10"
                                onClick={onCancel}
                            >
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        <div className="flex flex-row justify-center items-center h-full">
                            <div className="flex flex-col items-center justify-center gap-10">
                                <div className="flex flex-row gap-8 items-center justify-center mt-8">
                                    <div className="flex flex-col justify-center items-center gap-10">
                                        <img
                                            src={Logo}
                                            alt="Logo"
                                            className=" w-[10em] rounded-2xl"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                    <span className="text-primary text-3xl">
                                        {`$${Number(sendAmount).toFixed(2)}`}
                                    </span>


                                    <span className="text-placeholder text-xl mt-8">
                                        <span className="text-primary">{receiver}  </span>
                                        requested   {`$${Number(sendAmount).toFixed(2)}`} from you!
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center mt-16 max-md:flex-col w-[50%]">
                        <ButtonDc
                            text="Accept"
                            type="primary"
                            short
                            action={onPressAccept}
                        />
                        <ButtonDc
                            text="Decline"
                            short
                            type="cancel"
                            action={onPressDecline}
                        />
                    </div>
                </div>
            </div>
            <LoadingAnim loading={loader} />
            <ConfirmationAlert visible={visibleConfirmBox} message={`Are you sure you want to Send money to  ${receiver} ?`} onCancel={() => setVisibleConfirmBox(false)} onConfirm={handleConfirm} confirmMessage="Accept" />

        </div>
    );
}
