import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { AppDispatch } from "store/index";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";
import { matchSchedule, setMatchScheduleStatus } from "store/physicalGames/physicalGamesSlice";

export default function SchedulePhysicalGame({ }) {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selected, setSelected] = useState<matchSchedule>(matchSchedule.NOW);

    const isNextDisabled = !selected;
    const isMobile = window.innerWidth <= 768;

    const onCancel = () => {
        navigate("/wagering");
    };
    const onBack = () => {
        navigate("/gameMode");
    };

    const handleNext = () => {
        dispatch(setMatchScheduleStatus(selected));
        switch (selected) {
            case matchSchedule.NOW:
                return navigate('/physical-games/setup')
            case matchSchedule.LATER:
                break;
            default:
                break;
        }
    };

    return (
        <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center  h-screen ">
            <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-between">
                <div
                    className="backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
                >
                    <div className="w-full h-[calc(80vh-160px)] flex flex-col items-center overflow-y-auto">
                        <div className="w-full rounded-t-[34px] flex items-center justify-between">
                            <div className="flex flex-row items-center gap-5">
                                <button className="flex rotate-180 w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onBack}>
                                    <img src={BackIcon} alt="Close" />
                                </button>
                                <span className="text-3xl text-white items-center font-normal max-md:text-[18px]">
                                    Schedule your match
                                </span>
                            </div>
                            <button className=" w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onCancel}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        <div className="flex flex-col desktop:flex-row items-center gap-[5em] h-full justify-center max-md:flex-col max-md:gap-10 max-md:mt-10">
                            <button
                                className={`bg-borderColor p-[1.5rem] w-[20em] max-md:w-[60vw] h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center transform transition-transform  ${selected === matchSchedule.NOW ? "border-2 border-[#FFD482]" : ""}`}
                                onClick={() => setSelected(matchSchedule.NOW)}
                            >
                                <span className="text-white text-2xl">Now</span>
                            </button>
                            <button
                                className={`bg-borderColor p-[1.5rem] w-[20em] max-md:w-[60vw] h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center transform transition-transform   ${selected === matchSchedule.LATER ? " border-2 border-[#FFD482]" : ""}`}
                                onClick={() => setSelected(matchSchedule.LATER)}
                            >
                                <span className="text-white  text-2xl">Later</span>
                            </button>
                        </div>
                    </div>
                    <ButtonDc
                        short={isMobile ? true : false}
                        type="primary"
                        className="max-md:w-4/5 "
                        textClassName="max-md:text-sm"
                        text={'Next'}
                        action={handleNext}
                        disabled={isNextDisabled}
                    />
                </div>
            </div>
        </div>
    );
}
