import React, { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import EmojiPicker, { EmojiClickData, Theme } from "emoji-picker-react";
import { subscribeToTopic } from "store/fcm/actions";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import { ReactComponent as Dots } from "assets/dots.svg";
import { ReactComponent as Send } from "assets/send.svg";
import { ReactComponent as PrivateIcon } from "assets/community/private_icon.svg";
import { ReactComponent as PublicIcon } from "assets/community/public_icon.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCommunity, leaveCommunity } from "store/community/action";
import {
  updateCommunityChatMessages,
  updatePersonalChatMessages,
} from "store/messaging/messagingSlice";
import {
  deletePrivateChat,
  getCommunityChatMessages,
  getPersonalChatMessages,
  sendCommunityMessage,
  sendPersonalMessage,
} from "store/messaging/action";
import {
  addNewChatToList,
  removeDeletedOrLeftCommunity,
  removePersonalChat,
  setCommunitiesName,
  setCommunityId,
  setCommunityState,
  setEditComID,
  updateLastMessage,
} from "store/community/communitySlice";
import CommunitySettings from "./CommunitySettings";
import miscsStyles from "styles/miscs/miscs.module.scss";
import ImageHolder from "./ImagePlaceholder";
import { ReactComponent as Emoji } from "assets/streaming/emoji.svg";
import { ReactComponent as Gift } from "assets/streaming/gift.svg";
import { getBalance } from "store/wallet/action";
import { GiftModal } from "./CommunicationChatModal";
import { chatGifts } from "constant/gifts";


interface ChatModalProps {
  modal?: boolean;
  type?: String;
  chat?: any;
  isMatch?: boolean;
  closeModal: () => void;
}
interface MessageProps {
  user: string;
  message: string;
  time: string;
  username?: string;
  profilePicture?: string;
  giftId?: Number;
  balance?: Number;
}
const ChatModal: React.FC<ChatModalProps> = ({
  closeModal,
  chat,
  type,
  isMatch,
  modal,
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [dotModalOpen, setDotModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesData = useAppSelector(
    (state: RootState) => state.messaging.chats
  );
  const communityMessagesData = useAppSelector(
    (state: RootState) => state.messaging.communityChats
  );
  const user = useAppSelector((state: RootState) => state.user);
  const loader = useAppSelector((state: RootState) => state.messaging.loader);
  const currentUser = user?.user;
  const chatId = type === "COMMUNITIES" ? chat?._id : chat?._id;
  const fcmMessage = useAppSelector((state) => state.fcm);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const latestMessageRef = useRef<HTMLDivElement>(null);
  const [isGiftModalOpen, setIsGiftModalOpen] = useState<boolean>(false);
  const { dcBalance } = useAppSelector(
    (state) => state.wallet
  );

  const handleEmojiSelect = (emojiData: EmojiClickData) => {
    setMessage(message + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const closeBothModals = () => {
    setSettingsModalOpen(false);
    setDotModalOpen(false);
    closeModal();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    const date = new Date().toISOString();

    if (message.trim() !== "") {
      if (type === "CHAT") {
        if (messagesData && currentUser) {
          const data = {
            _id: "temp",
            message: message,
            user: currentUser?._id,
            time: date,
          };

          dispatch(updatePersonalChatMessages(data));
          dispatch(
            sendPersonalMessage({
              payload: { to: chat?.user, message: message },
              callback: () => {
                const newchat = {
                  _id: chatId,
                  user: chat?.user,
                  latest: {
                    _id: "temp",
                    message: message,
                    user: currentUser?._id,
                    time: date,
                  },
                };
                setMessage("");
                dispatch(addNewChatToList(newchat));
                dispatch(updateLastMessage({ chatId, newMessage: data }));
              },
            })
          );
        }
      } else if (type === "COMMUNITIES") {
        if (communityMessagesData && currentUser) {
          const data = {
            _id: "temp",
            message: message,
            user: currentUser?._id,
            time: date,
            username: user.user?.username,
            profilePicture: user.user?.profilePicture,
          };

          dispatch(updateCommunityChatMessages(data));
          dispatch(
            sendCommunityMessage({
              payload: { to: chatId, message: message },
              callback: () => {
                const newchat = {
                  _id: chatId,
                  user: chat?.user,

                  latest: {
                    _id: "temp",
                    message: message,
                    user: currentUser?._id,
                    time: date,
                  },
                };
                setMessage("");
              },
            })
          );
        }
      }

      setMessage("");
    }
  };

  const pushToChats = (messageData: any) => {
    const date = new Date().toISOString();
    const data = messageData?.data;
    const chatId = type === "COMMUNITIES" ? data?.communityId : data?.chatId;
    if (data.type !== "COMMUNITY_MESSAGE" && chatId !== chat?._id) return;
    if (data.type === "COMMUNITY_MESSAGE" && data.senderId === currentUser?._id)
      return;
    const newMsg = {
      _id: messageData.messageId,
      message: data?.message,
      user: data?.senderId,
      time: date,
      balance: Number(data.balance),
      giftId: Number(data.giftId),
    };

    const newCommunityMsg = {
      _id: data?.senderId,
      username: data?.sender,
      profilePicture: data?.senderPicture,
      time: date,
      message: data?.message,
    };
    if (type === "COMMUNITIES")
      return dispatch(updateCommunityChatMessages(newCommunityMsg));
    dispatch(updatePersonalChatMessages(newMsg));
  };
  useEffect(() => {
    if (!modal) return;
    if (type === "COMMUNITIES") dispatch(subscribeToTopic({ topic: chatId }));
  }, [chatId]);

  useEffect(() => {
    if (
      fcmMessage.data.type !== "PRIVATE_MESSAGE" &&
      fcmMessage.data.type !== "COMMUNITY_MESSAGE"
    )
      return;
    if (fcmMessage?.data) {
      if (fcmMessage.data.senderId === currentUser?._id) return;
      pushToChats(fcmMessage);
      const newchat = {
        _id: chat?._id,
        user: chat?.user,
        latest: {
          _id: fcmMessage.data?.chatId,
          message: fcmMessage?.data.message,
          time: new Date().toISOString(),
          user: fcmMessage?.data.senderId,
          balance: Number(fcmMessage?.data.balance),
          giftId: Number(fcmMessage?.data.giftId),
        },
      };
      if (fcmMessage.data.type !== "PRIVATE_MESSAGE") {
        dispatch(addNewChatToList(newchat));
      }
      dispatch(
        updateLastMessage({
          chatId: chat?._id,
          newMessage: {
            _id: fcmMessage.data?.chatId,
            message: fcmMessage?.data.message,
            time: new Date().toISOString(),
            user: fcmMessage?.data.senderId,
            balance: Number(fcmMessage?.data.balance),
            giftId: Number(fcmMessage?.data.giftId),
          },
        })
      );
    }
  }, [fcmMessage]);

  useEffect(() => {
    if (!modal) return;
    dispatch(getPersonalChatMessages(`/${chatId}`));
    dispatch(getCommunityChatMessages(`/${chatId}`));
  }, [chatId]);

  useEffect(() => {
    if (chatContainerRef.current && latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesData, communityMessagesData]);

  const groupMessagesByDate = (messages: any[]) => {
    if (!Array.isArray(messages)) {
      console.error(
        "Expected messages to be an array, but received:",
        messages
      );
      return {};
    }

    return messages.reduce((groupedMessages: any, message: any) => {
      const date = new Date(message.time).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric",
      });

      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }

      groupedMessages[date].push(message);
      return groupedMessages;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(
    type === "COMMUNITIES"
      ? communityMessagesData?.messages
      : messagesData?.messages
  );

  const Message: React.FC<MessageProps> = ({
    user,
    username,
    message,
    time,
    profilePicture,
    giftId,
    balance,
  }) => {
    const formattedTime = new Date(time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const isGiftedMessage = giftId !== undefined && Number(giftId) > -1;

    const fetchGiftIcon = (giftId: String) => {
      return chatGifts.find((item) => item.id === giftId)
    }
    const isGiftReceiver = user !== currentUser?._id;

    const giftIcon = fetchGiftIcon(String(giftId));
    const actualReceiverBalance = giftIcon ? dcBalance - giftIcon.amount : dcBalance;

    return (
      <>
        <div
          className={`flex ${user === currentUser?._id ? "justify-end" : "justify-start"
            } mt-4`}
          style={{
            maxWidth: "100%",
          }}
        >
          <div
            className={`flex ${user === currentUser?._id ? "items-end" : "items-start"
              } flex-col py-1 px-2 rounded-md `}
          >
            {!isGiftedMessage ?
              (<div
                className={`flex gap-2 ${user === currentUser?._id ? "flex-row-reverse " : "flex-row "
                  } `}
              >
                {type === "COMMUNITIES" && (
                  <div>
                    <ImageHolder
                      uri={profilePicture}
                      className="w-12 rounded-full"
                    />
                  </div>
                )}
                <p
                  className={`inline-block ${user === currentUser?._id ? " bg-lightBackground" : "bg-borderColor"
                    } p-2 min-w-16 rounded-xl whitespace-pre-wrap break-words text-left text-white`}
                >
                  {type === "COMMUNITIES" && user !== currentUser?._id && (
                    <div className="mt-1">
                      <p className="text-xs " style={{ color: "#ffffff80" }}>
                        {username}
                      </p>
                    </div>
                  )}
                  {message}
                </p>
              </div>) :
              <button className="flex flex-col justify-center items-center gap-2 bg-lightBackground p-2 rounded-lg">
                {giftId && <img src={fetchGiftIcon(String(giftId))?.image} alt="icon" />}
                <span className="text-white text-[8px]">
                  {
                    isGiftReceiver ?
                      `${chat?.user?.username} has sent you ${fetchGiftIcon(String(giftId))?.label}! Worth ${fetchGiftIcon(String(giftId))?.amount} DC`
                      : message
                  }
                </span>
              </button>}
            <div className="mt-1">
              <p className="text-xs italic" style={{ color: "#ffffff80" }}>
                {formattedTime}
              </p>
            </div>
          </div>
        </div>
        {isGiftedMessage && <span className="text-backgroundText text-[12px] flex justify-center items-center">Your remaining balance: {!isGiftReceiver ? balance?.toString() : actualReceiverBalance} DC</span>}
      </>
    );
  };

  const deleteChat = () => {
    dispatch(deletePrivateChat(chatId));
    dispatch(removePersonalChat(chatId));
    toast("Successfully deleted");
    closeModal?.();
  };

  const deleteCommunityClick = async () => {
    if (chat?.owner === currentUser?._id) {
      dispatch(deleteCommunity({ query: `/${chatId}` }));
    } else {
      dispatch(leaveCommunity({ query: `/${chatId}` }));
    }
    setDotModalOpen(!dotModalOpen);
    dispatch(removeDeletedOrLeftCommunity(chatId));
    setTimeout(() => {
      toast(
        chat?.owner === user?.user?._id
          ? "Successfully deleted"
          : "Successfully left"
      );
      closeModal?.();
    }, 2000);
  };

  const handleOnClickGiftIcon = () => {
    setIsGiftModalOpen(!isGiftModalOpen);
  }
  const handleOnCloseGiftModal = () => {
    setIsGiftModalOpen(false);
  }

  const handleOnSubmitGift = (giftData: any) => {
    handleOnCloseGiftModal();

    const msg = `You sent a ${giftData.label.toLowerCase()} to ${chat?.user.username}.Worth ${giftData.amount} DC.`
    const date = new Date().toISOString();

    if (messagesData && currentUser) {
      const data = {
        _id: "temp",
        message: msg,
        giftId: giftData.id,
        balance: dcBalance - giftData.amount,
        user: currentUser?._id,
        time: date
      };
      dispatch(
        sendPersonalMessage({
          payload: { to: chat?.user, message: msg, giftId: giftData.id },
          callback: () => {
            const newchat = {
              _id: chatId,
              user: chat?.user,
              latest: {
                _id: "temp",
                message: msg,
                giftId: giftData.id,
                balance: dcBalance - giftData.amount,
                user: currentUser?._id,
                time: date,
              },
            };
            dispatch(getBalance());
            dispatch(updatePersonalChatMessages(data));
            setMessage("");
            dispatch(addNewChatToList(newchat));
            dispatch(updateLastMessage({ chatId, newMessage: data }));
          },
        })
      );
    }
  }

  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex md:min-h-full md:h-[100vh] max-md:h-screen max-md:w-full justify-center px-2 py-12 max-md:p-0 text-center ">
            <div className="relative w-[95%] max-md:w-full sm:w-[80%] min-h-[40vh] md:rounded-2xl bg-borderColor md:bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-10 py-4">
                <button
                  type="button"
                  className="rounded-md p-1 inline-flex items-center justify-center absolute md:top-8 top-[30px] right-8"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close menu</span>
                  <CloseIcon className="max-md:h-5 " />
                </button>
                <button
                  className=" absolute md:top-9 top-[34px] right-20 "
                  onClick={() => setDotModalOpen(!dotModalOpen)}
                >
                  <Dots className="w-6 md:h-6 h-5 " />
                </button>
                <div className=" absolute top-8 right-[120px] ">
                  {type === "COMMUNITIES" &&
                    chat?.type?.toLowerCase()?.trim() === "public" ? (
                    <PublicIcon className=" w-8 md:h-8 h-6" />
                  ) : chat?.type?.toLowerCase()?.trim() === "private" ? (
                    <PrivateIcon className=" w-8 md:h-8 h-6" />
                  ) : (
                    ""
                  )}
                </div>
                {dotModalOpen && (
                  <div className="absolute flex flex-col gap-3 top-16 right-20 bg-opacity-95 p-2 bg-cardBackground shadow-lg rounded-md z-50">
                    {type === "COMMUNITIES" &&
                      chat?.owner === currentUser?._id && (
                        <button
                          className="flex  w-full px-2 py-1 text-sm font-bold text-white hover:bg-primary hover:text-black rounded-md"
                          onClick={() => {
                            dispatch(setEditComID(chatId));
                            dispatch(setCommunityState("EDIT"));
                            navigate("/communication/select-community-image");
                          }}
                        >
                          Edit Community
                        </button>
                      )}
                    {type === "COMMUNITIES" && (
                      <button
                        className="flex  w-full px-2 py-1 text-sm font-bold text-white hover:bg-primary hover:text-black rounded-md"
                        onClick={() => {
                          setSettingsModalOpen(true);
                          setDotModalOpen(!dotModalOpen);
                        }}
                      >
                        Community Settings
                      </button>
                    )}
                    {type === "COMMUNITIES" && (
                      <button
                        className="flex  w-full px-2 py-1 text-sm font-bold text-white hover:bg-primary hover:text-black rounded-md"
                        onClick={() => {
                          dispatch(setCommunitiesName(chat.name));
                          dispatch(setCommunityId(chatId));
                          navigate(
                            "/communication/invite-to-community/invite-duelists"
                          );
                        }}
                      >
                        Invite Duelists
                      </button>
                    )}
                    {type === "COMMUNITIES" && (
                      <div className="bg-white h-[1px] mt-2 w-full"></div>
                    )}
                    <button
                      className="flex  w-full px-2 py-1 text-sm font-bold text-brightRed hover:bg-primary rounded-md"
                      onClick={
                        type === "COMMUNITIES"
                          ? deleteCommunityClick
                          : deleteChat
                      }
                    >
                      {type === "COMMUNITIES"
                        ? chat?.owner === currentUser?._id
                          ? "Leave & Delete Community "
                          : "Leave Community"
                        : "Delete chat"}
                    </button>
                  </div>
                )}

                <div className="flex flex-row items-center gap-10 max-md:gap-3">
                  <ImageHolder
                    className="flex md:w-[70px] md:h-[70px] max-md:w-[50px] max-md:h-[50px] rounded-full p-1"
                    uri={
                      type === "COMMUNITIES"
                        ? chat?.coverImage
                        : chat.user?.profilePicture
                    }
                  />
                  <div className="flex w-[50vw] gap-2 items-center">
                    <div className="flex flex-row w-full justify-between">
                      <span className="text-white text-3xl max-md:text-xl">
                        {type === "COMMUNITIES"
                          ? chat?.name
                          : chat.user?.username}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-placeholder h-[1px] mt-2 w-full"></div>
              </div>
              <div
                className={`container h-[calc(83vh-145px)] max-md:h-[75vh] md:w-[60vw] md:bg-borderColor md:border-2 border-primary rounded-3xl px-10 py-4  ${miscsStyles.beautifulScrollbarHidden}`}
              >
                <div
                  className={`chat-messages ${miscsStyles.beautifulScrollbarHidden}`}
                  ref={chatContainerRef}
                >
                  {Object.keys(groupedMessages).length === 0 ? (
                    <div className="text-subText flex text-center h-[50vh] justify-center items-center font-bold">
                      To start a conversation, send a message
                    </div>
                  ) : (
                    Object.entries(groupedMessages)?.map(
                      ([date, messages]: any) => (
                        <div key={date}>
                          <div className="text-center my-4">
                            <span className="text-white font-bold">{date}</span>
                          </div>
                          {messages?.map((msg: any, index: number) => (
                            <div
                              key={msg?._id}
                              ref={
                                index === messages.length - 1
                                  ? latestMessageRef
                                  : null
                              }
                            >
                              <Message
                                user={msg.user}
                                message={msg.message}
                                time={msg.time}
                                username={msg?.username}
                                profilePicture={msg?.profilePicture}
                                giftId={msg?.giftId}
                                balance={msg?.balance}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
              <div className="flex justify-center mb-5">
                <div className="max-md:absolute md:w-[55vw] w-4/5 justify-center bottom-10 ">
                  <div className="flex flex-row items-center  rounded-lg bg-borderColor">
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                      className="flex-1 bg-borderColor rounded-l-xl md:w-[30vw] max-md:w-full p-2 max-md:text-sm text-left text-white focus:outline-none focus:ring-0 border-inputGray border-b-2 focus:border-primaryYellow peer"
                      placeholder="Type your message here..."
                    />
                    <button
                      className={`flex items-center justify-center hover:border-b-2 h-10 w-10 focus:ring-0 border-primaryYellow peer ${showEmojiPicker ? "border-b-2" : ""
                        }`}
                      onClick={() => {
                        setShowEmojiPicker(!showEmojiPicker);
                        setIsGiftModalOpen(false);
                      }}
                    >
                      <Emoji />
                    </button>
                    {showEmojiPicker && (
                      <div className="absolute bottom-36 right-26">
                        <EmojiPicker
                          theme={Theme.DARK}
                          onEmojiClick={handleEmojiSelect}
                        />
                      </div>
                    )}
                    {(
                      <button
                        onClick={handleOnClickGiftIcon}
                        className={`flex items-center justify-center hover:border-b-2 h-10 w-10 focus:ring-0 border-primaryYellow peer ${isGiftModalOpen ? "border-b-2" : ""
                          }`}
                      >
                        <Gift />
                      </button>
                    )}
                    {isGiftModalOpen && (
                      <div className="absolute bottom-[80px] right-0">
                        <GiftModal onClose={handleOnCloseGiftModal} onSubmit={handleOnSubmitGift} />
                      </div>
                    )}
                    <button
                      type="button"
                      className="bg-primary flex-col justify-center items-center h-10 w-14 rounded-xl text-black"
                      onClick={handleSendMessage}
                    >
                      <Send fill="#000000" className="h-10 w-10" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {settingsModalOpen && (
            <CommunitySettings
              isMatch={isMatch}
              chat={chat}
              closeSettingsModal={() => {
                setSettingsModalOpen(false);
              }}
              closeBothModals={closeBothModals}
            />
          )}
        </div>
        <Loader loading={loader} />
      </div>
    </div>
  );
};

export default ChatModal;
