import React from "react";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import { useNavigate } from "react-router-dom";


const ReferralsScreen = () => {
  const navigate = useNavigate();

  const onCancel=()=>{
    navigate('/settings/refer-friend')
  }

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat gap-[7em] flex flex-col items-center   h-screen ">
      <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between max-md:flex-col">
              
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="container mx-auto px-4 py-8 text-white flex flex-col justify-evenly gap-10 h-full">
            <h1 className="text-3xl font-bold text-center mb-6 ">
              Referral Program: How it Works
            </h1>
            <div className="max-w-xl mx-auto flex flex-col justify-between">
              <p className="mb-4">
                Welcome to our exciting referral program! We've designed a seamless
                way for you to earn rewards simply by inviting your friends to join
                our thrilling gambling mobile app. Here's how it works:
              </p>
              <ol className="list-decimal pl-6 mb-6">
                <li className="mb-2">
                  <strong>Share Your Unique Referral Link:</strong> Once you've signed
                  up and started enjoying the excitement of our app, you'll receive a
                  unique referral link. Share this link with your friends, family, and
                  fellow gambling enthusiasts through social media, email, or any
                  other preferred method.
                </li>
                <li className="mb-2">
                  <strong>Friends Join Using Your Link:</strong> When someone clicks
                  on your referral link, they'll be directed to download and sign up
                  for our app. As they create their account, our system automatically
                  recognizes that they've been referred
                </li>
              </ol>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

export default ReferralsScreen;