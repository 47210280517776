import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { getLeaderboard, getOtherUser } from "store/user/action";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { ReactComponent as GoldMedal } from "assets/games/GoldMedal.svg";
import { ReactComponent as SilverMedal } from "assets/games/SilverMedal.svg";
import { ReactComponent as BronzeMedal } from "assets/games/BronzeMedal.svg";

import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";
import miscsStyles from "styles/miscs/miscs.module.scss";
import ImageHolder from "components/ImagePlaceholder";
import LoadingAnim from "components/Loader";
import { getMinigamesLeaderBoard } from "store/gameHub/action";

interface GameRecordTileProps {
  profileImage: string;
  position: number;
  name: string;
  matches: number;
  wins: number;
  losses: number;
  last5?: string[];
  score: number;
  userId: string;
  onPress: () => void;
}

const GameRecordTile: React.FC<GameRecordTileProps> = ({ profileImage,
  position,
  name,
  score,
  userId, onPress }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const crownEmojis = [
    <GoldMedal key="gold" />,
    <SilverMedal key="silver" />,
    <BronzeMedal key="bronze" />,
  ];

  const isTopThree = position < 3;

  const positionText = isTopThree
    ? crownEmojis[position]
    : `${position + 1}.`;

  const handleProfilePress = () => {
    if (userId === user._id) {
      navigate(`/profile/${userId}`);
    } else {
      dispatch(getOtherUser(userId)).then(() => navigate(`/profile/${userId}`));
    }
  };

  return (
    <button
      className={
        ` flex justify-between bg-borderColor items-center p-2 px-5 rounded-xl text-white ${userId === user.user?._id ? 'border-2 border-primaryYellow' : ""}`
      }
      onClick={handleProfilePress}
    >
      <div className="flex items-center md:gap-3 col-span-1">
        <div className="w-8 h-8 flex justify-center items-center align-middle border-2 rounded-full mr-2">
          <span className='text-placeholder'>
            {position + 1}
          </span>
        </div>

        <div className="flex max-md:w-[40vw] items-center gap-3 col-span-2">
          <ImageHolder uri={profileImage} className="w-12 h-12 rounded-lg" />
          <div className="flex flex-col text-left">
            <span className="text-lg ">{name}</span>
            <span className="text-xs text-placeholder">Highest Score: {score}</span>
          </div>
        </div>
      </div>
      {isTopThree && <div className="col-span-1 items-end text-right text-3xl">{positionText}</div>}
    </button>
  );
};

interface GameLeaderBoardScreenProps {
  id: string;
  title: string;
  route: string;
  loader: boolean;
  isLandscape: boolean;
  backgroundImage: string;
  image?: string;
  gameLogo?: string;
  playButtonImage?: string;
  textColor?: string;
  textColor2?: string;
}


const GameLeaderBoardScreen: React.FC = () => {
  const location = useLocation();
  const data = location.state?.gameData as GameLeaderBoardScreenProps;
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const gameHub = useAppSelector((state: RootState) => state.gameHub);

  const [record, setRecord] = useState([])


  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;

  useEffect(() => {
    dispatch(getMinigamesLeaderBoard({
      payload: { gameId: data.id, sortBy: 'highestScore' }, callback(data) {
        setRecord(data.leaderboard
        )
      },
    }));
  }, [dispatch]);



  const handleGameSelect = (gameData: any) => {
    { isSuspended ? (toast.warning('Account Suspended')) : navigate("/gaming/game-starting", { state: { gameData } }); }
  };
  const handleClose = () => {
    navigate("/gaming/game-leaderBoard-main");
  };

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white font-normal">Leaderboard</span>
            <button className="w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="max-md:mt-5 md:grid md:grid-cols-2 items-center ">
            <div className="flex flex-col items-center justify-between p-4 gap-4">
              <div className="rounded-full flex w-56 h-56 justify-center items-center">
                <img src={data?.image} alt={data.title} className="md:w-56 md:h-56 w-48 h-48 md:p-8 p-5 md:rounded-[50px] rounded-[40px]" />
              </div>
              <span className="text-xl max-md:text-lg text-left font-semibold text-white">{data.title}</span>
              <span className="text-xs max-md:text-sm text-left text-disabled mt-[-15px]">Leaderboard</span>
              <ButtonDc text="Play Now" type="primary" action={() => { handleGameSelect(data) }} />
            </div>
            <div
              className={`md:mt-10 flex flex-col md:h-[calc(100vh-300px)] h-[calc(100vh-400px)] gap-3 overflow-y-auto ${miscsStyles.beautifulScrollbarHidden}`}
            >
              {record.map((item: any, index: number) => (
                <GameRecordTile
                  key={index}
                  profileImage={item.user.profilePicture}
                  position={index}
                  name={item.user.username}
                  matches={item.matches}
                  wins={item.wins}
                  losses={item.losses}
                  last5={item.last5}
                  score={item.highestScore}
                  userId={item.user._id}
                  onPress={() => handleGameSelect(record)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={gameHub.loader} />
    </div >
  );
};

export default GameLeaderBoardScreen;
