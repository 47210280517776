import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "store/index";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { toast } from "react-toastify";
import { setLoginModalVisible } from "store/auth/authSlice";
import Sidebar from "components/SideBar";
import ButtonDc from "components/Button";
import Header from "components/header";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
import playSoloCover from 'assets/games/ChallengePath/playSolo.png';
import playBetCover from 'assets/games/ChallengePath/playBet.png';
import { resetGameHubSlice, setSelectedGame } from "store/gameHub/gameHubSlice";
import BackIcon from "assets/arrow-right.svg";
import CloseIcon from "assets/close-button.svg";

export default function MainGameHubScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const location = useLocation();
  const gameData = location.state?.gameData;
  const from = location.state?.from;

  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);
  const user = useAppSelector((state: RootState) => state.auth);

  const onClickSolo = () => {
    if (isSuspended) return toast.warning('Account Suspended');
    if (from === 'DUEL') return navigate("/gaming/game-starting", { state: { gameData, from: 'DUEL' } });
    navigate("/gaming/games");
  }

  const onClickNow = () => {
    if (!user.userToken) return dispatch(setLoginModalVisible(true));
    if (isSuspended) return toast.warning('Account Suspended');
    if (from === 'DUEL') {
      dispatch(
        setSelectedGame({
          id: gameData?.id,
          title: gameData?.title,
          route: gameData?.route,
          gameType: gameData?.gameType,
          isLandscape: gameData?.isLandscape,
          image: gameData?.image,
          gameLogo: gameData?.gameLogo,
          backgroundImage: gameData?.backgroundImage,
          textColor: gameData?.textColor,
          textColor2: gameData?.textColor2,
        })
      );
      if (isSuspended) return toast.warning('Account Suspended');
      navigate("/gaming/cubes", { state: { gameData, from: 'DUEL' } });
    } else {
      dispatch(resetGameHubSlice());
      navigate(`/gaming/select-game/${"challenge"}`);
    }
  }

  const handleClose = () => {
    navigate('/duels/select-game');
  }

  return (
    <>
      {from !== 'DUEL' ? (<div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
        {from !== 'DUEL' && <div className="max-md:fixed z-20">
          <Sidebar
            onOptions={() => setVisibleOptionsMenu(true)}
            onNotifications={() => setVisibleNotifications(true)}
          />
        </div>}
        <div className="flex flex-col w-full h-auto xl:h-screen p-8 overflow-x-hidden">
          {from !== 'DUEL' && <Header
            onClick={() => navigate("/login")}
            onPressUser={() => {
              navigate("/profile");
            }}
          />
          }
          <div className=" w-full  md:gap-[2em] flex flex-col items-center mt-20 max-md:gap-3 max-md:my-20">
            <div
              className="flex flex-col backdrop-blur-sm con rounded-[32px] p-10 max-md:p-5 w-full max-md:w-[calc(100%-10px)] items-center justify-center"
            >
              <div className="flex flex-col gap-4">
                <span className="text-3xl text-white font-bold text-center max-md:mt- max-md:text-2xl">
                  Choose Your Game Mode
                </span>
                <span className=" text-white text-center text-xl font-normal max-md:text-lg">
                  <span className="font-bold pr-2">
                    Choose How You Want to Compete:
                  </span>
                  Play solo or engage in multiplayer wagering.
                </span>
              </div>
              <div className={`flex desktop:flex-row flex-col items-center justify-center desktop:mt-10 gap-10 md:my-8 max-md:gap-2 max-md:my-3`}>
                <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-8 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg hover:ring-2 ring-primaryActive"
                  onClick={onClickSolo}>
                  <img src={playSoloCover} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                  <div className="flex flex-col justify-center gap-2 items-center">
                    <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Play Solo</span>
                    <span className="text-disabled text-center md:w-[30vw] md:min-h-20  desktop:text-xl max-md:text-sm mb-6">
                      Enjoy the game on your own in classic single-player mode. Play for fun and try to beat your high score!
                    </span>
                    <ButtonDc
                      type="primary"
                      text="Start Solo Game"
                      action={onClickSolo}
                      short

                    />
                  </div>
                </button>
                <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-8 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg  hover:ring-2 ring-primaryActive"
                  onClick={onClickNow}>
                  <img src={playBetCover} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                  <div className="flex flex-col justify-center gap-2 items-center">
                    <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Play with a Wager</span>
                    <span className="text-disabled text-center  md:w-[30vw] md:min-h-20 desktop:text-xl max-md:text-sm mb-6">
                      Challenge another player to the same game. Place a wager, and the player with the highest score takes the pot!
                    </span>
                    <ButtonDc
                      type="primary"
                      text="Compete Now"
                      action={onClickNow}
                      short

                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Menu
          visible={visibleOptionsMenu}
          onClose={() => setVisibleOptionsMenu(false)}
        />
        <NotificationModal
          visible={visibleNotifications}
          onClose={() => setVisibleNotifications(false)}
        />
      </div>)
        : (
          <div className="mx-auto w-screen  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  md:p-2">
            <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
              <div
                className="  backdrop-blur-sm con rounded-[34px] p-10
            overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] justify-between items-center flex flex-col  max-md:h-[95vh]"
              >
                <div className="w-full max-md:w-[80vw] rounded-t-[34px] flex items-center justify-between max-md:mt-4">
                  <div className="flex flex-row items-center gap-5">
                    <button className="flex rotate-180 w-[25px] h-[25px]" onClick={handleClose}>
                      <img src={BackIcon} alt="Close" />
                    </button>
                    <span className="text-3xl text-white items-center font-normal max-md:text-lg">
                      Choose Your Game Mode
                    </span>
                  </div>
                  <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                    <img src={CloseIcon} alt="Close" />
                  </button>
                </div>
                <div className="flex flex-col gap-4">
                  <span className=" text-white text-center text-xl font-normal max-md:text-sm">
                    <span className="font-bold pr-2">
                      Choose How You Want to Compete:
                    </span>
                    Play solo or engage in multiplayer wagering.
                  </span>
                </div>
                <div className={`flex desktop:flex-row flex-col items-center justify-center desktop:mt-10 gap-10 md:my-8 max-md:gap-2 max-md:my-3`}>
                  <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-4 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg hover:ring-2 ring-primaryActive"
                    onClick={onClickSolo}>
                    <img src={playSoloCover} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                    <div className="flex flex-col justify-center gap-2 items-center">
                      <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Play Solo</span>
                      <span className="text-disabled text-center md:w-[30vw] md:min-h-20  desktop:text-xl max-md:text-sm mb-6">
                        Enjoy the game on your own in classic single-player mode. Play for fun and try to beat your high score!
                      </span>
                      <ButtonDc
                        type="primary"
                        text="Start Solo Game"
                        action={onClickSolo}
                        short

                      />
                    </div>
                  </button>
                  <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-4 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg  hover:ring-2 ring-primaryActive"
                    onClick={onClickNow}>
                    <img src={playBetCover} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                    <div className="flex flex-col justify-center gap-2 items-center">
                      <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Play with a Wager</span>
                      <span className="text-disabled text-center  md:w-[30vw] md:min-h-20 desktop:text-xl max-md:text-sm mb-6">
                        Challenge another player to the same game. Place a wager, and the player with the highest score takes the pot!
                      </span>
                      <ButtonDc
                        type="primary"
                        text="Compete Now"
                        action={onClickNow}
                        short
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>)}
    </>
  );
}
