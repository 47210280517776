import React from 'react';

interface ConfirmationAlertProps {
    visible: boolean;
    message?: string;
    confirmMessage?: string;
    cancelMessage?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationAlert: React.FC<ConfirmationAlertProps> = ({ visible, message, confirmMessage, cancelMessage, onConfirm, onCancel }) => {
    return (
        <>
            {visible && <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
                <div className="bg-white p-6 rounded-lg shadow-lg text-center border-2 border-primary">
                    <p className="mb-4">{message}</p>
                    <div className="flex justify-center space-x-4 ">
                        <button
                            onClick={onConfirm}
                            className="bg-primary text-gray-700 px-4 py-2 rounded hover:bg-primary"
                        >
                            {confirmMessage || 'Confirm'}
                        </button>
                        <button
                            onClick={onCancel}
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                        >
                            {cancelMessage || 'Cancel'}
                        </button>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default ConfirmationAlert;