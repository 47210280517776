import LoadingAnim from "components/Loader";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Lock } from "../../assets/lock_password.svg";
import { resetPassword } from "../../store/auth/actions";
import { AppDispatch } from "../../store/index";
import {
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneUppercase,
  min8chars,
} from "../../constant/validations";
import { toast } from "react-toastify";

export default function CreateNewPasswordScreen({ setLoggedIn }: any) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const otp = user.otp;
  const email = user.resetEmail;

  const render = async () => {
    if (
      !atLeastOneLowercase.test(password) ||
      !atLeastOneUppercase.test(password) ||
      !atLeastOneNumber.test(password) ||
      !min8chars.test(password) ||
      password !== confirmPassword
    ) {
      toast.warning("Please check your password and confirm password fields.");
      return;
    }
    dispatch(
      resetPassword({
        payload: { userEmail: email, code: otp, newPassword: password },
        callback: () => {
          navigate("/login");
        },
      })
    );
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const Validate = ({ text, regex }: { text: string; regex: RegExp }) => {
    return (
      <div className="flex w-[9em] md:w-[17em] items-center">
        {regex.test(password) ? (
          <i
            className="fas fa-check-circle text-lightGreen"
            style={{ fontSize: "0.62em" }}
            aria-hidden="true"
          ></i>
        ) : (
          <i
            className="fas fa-sync text-[#d0d0d0]"
            style={{ fontSize: "0.62em" }}
            aria-hidden="true"
          ></i>
        )}
        <span className="text-[#d0d0d0] text-center ml-2 text-[12px]">
          {text}
        </span>
      </div>
    );
  };

  return (
    <div className="bg-background min-h-screen h-auto bg-[url('/src/assets/Backdrop-log.png')] bg-no-repeat bg-fit flex items-center justify-center tab:justify-end">
      <div className="h-auto w-auto  border-[1px] rounded-3xl border-primaryYellow tab:mr-28 ">
        <div className="flex flex-col items-center justify-center w-full gap-[15px] p-10">
          <h1 className="text-[32px] font-bold text-primaryYellow">
            Create new password
          </h1>
          <Lock />
          <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw] gap-5"></div>
          <div className="flex flex-row items-center justify-center gap-5 max-w-[505px] min-w-[280px] w-[35vw] mt-5">
            <p className="text-[#d0d0d0] text-[12px] text-center">
              Please enter new password
            </p>
          </div>
          <div className="flex flex-col max-w-[505px] min-w-[280px] w-[35vw] items-center">
            <div className="flex flex-col  w-full gap-4 mt-4">
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  id="password"
                  className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 border-inputGray appearance-none focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                  placeholder=" "
                />
                <label
                  htmlFor="password"
                  className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Password
                </label>
                <button
                  id="togglePasswordVisibility"
                  onClick={togglePasswordVisibility}
                  type="button"
                  className="absolute right-2 top-4 text-white"
                >
                  <i
                    className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                  ></i>
                </button>
              </div>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  id="confirmPassword"
                  className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 border-inputGray appearance-none focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                  placeholder=" "
                />
                <label
                  htmlFor="confirmPassword"
                  className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Confirm Password
                </label>
                <button
                  id="toggleConfirmPasswordVisibility"
                  onClick={togglePasswordVisibility}
                  type="button"
                  className="absolute right-2 top-4 text-white"
                >
                  <i
                    className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                  ></i>
                </button>
              </div>
            </div>
            <div
              className={`transition-opacity duration-1000 ease-in-out ${isPasswordFocused
                ? "opacity-100"
                : "opacity-0 pointer-events-none"
                } max-w-[505px] w-[35vw] min-w-[280px]  items-center justify-center`}
            >
              <div className="flex flex-col mt-3 items-center justify-center">
                <div className="grid grid-cols-2 lap:grid-cols-4 gap-0 relative ">
                  <Validate regex={atLeastOneUppercase} text="1 uppercase" />
                  <Validate regex={atLeastOneLowercase} text="1 lowercase" />
                  <Validate regex={atLeastOneNumber} text="1 number" />
                  <Validate regex={min8chars} text="min 8 characters" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw]  gap-5">
            <button
              id="btnUpdatePassword"
              className="items-center text-black justify-center text-center flex flex-row gap-5 font-bold bg-gradient-to-b from-primaryYellow to-secondaryYellow hover:from-secondaryYellow hover:to-primaryYellow ... w-full h-[51.6px] mx-6 rounded-xl"
              onClick={render}
            >
              Update Password
            </button>
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
}
