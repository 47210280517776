import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import miscsStyles from "styles/miscs/miscs.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getAnnouncements } from "store/notifications/actions";
import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import { ReactComponent as Box } from 'assets/emptyBox.svg'
import Loader from "components/Loader";

export const announcementsCategories = [
    { key: "ALL", label: "All" },
    { key: "EVENTS", label: "Events" },
    { key: "UPDATES", label: "Updates" },
    { key: "NEWS", label: "News" },
];

const AppAnnouncements = () => {
    const [selectedCategory, setSelectedCategory] = useState(announcementsCategories[0]);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState({
        title: '',
        date: '',
        body: '',
        _id: ''
    });
    const { announcements, totalNotifications, loader } = useAppSelector((state) => state.notification);
    const isMobile = window.innerWidth <= 768;
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getAnnouncements({
            payload: { page: 1, category: selectedCategory.label }
        }))
    }, [])
    const darkTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    const onBack = () => {
        navigate('/')
    }
    const onCancel = () => {
        navigate('/')
    }
    const navigate = useNavigate();

    const handlePageChange = async (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        await dispatch(getAnnouncements({ payload: { page: value, category: selectedCategory.label } }));
    };


    useEffect(() => {
        dispatch(getAnnouncements({
            payload: { page: 1, category: selectedCategory.label }
        }))
    }, [selectedCategory])

    const renderCategories = (item: any, index: number) => {
        const isSelected = selectedCategory.key === item.key;

        const selectedContainerStyles = isSelected ? 'bg-white' : 'bg-inputGray'
        const selectedTextStyles = isSelected ? 'text-black' : 'text-white'

        const onSelectCategory = () => {
            setSelectedCategory(item)
        }

        return (
            <button className={`${selectedContainerStyles} flex items-center p-1 md:px-4  max-md:px-2 rounded-md`} key={index} onClick={onSelectCategory}>
                <span className={`${selectedTextStyles} text-sm`}>{item.label}</span>
            </button>
        )
    }
    const formatDate = (date: any) => {
        const dateValue = new Date(date);
        return dateValue.toLocaleString("en-US", {
            weekday: "short",
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    const renderAnnouncements = (item: any, index: number) => {

        const handleOnClickAnnouncement = () => {
            setSelectedAnnouncement(item);
        }
        const isSelected = item._id === selectedAnnouncement._id;
        const selectedStyles = 'border-2 border-primary'

        return (
            <div className={`bg-borderColor m-2 p-2 flex flex-col rounded-lg ${isSelected && selectedStyles}`} key={index} onClick={handleOnClickAnnouncement}>
                <div className="flex flex-row">
                    <span className="text-white font-bold text-[16px] max-md:text-[12px] lap:text-[14px]">{item?.title}</span>
                    <span className="text-white font-bold text-[16px] max-md:text-[12px] lap:text-[14px]"> - {formatDate(item?.date)}</span>
                </div>
                <span className="text-number text-[14px] max-md:text-[10px] lap:text-[12px]">{item?.body}</span>
            </div>
        )
    }

    const onRemoveSelection = () => {
        setSelectedAnnouncement({ title: '', date: '', body: '', _id: '' });
    }

    return (
        <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center  h-screen ">
            <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-center">
                <div
                    className="backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-170px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
                >
                    <div className="w-full h-[calc(90vh-160px)] flex flex-col items-center overflow-y-auto">
                        <div className="w-full rounded-t-[34px] flex items-center justify-between">
                            <div className="flex flex-row items-center gap-5">
                                <button className="flex rotate-180 w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onBack}>
                                    <img src={BackIcon} alt="Close" />
                                </button>
                                <span className="text-3xl text-white items-center font-normal max-md:text-[18px]">
                                    Announcements
                                </span>
                            </div>
                            <button className=" w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onCancel}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        {announcements && announcements?.length > 0 ?
                            (<div className="flex flex-row h-full mt-10 max-md:flex-col">
                                {selectedAnnouncement._id === '' && <div className="w-[30vw] desktop:w-[30vw] lap:w-[35vw] bg-bgBlack max-md:w-full max-md:h-full">
                                    <div className="flex flex-row items-center gap-2 m-4 overflow-y-hidden">
                                        {announcementsCategories.map(renderCategories)}
                                    </div>
                                    <div className={`overflow-x-hidden desktop:max-h-[58vh] lap:max-h-[50vh] max-md:h-full ${miscsStyles.beautifulScrollbarHidden}`}>
                                        {announcements?.length > 0 && announcements?.map(renderAnnouncements)}
                                    </div>
                                </div>}
                                {(selectedAnnouncement._id !== '' && !isMobile) && <div className="w-[30vw] desktop:w-[30vw] lap:w-[35vw] bg-bgBlack max-md:w-full max-md:h-full">
                                    <div className="flex flex-row items-center gap-2 m-4 overflow-y-hidden">
                                        {announcementsCategories.map(renderCategories)}
                                    </div>
                                    <div className={`overflow-x-hidden desktop:max-h-[58vh] lap:max-h-[50vh] max-md:h-full ${miscsStyles.beautifulScrollbarHidden}`}>
                                        {announcements?.length > 0 && announcements?.map(renderAnnouncements)}
                                    </div>
                                </div>}
                                <div className="w-[30vw] desktop:w-[30vw] lap:w-[35vw] max-md:w-full max-md:h-full flex flex-col bg-componentBackground">
                                    {selectedAnnouncement._id === '' && !isMobile &&
                                        (
                                            <div className="flex flex-col items-center justify-center h-full">
                                                <span className="text-white  bg-borderColor m-4 p-2 rounded-lg">Select an announcement to read</span>
                                            </div>
                                        )}
                                    {selectedAnnouncement._id !== "" && !isMobile && <div>
                                        <div className="flex flex-row bg-bgBlack h-[8vh] justify-center items-center">
                                            {isMobile && <button className="flex rotate-180 w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px] mx-4" onClick={onRemoveSelection}>
                                                <img src={BackIcon} alt="Close" />
                                            </button>}
                                            <span className="text-white font-bold text-[16px] max-md:text-[12px] lap:text-[14px]">{selectedAnnouncement?.title}</span>
                                            <span className="text-white font-bold text-[16px] max-md:text-[12px] lap:text-[14px]"> - {formatDate(selectedAnnouncement?.date)}</span>
                                        </div>
                                        <div className="m-2">
                                            <span className="text-white text-[16px] max-md:text-[10px] lap:text-[14px]">{selectedAnnouncement?.body}</span>
                                        </div>
                                    </div>}
                                    {selectedAnnouncement._id !== '' && isMobile &&
                                        (<div>
                                            <div className="flex flex-row bg-bgBlack h-[8vh] justify-center items-center">
                                                {isMobile && <button className="flex rotate-180 w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px] mx-4" onClick={onRemoveSelection}>
                                                    <img src={BackIcon} alt="Close" />
                                                </button>}
                                                <span className="text-white font-bold text-[16px] max-md:text-[12px] lap:text-[14px]">{selectedAnnouncement?.title}</span>
                                                <span className="text-white font-bold text-[16px] max-md:text-[12px] lap:text-[14px]"> - {formatDate(selectedAnnouncement?.date)}</span>
                                            </div>
                                            <div className="m-2">
                                                <span className="text-white text-[16px] max-md:text-[10px] lap:text-[14px]">{selectedAnnouncement?.body}</span>
                                            </div>
                                        </div>
                                        )}
                                </div>
                            </div>) :
                            <div className="flex flex-col h-full mt-10 max-md:flex-col justify-start">
                                <div className="flex flex-row justify-start items-start gap-2 m-4 overflow-y-hidden">
                                    {announcementsCategories.map(renderCategories)}
                                </div>
                                <div className="flex flex-col items-center mt-4 gap-4">
                                    <Box />
                                    <span className="text-white text-center">Stay tuned! New updates, events, and exciting announcements will be posted here soon. We're always working to bring you the latest news, features, and improvements. Check back later for fresh updates and stay connected with everything happening in the app!</span>
                                </div>
                            </div>}
                    </div>
                </div>
                <ThemeProvider theme={darkTheme}>
                    <Pagination
                        count={totalNotifications}
                        variant="outlined"
                        shape="rounded"
                        color="standard"
                        onChange={handlePageChange}
                    />
                </ThemeProvider>
                <Loader loading={loader} />
            </div>
        </div>
    )
}

export default AppAnnouncements;