import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/index";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { toast } from "react-toastify";
import ButtonDc from "components/Button";
import Header from "components/header";
import onlineGamesCoverPng from 'assets/online_games.png';
import PhysicalGamesCoverPng from 'assets/physical_games.png';
import BackIcon from "assets/arrow-right.svg";
import CloseIcon from "assets/close-button.svg";
import { resetPhysicalGameSlice } from "store/physicalGames/physicalGamesSlice";

export default function SelectGameModeScreen({ }) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isSuspended = useAppSelector(
        (state: RootState) => state.user
    ).user?.isSuspended;

    const onBack = () => {
        navigate('/wagering');
    }
    const onCancel = () => {
        navigate('/');
    }

    return (
        <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
            <div className="flex flex-col w-full h-auto xl:h-screen p-8 overflow-x-hidden">
                <Header
                    onClick={() => navigate("/login")}
                    onPressUser={() => {
                        navigate("/profile");
                    }}
                />
                <div className=" w-full  md:gap-[2em] flex flex-col items-center mt-20 max-md:gap-3 max-md:my-20">
                    <div
                        className="flex flex-col backdrop-blur-sm con rounded-[32px] p-10 max-md:p-5 w-full max-md:w-[calc(100%-10px)] items-center justify-center"
                    >
                        <div className="w-full rounded-t-[34px] flex items-center justify-between">
                            <div className="flex flex-row items-center gap-5">
                                <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                                    <img src={BackIcon} alt="Close" />
                                </button>
                                <span className="text-3xl text-white items-center font-normal max-md:text-[14px]">
                                    Play Match
                                </span>
                            </div>
                            <button className=" w-[25px] h-[25px]" onClick={onCancel}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        <div className="flex flex-col gap-4 max-md:mt-4">
                            <span className=" text-white text-center text-3xl font-bold max-md:text-lg">
                                Select Game Type
                            </span>
                            <span className="pr-2 text-white text-center text-lg max-md:text-[12px]">
                                Choose how you want to compete! Challenge online players in exciting multiplayer games or face off against friends in classic physical board games.
                            </span>
                        </div>
                        <div className={`flex desktop:flex-row flex-col items-center justify-center desktop:mt-10 gap-10 md:my-8 max-md:gap-2 max-md:my-3`}>
                            <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-8 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg  hover:ring-2 ring-primaryActive"
                                onClick={() => {
                                    if (isSuspended) return toast.warning('Account Suspended');
                                    navigate("/duels");
                                }}>
                                <img src={onlineGamesCoverPng} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                                <div className="flex flex-col justify-center gap-2 items-center">
                                    <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Challenge Online Players</span>
                                    <span className="text-disabled text-center md:w-[30vw]  desktop:text-xl max-md:text-sm mb-6">
                                        Compete in fast-paced multiplayer games like PUBG, COD, and more. Show off your skills against global opponents!
                                    </span>
                                    <ButtonDc
                                        type="primary"
                                        text="Start Online Match"
                                        short
                                        action={() => {
                                            if (isSuspended) return toast.warning('Account Suspended');
                                            navigate("/duels");
                                        }}
                                    />
                                </div>
                            </button>
                            <button className="flex desktop:flex-col max-md:flex-col rounded-xl p-4 md:p-8 items-center justify-center bg-borderColor gap-2 transform transition-transform hover:scale-105 hover:shadow-lg hover:ring-2 ring-primaryActive"
                                onClick={() => {
                                    if (isSuspended) return toast.warning('Account Suspended');
                                    dispatch(resetPhysicalGameSlice())
                                    navigate('/physical-games/setup');
                                }}>
                                <img src={PhysicalGamesCoverPng} alt="Stake Cover" className="w-[30vw] desktop:w-[40vh] rounded-xl" />
                                <div className="flex flex-col justify-center gap-2 items-center">
                                    <span className="text-white font-bold desktop:text-2xl max-md:text-lg mt-2">Challenge a Friend in Physical Games</span>
                                    <span className="text-disabled text-center  md:w-[30vw] desktop:text-xl max-md:text-sm mb-6">
                                        Play classic games like Chess, Ludo, and Scrabble with a friend. Test your strategy in head-to-head matches!
                                    </span>
                                    <ButtonDc
                                        type="primary"
                                        text="Start Physical Game"
                                        action={() => {
                                            if (isSuspended) return toast.warning('Account Suspended');
                                            dispatch(resetPhysicalGameSlice())
                                            navigate('/physical-games/setup');
                                        }}
                                        short
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
