import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import CloseIcon from "assets/close-button.svg";
import { AppDispatch, RootState } from "store/index";
import ImageHolder from "components/ImagePlaceholder";
import ButtonDc from "components/Button";
import { ReactComponent as MinusIcon } from "assets/minus_icon.svg";
import { gameTypes } from "constant/types";
import { createTournamentMatch } from "store/tournament/action";
import {
  getStagedPlayers,
  getTournamentById,
} from "store/liveBrackets/actions";
import LoadingAnim from "components/Loader";

interface MembersModalProps {
  visible: boolean;
  data: any[];
  onClose: () => void;
  onPress?: (data: any) => void;
  selected: any[];
}

const MembersModal: React.FC<MembersModalProps> = ({
  visible,
  onClose,
  data,
  onPress,
  selected,
}) => {
  if (!visible) return null;

  const renderMembers = (member: any, index: number) => {
    const alreadySelected = selected.find(
      (selectedItem) => selectedItem?._id === member?._id
    );
    return (
      <>
        {!alreadySelected && (
          <button
            key={index}
            className="flex items-center gap-4 mb-4"
            onClick={() => onPress?.(member)}
          >
            <ImageHolder
              uri={member?.profilePicture}
              className="w-12 h-12 rounded-full"
            />
            <span className="text-white">{member?.username}</span>
          </button>
        )}
      </>
    );
  };
  return (
    <div className="fixed flex-col inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className=" md:w-1/3 h-1/3 w-[90vw]  bg-borderColor rounded-2xl justify-center items-center flex flex-col text-slate-100 shadow-xl ">
        <span className="text-xl font-bold text-white mb-4">Match Players</span>
        <div className="p-6  rounded-lg flex flex-col items-center overflow-y-auto">
          <div className="overflow-y-auto max-h-96">
            {data.map(renderMembers)}
          </div>
          <button
            className="mt-4 px-4 py-2 bg-primary text-black rounded hover:bg-primaryActive focus:outline-none"
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default function CreateMatchScreen({ }) {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const tournament = useAppSelector((state: RootState) => state.tournament);
  const liveBracket = useAppSelector((state: RootState) => state.liveBrackets);

  const liveBracketTournament = useAppSelector(
    (state: RootState) => state.liveBrackets.tournamentInfo
  );
  const tournamentId = tournament.tournamentId;
  const stagedPlayers = liveBracketTournament.players;
  const [teamOnePlayers, setTeamOnePlayers] = useState<any[]>([]);
  const [teamTwoPlayers, setTeamTwoPlayers] = useState<any[]>([]);
  const tournamentDate = liveBracketTournament.tournamentDate;
  const gameType = liveBracketTournament.gameType;
  const selectedConsole = tournament.console;
  const selectedProvider = tournament.selectedPlatform;
  const isPhysicalMatch = liveBracketTournament.isPhysicalGame;

  let playerCount = 0;
  switch (gameType) {
    case "ONE_VS_ONE":
      playerCount = 1;
      break;
    case "TWO_VS_TWO":
      playerCount = 2;
      break;
    case "THREE_VS_THREE":
      playerCount = 3;
      break;
    case "FOUR_VS_FOUR":
      playerCount = 4;
      break;
    case "FIVE_VS_FIVE":
    case "FFA":
      playerCount = 5;
      break;
    default:
      playerCount = 0;
      break;
  }

  const [playerModalVisible, setPlayerModalVisible] = useState(false);
  const [selectingPlayerOne, setSelectingPlayerOne] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(getStagedPlayers({ payload: tournamentId }));
  }, []);

  const createButtonDisabled =
    playerCount !== teamOnePlayers?.length ||
    playerCount !== teamTwoPlayers?.length || isSubmitting;

  const onCancel = () => {
    if (isPhysicalMatch) return navigate(`/physical-games/live-bracket/${tournamentId}`);
    navigate(`/tournament/live-bracket/${tournamentId}`);
  };

  const renderStagedPlayers = (player: any, index: number) => {
    const truncatedUsername =
      player?.username?.length > 8 ? `${player?.username?.slice(0, 8)}...` : player.username;
    return (
      <div
        key={index}
        className="flex flex-col items-center justify-center gap-1"
      >
        <div className="rounded-full border-primary border-4 p-1">
          <ImageHolder
            uri={player?.profilePicture}
            className="flex w-[50px] h-[50px] rounded-full"
          />
        </div>
        <span className="text-white">{truncatedUsername}</span>
      </div>
    );
  };

  const renderTeams = (player: any, index: number) => {
    const handleMinusPress = () => {
      //remove player from team
      const newTeamOnePlayers = teamOnePlayers.filter(
        (teamPlayer) => teamPlayer._id !== player._id
      );
      const newTeamTwoPlayers = teamTwoPlayers.filter(
        (teamPlayer) => teamPlayer._id !== player._id
      );
      setTeamOnePlayers(newTeamOnePlayers);
      setTeamTwoPlayers(newTeamTwoPlayers);
    };

    return (
      <div
        key={player?._id}
        className="flex flex-col items-center justify-center gap-1"
      >
        <div className="rounded-full border-primary border-4 p-1">
          <ImageHolder
            uri={player?.profilePicture}
            className="flex w-[50px] h-[50px] rounded-full max-md:h-[30px] max-md:w-[30px]"
          />
        </div>
        <div className=" h-[90px] w-[80px] absolute">
          <button onClick={handleMinusPress}>
            <MinusIcon />
          </button>
        </div>
        <span className="text-white max-md:text-[12px]">
          {player?.username}
        </span>
      </div>
    );
  };

  const onSelectedPlayer = (player: any) => {
    let playerList = [];
    if (player?.user) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
    if (player.teamId) {
      playerList = stagedPlayers
        ? stagedPlayers.filter(
          (stagedPlayer) => stagedPlayer.teamId === player.teamId
        )
        : [];
    } else {
      playerList = [player];
    }
    if (selectingPlayerOne) {
      setTeamOnePlayers([...teamOnePlayers, ...playerList]);
    } else {
      setTeamTwoPlayers([...teamTwoPlayers, ...playerList]);
    }
    if (gameType === gameTypes.ONE_VS_ONE) {
      setPlayerModalVisible(false);
    }
  };

  const handlePlayerOneButton = () => {
    setPlayerModalVisible(true);
    setSelectingPlayerOne(true);
  };

  const handlePlayerTwoButton = () => {
    setPlayerModalVisible(true);
    setSelectingPlayerOne(false);
  };

  const createMatch = () => {
    setIsSubmitting(true);
    const data = {
      tournamentId: tournamentId,
      scheduledTime: tournamentDate.toString(),
      team1: isUser
        ? teamOnePlayers.map((player) => player.user)
        : teamOnePlayers.map((player) => player._id),
      team2: isUser
        ? teamTwoPlayers.map((player) => player.user)
        : teamTwoPlayers.map((player) => player._id),
      consoleType: selectedConsole.consoleType,
      providerType: selectedProvider,
    };
    dispatch(
      createTournamentMatch({
        payload: { data },
        callback: () => {
          dispatch(
            getTournamentById({
              payload: tournamentId,
              callback: () => {
                if (isPhysicalMatch) return navigate(`/physical-games/live-bracket/${tournamentId}`);
                navigate(`/tournament/live-bracket/${tournamentId}`);
              }

            })
          );
        },
      })
    );
  };
  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-3xl text-white items-center font-normal max-md:text-[18px]">
                {isPhysicalMatch ? 'Physical Game' : 'Tournament'}
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <span className="text-subText text-2xl font-normal text-center max-md:text-[18px]">
              Choose Players for the Match
            </span>
          </div>

          <div className="flex flex-col items-center justify-center gap-[2em] my-8 max-md:flex-col">
            <div className="flex flex-row items-center justify-center gap-2">
              {stagedPlayers.map(renderStagedPlayers)}
            </div>
            <div className="flex flex-row justify-between gap-20 max-md:gap-10 overflow-auto">
              <div className="flex flex-col items-center justify-between gap-2">
                <div className="flex flex-col items-center p-2 max-md:gap-4">
                  <span className="text-white text-xl">Team 1</span>
                  <ButtonDc
                    text="Add to Team 1"
                    action={handlePlayerOneButton}
                    type={"primary"}
                    short
                    disabled={!createButtonDisabled}
                  />
                </div>
                <div className="flex flex-col items-center justify-center gap-2">
                  {teamOnePlayers.map(renderTeams)}
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-2">
                <div className="flex flex-col items-center p-2 max-md:gap-4">
                  <span className="text-white text-xl">Team 2</span>
                  <ButtonDc
                    text="Add to Team 2"
                    action={handlePlayerTwoButton}
                    type={"primary"}
                    short
                    disabled={!createButtonDisabled}
                  />
                </div>
                <div className="flex flex-col items-center justify-center gap-2">
                  {teamTwoPlayers.map(renderTeams)}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-6 right-0 left-0 max-md:m-4">
            <ButtonDc
              text="Confirm"
              action={createMatch}
              type={"primary"}
              disabled={createButtonDisabled}
            />
          </div>
        </div>
      </div>
      <MembersModal
        data={stagedPlayers}
        visible={playerModalVisible}
        onClose={() => setPlayerModalVisible(false)}
        onPress={onSelectedPlayer}
        selected={[...teamOnePlayers, ...teamTwoPlayers]}
      />
      <LoadingAnim loading={tournament.loading || liveBracket.loading} />
    </div>
  );
}
