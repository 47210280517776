import { createMainTournamentApi } from "api/tournament.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
  err?: () => void;
}

export const createPhysicalMatch = createAsyncThunk<any, WithCallback>(
  "/physicalMatch/createMainTournament",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await createMainTournamentApi(payload.tournamentData);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
