const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "http://192.168.8.102:4000";

export const signOutUrl = BASE_URL + "/user/sign-out";
export const livesUrl = BASE_URL + "/stream/lives";
export const allEventsUrl = BASE_URL + "/match/get-duels";
export const getAllTournamentsUrl = BASE_URL + "/tournament/get-all";
export const getGamesUrl = BASE_URL + "/match/games";
export const signInUrl = BASE_URL + "/auth/signin";
export const signUpUrl = BASE_URL + "/auth/signup";

export const getLobbyUrl = BASE_URL + "/match/match-details";
export const setReadyForMatchUrl = BASE_URL + "/match/ready-state";
export const startGameUrl = BASE_URL + "/match/start-match";
export const endMatchUrl = BASE_URL + "/match/end-duel";
export const cancelMatch = BASE_URL + "/match/cancel-match";
export const leaveLobbyUrl = BASE_URL + "/match/leave-duel";

export const getStakesAvailableEventsUrl = BASE_URL + "/match/stake-available";
export const getPrizePoolValueUrl = BASE_URL + "/user/price-pool";
export const getWinnersUrl = BASE_URL + "/match/winners";
export const createMatchUrl = BASE_URL + "/match/create";
export const getCommunityFriendsUrl = BASE_URL + "/user/friends";
export const getCommunityDuelistsUrl = BASE_URL + "/user/duelest";
export const inviteToMatchUrl = BASE_URL + "/match/invite-match";

export const sendOpponentInvitationUrl = BASE_URL + "/match/invite-opponent";
export const getPresetsUrl = BASE_URL + "/user/presets";
export const joinLobbyUrl = BASE_URL + "/match/join-match";
export const voteMatchUrl = BASE_URL + "/match/vote-duel";

export const getNotificationsUrl = BASE_URL + "/user/notifications";
export const handleNotificationUrl = BASE_URL + "/user/handle-notification";
export const getOtherUserUrl = BASE_URL + "/user/user-profile";
export const userUrl = BASE_URL + "/user/details";
export const setVerifyEmailUrl = BASE_URL + "/auth/verify-email";
export const setResendEmailUrl = BASE_URL + "/auth/resend-email";
export const confirmUserEmailOtpUrl = BASE_URL + "/user/update-email";
export const getMatchmakingDuelsUrl = BASE_URL + "/match/match-making-duel";

export const subscribeToTopicUrl = BASE_URL + "/user/subscribe-topic";

export const getBalanceUrl = BASE_URL + "/user/get-balance";
export const sendPayPalAuthCodeUrl = BASE_URL + "/user/paypal-data";
export const linkedAccountsUrl = BASE_URL + "/common/get-linked-accounts";
export const checkBalanceUrl = BASE_URL + "/user/check-balance";
export const capturePaymentUrl = BASE_URL + "/pay/capture";
export const digitalPaymentUrl = BASE_URL + "/pay/digital-payment";
export const getDisputesUrl = BASE_URL + "/user/disputes";
export const closeMatchUrl = BASE_URL + "/match/cancel-match";

export const uploadImageUrl = BASE_URL + "/user/profilepicture";
export const searchGamesUrl = BASE_URL + "/match/search-games";
export const usersSearchUrl = BASE_URL + "/common/usersearch";
export const getStakeAmountUrl = BASE_URL + "/match/stake-amount";
export const stakeUrl = BASE_URL + "/match/stake";
export const getStakedMatchesUrl = BASE_URL + "/match/get-staked-matches";
export const followUserUrl = BASE_URL + "/user/follow";
export const unFollowUserUrl = BASE_URL + "/user/unfollow";
export const getFollowersUrl = BASE_URL + "/user/get-followers";
export const getFollowingUrl = BASE_URL + "/user/get-following";
export const getCurrentMatchUrl = BASE_URL + "/user/current-match";
export const getQuickAccessUrl = BASE_URL + "/user/quick-access";
export const getReceiversUrl = BASE_URL + "/user/all-receivers";
export const searchReceiversUrl = BASE_URL + "/user/search-receivers";
export const sendMoneyUrl = BASE_URL + "/user/send-money";
export const requestPayoutUrl = BASE_URL + "/pay/request-payout";
export const requestMoneyUrl = BASE_URL + "/pay/request-money";
export const getPrivateChatListUrl = BASE_URL + "/user/private-chats";
export const getCommunityListUrl = BASE_URL + "/community/community-list";
export const walletIdUrl = BASE_URL + "/user/wallet-identification";
export const getTrasactionHistoryUrl =
  BASE_URL + "/user/get-transaction-history";
export const getPersonalChatMessagesUrl = BASE_URL + "/user/private-chats";
export const createCommunityUrl = BASE_URL + "/community/community";
export const sendPersonalMessageUrl = BASE_URL + "/chat/direct";
export const deletePrivateChatUrl = BASE_URL + "/user/delete-private-chat";
export const searchChatUrl = BASE_URL + "/user/search-private-chat";
export const searchCommunityUrl = BASE_URL + "/community/search-communities";

export const getCommunityChatUrl = BASE_URL + "/community/community-chats";
export const sendCommunityMessageUrl = BASE_URL + "/chat/community";
export const joinCommunityUrl = BASE_URL + "/community/join-community";
export const deleteCommunityUrl = BASE_URL + "/community/delete-community";
export const leaveCommunityUrl = BASE_URL + "/community/leave-community";
export const editCommunityUrl = BASE_URL + "/community/edit-community";
export const getCommunityParticipantsUrl =
  BASE_URL + "/community/community-members";
export const removeFollowerUrl = BASE_URL + "/user/remove-follower";
export const inviteCommunityUrl = BASE_URL + "/chat/invite-community";

export const sendOTPUrl = BASE_URL + "/auth/forget-password";
export const validateOtpUrl = BASE_URL + "/auth/validate-otp";
export const resetPasswordUrl = BASE_URL + "/auth/reset-password";
export const createMainTournamentUrl = BASE_URL + "/tournament/create";
export const getMatchChatUrl = BASE_URL + "/match/match-chats";
export const sendMatchMessageUrl = BASE_URL + "/chat/match";
export const inviteToTournamentUrl = BASE_URL + "/tournament/invite-player";

export const getTournamentByIdUrl = BASE_URL + "/tournament/data";
export const deleteTournamentUrl = BASE_URL + "/tournament/delete-tournament";
export const getStagedPlayersUrl = BASE_URL + "/tournament/players";
export const createTournamentMatchUrl = BASE_URL + "/tournament/create-match";
export const updateTournamentDateUrl = BASE_URL + "/tournament/update-date";
export const startTournamentUrl = BASE_URL + "/tournament/start";
export const goToYourMatchUrl = BASE_URL + "/tournament/join-match";
export const goToNextStageUrl = BASE_URL + "/tournament/next-stage";
export const endTournamentUrl = BASE_URL + "/tournament/end-tournament";
export const getTournamentMatchesUrl = BASE_URL + "/tournament/matches";
export const joinTournamentUrl = BASE_URL + "/tournament/join";
export const leaderBoardUrl = BASE_URL + "/match/leaderboard";
export const updateTwitchUserUrl = BASE_URL + "/user/twitch-data";
export const updateXboxUserUrl = BASE_URL + "/user/xbox-data";
export const savePsUrl = BASE_URL + "/user/psn-data";
export const saveEaUrl = BASE_URL + "/user/ea-data";
export const saveEpicUrl = BASE_URL + "/user/epic-data";
export const saveStreetFighterUrl = BASE_URL + "/user/street-fighter-data";
export const mlbUrl = BASE_URL + "/user/mlb-data";
export const saveActivitionUrl = BASE_URL + "/user/activision-data";
export const battleUrl = BASE_URL + "/user/battle-net-data";
export const nintendoUrl = BASE_URL + "/user/nintendo-data";
export const steamUrl = BASE_URL + "/user/steam-data";
export const originUrl = BASE_URL + "/user/origin-data";

export const checkPasswordUrl = BASE_URL + "/user/check-password";
export const techSupportUrl = BASE_URL + "/common/tech-support";
export const deleteAccountUrl = BASE_URL + "/user/delete";
export const getEndedTournamentsUrl =
  BASE_URL + "/tournament/user-available?tournamentStatus=ENDED&page=";
export const deleteTournamentMatchUrl = BASE_URL + "/tournament/delete-match";
export const userValidityUrl = BASE_URL + "/auth/valid-user";

export const createSteamUrl = BASE_URL + "/stream/create-stream";
export const streamForExternalUrl = BASE_URL + "/stream/stream-data";
export const validateSteamUrl = BASE_URL + "/stream/validate-stream";
export const checkStreamingUrl = BASE_URL + "/stream/check-stream";
export const getStreamUrl = BASE_URL + "/stream/get-stream";
export const getLiveStreamsUrl = BASE_URL + "/stream/lives";
export const endStreamUrl = BASE_URL + "/stream/stop-stream";
export const startRecordingUrl = BASE_URL + "/stream/start-record";
export const stopRecordingUrl = BASE_URL + "/stream/stop-record";
export const referralUrl = BASE_URL + "/user/referrals";
export const facebookSignUpUrl = BASE_URL + "/auth/facebook";
export const leaveTournamentUrl = BASE_URL + "/tournament/leave-tournament";

export const deleteFacebookAccountUrl = BASE_URL + "/auth/facebook";
export const deleteAppleAccountUrl = BASE_URL + "/auth/apple";
export const deleteGoogleAccountUrl = BASE_URL + "/auth/google";
export const enableStakingUrl = BASE_URL + "/match/enable-staking";

export const createChallengeUrl = BASE_URL + "/minigame/challenge";
export const getGameChallengeUrl = BASE_URL + "/minigame/challenges";
export const startGameChallengeUrl = BASE_URL + "/minigame/challenge/start";
export const endGameChallengeUrl = BASE_URL + "/minigame/challenge/end";
export const cancelGameChallengeUrl = BASE_URL + "/minigame/challenge/cancel";
export const leaveGameChallengeUrl = BASE_URL + "/minigame/challenge/leave";
export const sendGameChallengeUrlInvitationUrl =
  BASE_URL + "/minigame/challenge/invite";
export const joinGameChallengeUrl = BASE_URL + "/minigame/challenge/join";
export const setReadyForChallengeUrl =
  BASE_URL + "/minigame/challenge/ready-state";
export const getWinnerGameChallengeUrl =
  BASE_URL + "/minigame/challenge/winner";

export const getMatchTimerUrl = BASE_URL + "/match/lobby-timer";
export const getPaypalHistoryUrl = BASE_URL + "/user/get-transaction-history";
export const getMinigamesLeaderboardUrl =
  BASE_URL + "/minigame/challenge/player-stats";
export const getStakeAvailableTournamentsUrl =
  BASE_URL + "/tournament/stake-available";
export const stakeSpecificTournamentUrl = BASE_URL + "/tournament/stake";
export const getStakedTournamentsUrl =
  BASE_URL + "/tournament/staked-tournaments";
export const getStakingAvailableChallengesUrl =
  BASE_URL + "/minigame/challenges/stake-available";
export const getStakedChallengesUrl = BASE_URL + "/minigame/challenges/staked";
export const stakeSpecificChallengeUrl = BASE_URL + "/minigame/challenge/stake";
export const stakeAvailableEventsUrl =
  BASE_URL + "/common/stake-available-events";
export const getAnnouncementsUrl = BASE_URL + "/user/announcements";
