import Star from "assets/chatGifts/star.svg";
import Flowers from "assets/chatGifts/flowers.svg";
import Tea from "assets/chatGifts/tea.svg";
import Gift from "assets/chatGifts/gift.svg";

export const chatGifts = [
  {
    id: '1',
    label: "Star",
    amount: 10,
    image: Star,
  },
  {
    id: '2',
    label: "Flowers",
    amount: 20,
    image: Flowers,
  },
  {
    id: '3',
    label: "Coffee",
    amount: 50,
    image: Tea,
  },
  {
    id: '4',
    label: "Gift Box",
    amount: 100,
    image: Gift,
  },
];
