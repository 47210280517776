import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getMatchMakingEvents } from "store/availableToPlay/action";
import LoadingAnim from "components/Loader";
import SearchIcon from "assets/search.svg";
import ImageHolder from "components/ImagePlaceholder";
import {
  setConsole,
  setJoiningMatchId,
  setMatchCubes,
  setSelectedGame,
  setSelectedGameType,
  setSelectedRound,
} from "store/matchMaking/matchMakingSlice";
import { joinLobby } from "store/matchMaking/actions";

const MatchmakingScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedGame, matchCubes, selectedGameType, selectedPlatform, loader } =
    useAppSelector((state) => state.matchMaking);
  const { console } =
    useAppSelector((state) => state.tournament);
  const loading = useAppSelector((state) => state.availableToPlay).loader

  const { matchMakingDuels } = useAppSelector(
    (state) => state.availableToPlay
  );
  const consoleType = console.consoleType;

  useEffect(() => {
    dispatch(
      getMatchMakingEvents({
        game: selectedGame?._id,
        gameType: selectedGameType.type,
        cubeWager: parseInt(matchCubes),
      })
    );
  }, [dispatch]);

  const handleClose = () => {
    navigate("/");
  };

  const handleClick = (data: any) => {
    //TODO -> check provider

    dispatch(setJoiningMatchId(data._id));
    dispatch(
      setSelectedGame({
        platforms: { xbox: false, playstation: false, pc: false },
        _id: data.game,
        name: "",
        image: "",
        consoles: [],
        rounds: [],
        gameTypes: [],
      })
    );
    dispatch(setSelectedGameType(data.gameType));
    dispatch(setSelectedRound({ _id: "", name: data.rounds }));
    dispatch(setMatchCubes(data.cubeWager));
    dispatch(joinLobby({ matchId: data._id, consoleType: consoleType, providerType: selectedPlatform })).then(
      () => {
        navigate(`/duels/lobby/${data._id}`);
      }
    );

  };


  const renderEvents = (item: any, index: number) => {
    return (
      <button
        className="flex flex-col p-2 m1 items-center w-[250px] h-[200px] bg-modalFade rounded-lg m-5 transform transition-transform hover:scale-105 hover:shadow-lg hover:ring hover:ring-[#FFD482]"
        key={index}
        onClick={() => handleClick(item)}
      >
        <span className="text  text-[12px] ">{item.game.name}</span>
        <ImageHolder
          uri={item.coverImage}
          width={180}
          height={180}
          className=" rounded-xl mb-1"
        />
        <div className=" flex flex-col justify-start items-center  ">
          <span className="text  text-[12px] ">
            Total Prize : {item.cubeWager}
          </span>
          <span className="text  text-[12px] ">
            Game type : {item.gameType}
          </span>
          <span className="text  text-[12px] ">Rounds : {item.rounds}</span>
        </div>
      </button>
    );
  };
  const onClickMatchmaking = () => {
    navigate("/duels/duel-overview");
  };

  const renderHeader = () => {
    return (
      <span className="text-white md:mt-16 max-md:text-[12px]">
        Sorry, We haven't any created matches.{"\t"}
        <button className="text-primary" onClick={onClickMatchmaking}>
          Click here
        </button>
        {"\t"}to create new Match.
      </span>
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  p-2">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Select a match
            </span>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="w-full min-h-[64px] bg-borderColor rounded-xl flex items-center px-[40px] max-md:mt-5">
            <img src={SearchIcon} alt="" />
            <input
              type="text"
              className="w-full h-full bg-transparent outline-none ml-[20px] placeholder:text-placeholder font-normal size text-[14px] text-white"
              placeholder="Search"
            />
          </div>
          <div className="w-full flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-2/3">
            {matchMakingDuels.length > 0
              ? matchMakingDuels?.map(renderEvents)
              : renderHeader()}
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader || loading} />
    </div>
  );
};

export default MatchmakingScreen;
