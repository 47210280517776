import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { ping } from "ldrs";
import { endGameChallenge, getGameChallenge } from "store/gameHub/action";
import { games } from "types/games";
import { toast } from "react-toastify";

import { ReactComponent as Check } from "assets/success.svg";
import Refresh from "assets/refresh_icon.svg";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";

const GameWaitingScreen = () => {

  const { challengeId } = useParams();
  const dispatch = useAppDispatch();
  ping.register();
  const navigate = useNavigate();
  const [enableEnd, setEnableEnd] = useState(false);

  const fcmData = useAppSelector((state) => state.fcm);
  const gameHub = useAppSelector((state: RootState) => state.gameHub);
  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const loading = gameHub.loader;
  const isHost = gameHub.currentGame.host === userId;

  const currentGame = gameHub?.currentGame;
  const gameData = games.find((game: any) => game.id === currentGame?.gameId._id);
  const bothFinished = currentGame.finishedUsers.length === 2
  const challengeEnded = currentGame.challengeStatus === "ENDED"
  const gameBanner = gameData?.image;
  const title = gameData?.title

  useEffect(() => {
    if (fcmData?.data?.type === "PROMPT_END_CHALLENGE" || bothFinished) {
      dispatch(getGameChallenge({ matchId: challengeId }));
      setEnableEnd(true);
    }
    if (fcmData?.data?.type === "CHALLENGE_ENDED" || challengeEnded) return navigate(`/gaming/game-results/${challengeId}`)
  }, [fcmData, bothFinished, challengeEnded]);

  const handleEnd = () => {
    dispatch(endGameChallenge({
      matchId: challengeId,
      callback: () => { dispatch(getGameChallenge({ matchId: challengeId, callback: () => navigate(`/gaming/game-results/${challengeId}`) })); }
    }));
  }

  const onRefresh = () => {
    dispatch(getGameChallenge({ matchId: challengeId }));
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="backdrop-blur-sm con rounded-[34px] p-10 flex flex-col justify-center
        w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[calc(100%)]"
        >
          <div className="flex flex-row max-md:justify-between  justify-center items-center gap-4">
            <button
              className=" w-[25px] h-[25px] absolute right-12"
              onClick={onRefresh}
            >
              <img src={Refresh} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col items-center mt-10">
            <span className="text-white text-center font-aldo text-4xl mb-5">{title}</span>
            <img
              className="md:h-[30vh] rounded-xl"
              src={gameBanner}
              alt="Game Banner"
            />
            <div className="flex flex-col justify-center items-center gap-y-10">
              <Check className="w-[10vh] h-[10vh]" />

              <div className="justify-center items-center  text-center">
                <span className="text-3xl text-white items-center font-bold text-center">
                  Your score has been recorded
                </span>
              </div>

              {!enableEnd || !isHost ? (<div className="flex flex-col justify-center items-center bg-borderColor py-5 px-28  rounded-[12px] gap-5 max-md:px-5">
                <span className="text-xl text-white items-center text-center font-normal">
                  Waiting for others to end match
                </span>
                <l-ping size="45" speed="2" color="white"></l-ping>
              </div>) : (<>
                {isHost && <ButtonDc text="End Challenge" action={handleEnd} type="primary" />}</>)}
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loading} />
    </div>
  );
};

export default GameWaitingScreen;
