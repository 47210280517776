import React from "react";
import { ReactComponent as Arrow } from "assets/arrow-right-gaming.svg";
import ImageHolder from "components/ImagePlaceholder";

interface WinnerDisplayProps {
  team1JSON: any[];
  team2JSON: any[];
  winner: string;
}

const WinnerDisplay: React.FC<WinnerDisplayProps> = ({
  team1JSON,
  team2JSON,
  winner,
}) => {
  const renderTeam = (team: any[]) => {
    const columns = Math.ceil(team.length / 2);
    const splitTeam = [];
    for (let i = 0; i < team.length; i += columns) {
      splitTeam.push(team.slice(i, i + columns));
    }
    return splitTeam.map((column, columnIndex) => (
      <div key={columnIndex} className="flex flex-col items-center gap-1">
        {column.map((player, index) => (
          <div className="flex flex-col items-center m-2">
            <ImageHolder
              key={index}
              uri={player?.profilePicture}
              className="w-12 h-12 rounded-md flex"
            />
            <span className="text-primary">{player.username}</span>
          </div>
        ))}
      </div>
    ));
  };

  const renderWinner = (winner: string) => {
    const winningTeam = winner === "1" ? team1JSON : team2JSON;
    return renderTeam(winningTeam);
  };

  return (
    <div className="flex flex-row gap-5 justify-evenly">
      <div className="flex flex-row items-center gap-3">
        <div className="flex flex-row items-center gap-2 border border-white rounded-lg p-2">
          <div className="flex flex-row gap-1">{renderTeam(team1JSON)}</div>
          <span className="text-white text-l">VS</span>
          <div className="flex flex-row gap-1">{renderTeam(team2JSON)}</div>
        </div>
        <div>
          <Arrow width={25} height={20} />
        </div>
        <div className="flex flex-row items-center gap-2 border border-white rounded-lg p-2">
          <div className="flex flex-row gap-1">{renderWinner(winner)}</div>
        </div>
      </div>
    </div>
  );
};

export default WinnerDisplay;
