import React from "react";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import { gameTypeValues, gameTypes } from "types/constants";
import PhysicalGamesCoverPng from 'assets/physical_games.png';

interface TournamentInfoModalProps {
  closeModal?: () => void;
  data?: any;
  isPhysicalMatch?: boolean;
}
const TournamentInfoModal: React.FC<TournamentInfoModalProps> = ({
  data,
  isPhysicalMatch,
  closeModal,
}) => {
  interface EditableRowProps {
    title: string;
    subtitle: string;

  }

  const isDuel = data?.matchType === "DUEL";
  const EditableRow: React.FC<EditableRowProps> = ({ title, subtitle }) => {
    return (
      <div className="flex flex-row bg-borderColor items-center justify-between gap-5  w-1/2 h-16 rounded-lg p-2  max-md:w-11/12">
        <div className="flex flex-col justify-between gap-1">
          <span className="text-white">{title}</span>
          <span className="text-placeholder text-xs">{subtitle}</span>
        </div>
      </div>
    );
  };
  const formattedDate = new Date(isDuel ? data?.createdAt : data?.tournamentDate).toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex min-h-full justify-center px-2 py-12 text-center ">
            <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-componentBackground bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-5 py-4">
                <button
                  type="button"
                  className="rounded-md p-1 inline-flex items-center justify-center focus:outline-none absolute top-3 right-2"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close menu</span>
                  <CloseIcon />
                </button>
                <span className="text-2xl text-white items-center font-normal">
                  {isDuel ? "Duel Info" : isPhysicalMatch ? 'Game Info' : "Tournament Info"}
                </span>
              </div>

              <div className="flex flex-col items-center mt-10">
                <img
                  className="md:w-[40vh] rounded-xl"
                  src={isPhysicalMatch ? PhysicalGamesCoverPng : data?.coverImage}
                  alt="Game Banner"
                />
                <span className="text-white text-center mt-5">
                  Load into a non-ranked{" "}
                  {gameTypeValues[data?.gameType as gameTypes]} match with your
                  opponent.
                  <br />
                  Whoever wins the {data?.rounds} match(es) is the winner
                </span>
              </div>
              <div className="flex flex-col items-center gap-5 md:mt-[10px] max-md:mt-5">
                <span className="text-primary">{formattedDate}</span>
                {isPhysicalMatch ?
                  (
                    <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-between">
                      {data?.cubeWager === 0 ? (
                        <EditableRow
                          title="Free Play"
                          subtitle={''}
                        />) : (<EditableRow
                          title="Cube Wager"
                          subtitle={`$${data?.cubeWager}`}
                        />)}
                      <EditableRow
                        title="Game Type"
                        subtitle={gameTypeValues[data?.gameType as gameTypes]}
                      />
                    </div>
                  )
                  :
                  (
                    <>
                      <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-between">
                        <EditableRow title="Best" subtitle={data?.rounds} />
                        <EditableRow
                          title="Game Type"
                          subtitle={gameTypeValues[data?.gameType as gameTypes]}
                        />
                      </div>
                      <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-between">
                        {data?.cubeWager === 0 ? (<EditableRow
                          title="Free Play"
                          subtitle={''}
                        />) : (<EditableRow
                          title="Cube Wager"
                          subtitle={`$${data?.cubeWager}`}
                        />)}
                        <EditableRow
                          title="Designated Spectators"
                          subtitle={
                            data?.designatedSpectators?.length <= 0
                              ? "No Spectators"
                              : `${data?.designatedSpectators?.length} Spectators`
                          }
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentInfoModal;
