import React, { MouseEvent, ReactNode } from "react";
import styles from "../styles/components/button.module.scss";

interface ButtonDcProps {
  text: string | undefined;
  action: () => void;
  disabled?: boolean;
  type?: "facebook" | "primary" | "error" | "cancel" | "background";
  icon?: ReactNode;
  style?: React.CSSProperties;
  short?: boolean;
  className?: string;
  textClassName?: string;
  varient?: "outlined"| 'contained';
}

const ButtonDc: React.FC<ButtonDcProps> = ({
  text,
  action,
  disabled = false,
  type,
  icon,
  style,
  short,
  className,
  textClassName,
  varient,
}) => {
  const btnStyle =
    type === "facebook"
      ? `${styles.facebook} ${styles.buttonContainer}`
      : type === "primary"
      ? `${styles.primary} ${styles.buttonContainer}`
      : type === "error"
      ? `${styles.error} ${styles.buttonContainer}`
      : type === "cancel"
      ? `${styles.cancel} ${styles.buttonContainer}`
      : type === "background"
      ? `${styles.background} ${styles.buttonContainer}`
      : `${style} ${styles.buttonContainer}`;

  const containerStyle = disabled ? `${styles.disabled}` : "";
  const shortStyle = short ? `${styles.short}` : "";
  const varientStyle = varient === 'outlined' ? `${styles.varient} border-2 border-primary rounded-lg` : ``;

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    action();
  };

  return (
    <div className={`${btnStyle} ${containerStyle} ${shortStyle} ${varientStyle}`}>
      <button onClick={handleClick} disabled={disabled} className={className} style={style}>
        {icon && <span className={styles.iconStyle}>{icon}</span>}
        <span className={textClassName}>{text}</span>
      </button>
    </div>
  );
};

export default ButtonDc;
