import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { AppDispatch } from "store/index";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";
import MatchVarientModal from "./components/matchVarientModal.component";
import { setGameFormats, setGameVarient } from "store/physicalGames/physicalGamesSlice";

export enum gameFormats {
    SINGLE_ELIMINATION = "Single Elimination",
    DOUBLE_ELIMINATION = "Double Elimination",
    ROUND_ROBIN = "Round Robin",
};


export default function SetupPhysicalGameTypeScreen({ }) {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 768;
    const [disable, setDisable] = useState(true);
    const [visibleMatchVarient, setVisibleMatchVarient] = useState(false);
    const [selected, setSelected] = useState<gameFormats>(gameFormats.SINGLE_ELIMINATION);

    const onCancel = () => {
        navigate("/wagering");
    };

    const onBack = () => {
        navigate("/physical-games/setup");
    };

    useEffect(() => {
        if (selected) return setDisable(false);
        setDisable(true)
    }, [selected])

    const handleNext = () => {
        dispatch(setGameFormats(selected))
        setVisibleMatchVarient(true);
    }

    const handleOnCloseMatchVarientModal = () => {
        setVisibleMatchVarient(false);
    }

    const handleOnPressMatchVarient = (varient: any) => {
        dispatch(setGameVarient(varient.name))
        setVisibleMatchVarient(false);
        navigate("/physical-games/wager");
    }

    return (
        <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center  h-screen ">
            <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-between">
                <div
                    className="backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
                >
                    <div className="w-full h-[calc(80vh-160px)] flex flex-col items-center overflow-y-auto">
                        <div className="w-full rounded-t-[34px] flex items-center justify-between">
                            <div className="flex flex-row items-center gap-5">
                                <button className="flex rotate-180 w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onBack}>
                                    <img src={BackIcon} alt="Close" />
                                </button>
                                <span className="text-3xl text-white items-center font-normal max-md:text-[16px]">
                                    Set Up Your Match Varient
                                </span>
                            </div>
                            <button className=" w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onCancel}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        <div className="flex flex-col items-center gap-[1em] h-full justify-center max-md:gap-4 max-md:mt-10 max-md:w-full">
                            <div className="flex flex-col items-center justify-center gap-[3em] my-8 max-md:flex-col ">
                                <button
                                    className={`bg-borderColor p-[1.5rem] w-[20em] max-md:w-[15em] h-[8vh] max-md:h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center ${selected === 'Single Elimination' ? " border-2 border-[#FFD482]" : ""}`}
                                    onClick={() => setSelected(gameFormats.SINGLE_ELIMINATION)}
                                >
                                    <span className="text-white text-2xl max-md:text-xl">{gameFormats.SINGLE_ELIMINATION}</span>
                                </button>
                                <button
                                    disabled
                                    className={`bg-borderColor p-[1.5rem] w-[20em] max-md:w-[15em] h-[8vh] max-md:h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center ${selected === 'Double Elimination' ? " border-2 border-[#FFD482]" : ""}`}
                                    onClick={() => setSelected(gameFormats.DOUBLE_ELIMINATION)}
                                >
                                    <span className="text-white text-2xl max-md:text-xl">{gameFormats.DOUBLE_ELIMINATION}</span>
                                </button>
                                <button
                                    disabled
                                    className={`bg-borderColor p-[1.5rem] w-[20em] max-md:w-[15em] h-[8vh] max-md:h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center ${selected === 'Round Robin' ? " border-2 border-[#FFD482]" : ""}`}
                                    onClick={() => setSelected(gameFormats.ROUND_ROBIN)}
                                >
                                    <span className="text-white text-2xl max-md:text-xl">{gameFormats.ROUND_ROBIN}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ButtonDc
                        short={isMobile ? true : false}
                        type="primary"
                        className="max-md:w-4/5 "
                        textClassName="max-md:text-sm"
                        text={'Next'}
                        action={handleNext}
                        disabled={disable}
                    />
                </div>
            </div>
            <MatchVarientModal visible={visibleMatchVarient} onClose={handleOnCloseMatchVarientModal} onPress={handleOnPressMatchVarient} />
        </div>
    );
}
