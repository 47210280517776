import {
  endMatchApi,
  getLobbyApi,
  setReadyForMatchApi,
  startMatchApi,
} from "api/lobby.api";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface GetCurrentMatchPayload {
  matchId: any;
  callback?: () => void;
}

interface WithCallback {
  matchId: any;
  callback?: (data?: any) => void;
}

export const getCurrentMatch = createAsyncThunk<any, GetCurrentMatchPayload>(
  "duels/getCurrentMatch",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getLobbyApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      toast.error("Match Cancelled.");
      window.location.href = "/";
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getCurrentMatches = createAsyncThunk<any, WithCallback>(
  "duels/getCurrentMatches",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getLobbyApi(matchId);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error("Match Cancelled.");
      window.location.href = "/";
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const setReadyForMatch = createAsyncThunk<any, WithCallback>(
  "duels/setReadyForMatch",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await setReadyForMatchApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const startMatch = createAsyncThunk<any, WithCallback>(
  "duels/startMatch",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await startMatchApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      toast.error(error?.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const endMatch = createAsyncThunk<any, WithCallback>(
  "duels/endMatch",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await endMatchApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const cancelMatch = createAsyncThunk<any, WithCallback>(
  "duels/cancelMatch",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await endMatchApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      toast.error(error?.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCurrentMatchWithCallback = createAsyncThunk<any, WithCallback>(
  "duels/getCurrentMatchWithCallback",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getLobbyApi(matchId);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
