import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as BillIcon } from "assets/bill.svg";
import { ReactComponent as PayPalIcon } from "assets/paypal_icon.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  capturePayment,
  checkIfBalanceIsEnough,
  getBalance,
} from "store/wallet/action";
import { toast } from "react-toastify";
import { updateBalance } from "store/wallet/walletSlice";
import { RootState } from "store/index";
import { digitalPayment } from "store/payments/action";
import { setStake, stakeSpecificChallenge, stakeSpecificTournament } from "store/stake/action";
import CloseIcon from "assets/close-button.svg";
import LoadingAnim from "./Loader";
import { stakeMethods } from "screens/Staking/stakingAmount.screen";
import { resetStakingSlice } from "store/stake/stakeSlice";

const PaymentMethod = ({
  amount,
  payId,
  staking,
  onSuccess,
  back,
  onClose,
  from,
  donation,
}: {
  amount: number;
  payId?: string;
  staking?: boolean;
  donation?: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
  back?: boolean;
  from?: string;
}) => {
  const walletBalance = useAppSelector((state) => state.wallet)?.balance;
  const { loader } = useAppSelector((state) => state.wallet);
  const { matchId, stakingMethod, team, player } = useAppSelector((state) => state.stake);
  const dispatch = useAppDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;

  const handleCapturePaymentSuccess = () => {
    dispatch(resetStakingSlice());
    onSuccess?.();
  };

  const handleRedirectUrl = () => {
    switch (from) {
      case "DUEL_WON":
        return `duels/won/${payId}`;
      case "DUEL_LOST":
        return `duels/lost/${payId}`;
      case "LIVE":
        return `stream/preview-live-stream/${payId}`;
      case "WALLET":
        return `wallet/main/${payId}`;
      case "GAME_HUB":
        return `gaming/game-results/${payId}`;
      case "PHYSICAL_GAMES":
        return `physical-games/results/${payId}`;
      default:
        return "stakes/stake-amount";
    }
  };

  const onPaypalPaymentStaking = (result: any) => {
    switch (stakingMethod) {
      case stakeMethods.DUELS:
        dispatch(
          setStake({
            payload: { matchId: matchId, amount: amount, team: team },
            callback: () => window.location.href = result.href,
          })
        );
        break;
      case stakeMethods.TOURNAMENTS:
        dispatch(stakeSpecificTournament({
          payload: { tournamentId: matchId, amount, team },
          callback: () => window.location.href = result.href,
        }))
        break;
      case stakeMethods.CHALLENGES:
        dispatch(stakeSpecificChallenge({
          payload: { challengeId: matchId, amount, stakedPlayer: player },
          callback: () => window.location.href = result.href,
        }))
        break;
      default:
        window.location.href = result.href
        break;


    }
  }

  const onPressPaypal = () => {
    dispatch(
      digitalPayment({
        payload: {
          value: amount,
          payId: payId ? payId : currentMatch.matchId,
          redirectUrl: `${process.env.REACT_APP_DIGITAL_PAYMENT_URL
            }${handleRedirectUrl()}`,
        },
        callback: (result: any) => { onPaypalPaymentStaking(result) },
      })
    );
  };

  const onPayPaypal = (token: any) => {
    dispatch(
      capturePayment({
        payload: {
          orderId: token,
          paymentId: token,
        },
        callback: handleCapturePaymentSuccess,
      })
    );
  };

  useEffect(() => {
    const token = window.location?.search
      ?.split("&token=")?.[1]
      ?.split("&")?.[0];
    if (!token) return;
    console.log("CAPTURE PAYMENT");
    onPayPaypal(token);
  }, []);

  const captureStakePayment = () => {
    switch (from) {
      case "LIVE":
        dispatch(
          capturePayment({
            payload: {
              paymentId: payId,
              walletPayment: amount.toFixed(2),
            },
            callback: handleCapturePaymentSuccess,
            failed: captureStakePayment,
          })
        );
        break;
      case "WALLET":
        dispatch(
          capturePayment({
            payload: {
              paymentId: payId,
              walletPayment: amount.toFixed(2),
            },
            callback: handleCapturePaymentSuccess,
            failed: captureStakePayment,
          })
        );
        break;
      case "PHYSICAL_GAMES":
        break;
      default:
        dispatch(
          capturePayment({
            payload: {
              orderId: false,
              paymentId: payId,
            },
            callback: handleCapturePaymentSuccess,
            failed: captureStakePayment,
          })
        );
        break;
    }

  };


  const handleEnoughBalance = (result: any) => {
    const { isEnoughBalance } = result;
    if (!isEnoughBalance) {
      return toast.error(
        "Insufficient Balance. Please add funds to your wallet to proceed or select another payment method"
      );
    }

    if (!payId) return toast.error("PayId is required");

    dispatch(updateBalance(walletBalance - amount));

    switch (stakingMethod) {
      case stakeMethods.DUELS:
        dispatch(
          setStake({
            payload: { matchId: matchId, amount: amount, team: team },
            callback: captureStakePayment,
          })
        );
        break;
      case stakeMethods.TOURNAMENTS:
        dispatch(stakeSpecificTournament({
          payload: { tournamentId: matchId, amount, team },
          callback: captureStakePayment,
        }))
        break;
      case stakeMethods.CHALLENGES:
        dispatch(stakeSpecificChallenge({
          payload: { challengeId: matchId, amount, stakedPlayer: player },
          callback: captureStakePayment,
        }))
        break;
      default:
        captureStakePayment()
        break;
    }
  };

  const handleDCBalancePress = () => {
    setIsButtonDisabled(true);
    dispatch(
      checkIfBalanceIsEnough({
        payload: { payValue: amount },
        callback: handleEnoughBalance,
      })
    );
  };

  useEffect(() => {
    dispatch(getBalance());
  }, []);

  return (
    <div className="fixed w-full h-full backdrop-blur-sm flex flex-col justify-center items-center z-10">
      <div className="rounded-[18px] bg-borderColor min-h-[70%] min-w-[500px] p-[36px] flex flex-col items-center justify-between">
        {back ? (
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Select Payment Method
            </span>
            <button className=" w-[25px] h-[25px]" onClick={onClose}>
              <img src={CloseIcon} alt="Close" width={20} />
            </button>
          </div>
        ) : (
          <span className="text-white text-2xl">Select Payment Method</span>
        )}

        <BillIcon />
        <div className="flex flex-col w-full items-center gap-y-[20px]">
          <button
            className="bg-white flex justify-center p-[10px] w-[80%] text-black rounded-full font-bold gap-x-[8px] disabled:bg-disabled disabled:cursor-not-allowed"
            onClick={onPressPaypal}
          >
            <PayPalIcon />
            PayPal
          </button>
          <span className="text-placeholder">or</span>
          <div className="flex flex-col items-center border border-primaryActive rounded-[14px] w-[80%] overflow-hidden gap-y-[10px] pt-[10px]">
            <div className="flex flex-col">
              <span className="text-primary font-bold text-[24px] w-full text-center">
                ${amount}
              </span>
              <span className="text-placeholder text-[12px]">
                Balance: ${walletBalance.toFixed(2)}
              </span>
            </div>
            <button
              className="bg-primary flex justify-center p-[10px] w-full text-black  font-bold gap-x-[8px]"
              onClick={handleDCBalancePress}
              disabled={isButtonDisabled}
            >
              Pay with Wallet Balance
            </button>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
};

export default PaymentMethod;
