import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import eventSlice from "./events/eventSlice";
import matchMakingSlice from "./matchMaking/matchMakingSlice";
import currentMatchSlice from "./currentMatch/currentMatchSlice";
import winnersSlice from "./winners/winnersSlice";
import presetSlice from "./preset/presetSlice";
import userSlice from "./user/userSlice";
import notificationSlice from "./notifications/notificationSlice";
import availableToPlaySlice from "./availableToPlay/availableToPlaySlice";
import linkedAccountsSlice from "./linkedAccounts/linkedAccountsSlice";
import fcmSlice from "./fcm/fcmSlice";
import walletSlice from "./wallet/walletSlice";
import stakeSlice from "./stake/stakeSlice";
import communitySlice from "./community/communitySlice";
import messagingSlice from "./messaging/messagingSlice";
import tournamentSlice from "./tournament/tournamentSlice";
import liveBracketSlice from "./liveBrackets/liveBracketSlice";
import streamingSlice from "./streaming/streamingSlice";
import gameHubSlice from "./gameHub/gameHubSlice";
import  physicalGameSlice  from "./physicalGames/physicalGamesSlice";

const persistAuthConfig = {
  key: "auth",
  storage,
};

const persistEventConfig = {
  key: "event",
  storage,
};

const persistMatchMakingConfig = {
  key: "matchMaking",
  storage,
};

const persistCurrentMatchConfig = {
  key: "currentMatch",
  storage,
};

const persistWinnersrConfig = {
  key: "winners",
  storage,
};

const persistPresetConfig = {
  key: "presets",
  storage,
};

const persistUserConfig = {
  key: "user",
  storage,
};

const persistNotificationConfig = {
  key: "notification",
  storage,
};

const availableToPlayConfig = {
  key: "availableToPlay",
  storage,
};

const persistLinkedAccountsConfig = {
  key: "linkedAccounts",
  storage,
};

const persistFCMConfig = {
  key: "fcm",
  storage,
};

const persistWalletConfig = {
  key: "wallet",
  storage,
};

const stakeConfig = {
  key: "stake",
  storage,
};

const communityConfig = {
  key: "community",
  storage,
};
const messagingConfig = {
  key: "messaging",
  storage,
};
const streamingConfig = {
  key: "streaming",
  storage,
};
const gameHubConfig = {
  key: "gameHub",
  storage,
};
const physicalGamesConfig = {
  key: "physicalGames",
  storage,
};

const persistedAuthReducer = persistReducer(persistAuthConfig, authSlice);
const persistedEventReducer = persistReducer(persistEventConfig, eventSlice);
const persistedMatchMakingReducer = persistReducer(
  persistMatchMakingConfig,
  matchMakingSlice
);
const persistCurrentMatchReducer = persistReducer(
  persistCurrentMatchConfig,
  currentMatchSlice
);
const persistedUserReducer = persistReducer(persistUserConfig, userSlice);
const persistedPresetReducer = persistReducer(persistPresetConfig, presetSlice);
const persistedWinnersReducer = persistReducer(
  persistWinnersrConfig,
  winnersSlice
);
const persistedNotificationReducer = persistReducer(
  persistNotificationConfig,
  notificationSlice
);
const availableToPlayReducer = persistReducer(
  availableToPlayConfig,
  availableToPlaySlice
);
const persistedLinkedAccountReducer = persistReducer(
  persistLinkedAccountsConfig,
  linkedAccountsSlice
);
const persistFCMReducer = persistReducer(persistFCMConfig, fcmSlice);
const persistWalletReducer = persistReducer(persistWalletConfig, walletSlice);
const stakeReducer = persistReducer(stakeConfig, stakeSlice);
const communityReducer = persistReducer(communityConfig, communitySlice);
const messagingReducer = persistReducer(messagingConfig, messagingSlice);
const tournamentReducer = persistReducer(messagingConfig, tournamentSlice);
const liveBracketReducer = persistReducer(messagingConfig, liveBracketSlice);
const streamingReducer = persistReducer(streamingConfig, streamingSlice);
const gameHubReducer = persistReducer(gameHubConfig, gameHubSlice);
const physicalGamesReducer = persistReducer(physicalGamesConfig, physicalGameSlice);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    event: persistedEventReducer,
    matchMaking: persistedMatchMakingReducer,
    currentMatch: persistCurrentMatchReducer,
    winners: persistedWinnersReducer,
    presets: persistedPresetReducer,
    user: persistedUserReducer,
    notification: persistedNotificationReducer,
    availableToPlay: availableToPlayReducer,
    linkedAccounts: persistedLinkedAccountReducer,
    fcm: persistFCMReducer,
    wallet: persistWalletReducer,
    stake: stakeReducer,
    community: communityReducer,
    messaging: messagingReducer,
    tournament: tournamentReducer,
    liveBrackets: liveBracketReducer,
    streaming: streamingReducer,
    gameHub: gameHubReducer,
    physicalGames: physicalGamesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persister = persistStore(store);
export default store;
