import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getGames } from "store/matchMaking/actions";
import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import {
  setConsole,
  setMatchFromHome,
  setSelectedGame,
} from "store/matchMaking/matchMakingSlice";
import {
  setSelectedTournamentGame,
  setTournamentConsole,
} from "store/tournament/tournamentSlice";
import ConsolModal from "components/Consoles";
import LoadingAnim from "components/Loader";
import SearchBar from "components/Search";
import CloseIcon from "assets/close-button.svg";
import MatchCard from "components/MatchCard";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";
import { games } from "types/games";
import miscsStyles from "styles/miscs/miscs.module.scss";
import { RootState } from "store/index";
import { setLoginModalVisible } from "store/auth/authSlice";
import { toast } from "react-toastify";


enum tabs {
  GAMES = 'GAMES',
  MINI_GAMES = 'MINI_GAMES',
}

interface GameTileProps {
  title: string;
  image: string;
  onPress: () => void;
}

const GameTile: React.FC<GameTileProps> = ({ title, image, onPress }) => {
  return (
    <div
      className="flex flex-col items-center rounded-lg overflow-hidden p-2"
    >
      <div className="w-[100px] h-[100px] flex justify-center items-center rounded-lg cursor-pointer "
        onClick={onPress}>
        <img src={image} alt={title} className="w-[100px] h-[100px] shadow-xl rounded-md transform transition-transform hover:scale-105 hover:shadow-lg" />
      </div>

      <div className="p-4 text-center">
        <h3 className="text-white text-lg font-semibold">{title}</h3>
      </div>
    </div>
  );
};

const CardModal = () => {
  const [selected, setSelected] = useState<any | null>(null);
  const [visible, setVisible] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [tab, setTab] = useState(tabs.GAMES);
  const [tabData, setTabData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matchMaking = useAppSelector((state) => state.matchMaking);
  const duelpath = useAppSelector(
    (state) => state.matchMaking
  ).selectedDuelPath;
  const gameList = matchMaking.gamesList;
  const isNextDisabled = !selected;
  const page = matchMaking.page;
  const user = useAppSelector((state: RootState) => state.auth);
  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;


  const handleClose = () => {
    navigate("/wagering");
  };
  const onBack = () => {
    if (duelpath === 'DUEL') return navigate("/duels/schedule-match");
    if (duelpath === 'CREATE_MATCHMAKING') return navigate('/duels');
    if (duelpath === 'CREATE_TOURNAMENT') return navigate("/tournament/type");

  };
  const handleClick = (matchItem: any) => {
    setSelected(matchItem);
  };

  const handleNext = () => {
    setVisible(true);
  };
  const handleSearch = (matchItem: any) => {
    handleClick(matchItem);
    setVisible(true);
  };

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    await dispatch(getGames(value));

  };

  useEffect(() => {
    setTabData(gameList);
  }, [gameList])


  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  useEffect(() => {
    dispatch(getGames(1));
  }, [dispatch]);

  useEffect(() => {
    if (duelpath === "CREATE_TOURNAMENT") {
      dispatch(setSelectedTournamentGame(selected));
    } else {
      dispatch(setSelectedGame(selected));
    }
  }, [dispatch, selected]);

  const getButtonTextStyle = (tabName: string) => {
    return tab === tabName ? 'text-primary' : 'text-white';
  };

  useEffect(() => {
    if (tab === tabs.GAMES) {
      setTabData(gameList);
    } else {
      setTabData(games);
    }
  }, [tab])

  const handleGameSelect = (gameData: any) => {
    if (!user.userToken) return dispatch(setLoginModalVisible(true));
    if (isSuspended) {
      toast.warning('Account Suspended');
    } else {
      navigate("/gaming", { state: { gameData, from: 'DUEL' } });
    }
  };

  useEffect(() => {
    if (tab === tabs.GAMES) {
      setTabData(results);
    }
  }, [results])

  useEffect(() => {
    if (searchQuery) {
      const filteredGames = games.filter((game) =>
        game.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setTabData(filteredGames);
    } else {
      setTabData(games);
    }
  }, [searchQuery]);

  const setSearchResults = (data: any) => {
    if (tab === tabs.GAMES && searchQuery.length > 0) {
      setResults(data);
    }
  }

  const onPressSearch = (data: any) => {
    if (tab === tabs.MINI_GAMES) {
      setSearchQuery(data);
    }
  }

  return (
    <>
      {duelpath !== 'DUEL' ? (<div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  p-2">
        <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
          <div
            className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] justify-between flex flex-col  max-md:h-[95vh]"
          >
            <div className="w-full mb-5 flex justify-between">
              {duelpath !== "DUEL" ? <span className="text-lg text-subText items-center font-normal uppercase">
                {duelpath}
              </span>
                :
                <span className="text-lg text-subText items-center font-normal uppercase">
                  {duelpath} {'>'} {matchMaking?.matchSchedule}
                </span>
              }

            </div>
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row items-center gap-5">
                <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                  <img src={BackIcon} alt="Close" />
                </button>
                <span className="text-3xl text-white items-center font-normal">
                  Select a game
                </span>
              </div>
              <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="text-white mt-4">
              <SearchBar
                searchIcon
                placeholder="Search games"
                type="games"
                setResults={(v) => setResults(v)}
                onClick={handleSearch}
              />
            </div>
            <div className="w-full md:h-[calc(90vh-430px)] mt-5 flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-[50vh]">
              {gameList.map((item, index) => (
                <MatchCard
                  key={index}
                  id={`${index}`}
                  thumbnail={item.image}
                  item={item}
                  name={item?.name}
                  selected={selected?._id === item?._id}
                  onClick={handleClick}
                />
              ))}
            </div>
            <div className="flex absolute justify-center bottom-28 left-0 right-0 md:bottom-20 max-md:bottom-20">
              <ThemeProvider theme={darkTheme}>
                <Pagination
                  count={page}
                  variant="outlined"
                  shape="rounded"
                  color="standard"
                  onChange={handlePageChange}
                />
              </ThemeProvider>
            </div>
            <ButtonDc
              type="primary"
              className="absolute bottom-10 max-md:w-auto md:bottom-4  max-md:bottom-4"
              textClassName="max-md:text-sm"
              text={'Next'}
              action={handleNext}
              disabled={isNextDisabled}
            />
          </div>
        </div>
        <ConsolModal
          visible={visible}
          onPress={(item: any) => {
            if (duelpath !== "DUEL") {
              dispatch(setTournamentConsole(item));
            } else {
              dispatch(setConsole(item));
            }
            dispatch(setMatchFromHome(false));
            setVisible(false);
            navigate("/duels/select-platform");
          }}
          data={selected?.consoles}
          onClose={() => setVisible(false)}
        />
        <LoadingAnim loading={matchMaking.loader} />
      </div>)
        :
        (<div className="mx-auto w-screen  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  md:p-2">
          <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
            <div
              className="  backdrop-blur-sm con rounded-[34px] p-10
            overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] justify-between items-center flex flex-col  max-md:h-[95vh]"
            >
              {/* Header */}
              <div className="w-full max-md:w-[80vw] rounded-t-[34px] flex items-center justify-between max-md:mt-4">
                <div className="flex flex-row items-center gap-5">
                  <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                    <img src={BackIcon} alt="Close" />
                  </button>
                  <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                    Select a game
                  </span>
                </div>
                <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                  <img src={CloseIcon} alt="Close" />
                </button>
              </div>

              {/* Tab-Buttons */}
              <div className="flex flex-row items-center  max-md:mx-5 justify-evenly max-md:justify-evenly md:gap-16 max-md:mt-4 border-2 border-darkenGray max-md:w-[80vw] mt-8 rounded-lg w-2/3">
                <button
                  className="md:p-4 rounded-xl border-0  flex flex-row gap-4 items-center justify-center md:w-[250px]"
                  onClick={() => setTab(tabs.GAMES)}
                >
                  <span className={`text-xl max-md:text-sm ${getButtonTextStyle(tabs.GAMES)}`}>
                    {tabs.GAMES}
                  </span>
                </button>
                <div className='h-10 w-0.5 bg-darkenGray' />
                <button
                  className="md:p-4 rounded-xl border-0  flex flex-row gap-4 items-center justify-center md:w-[250px]"
                  onClick={() => setTab(tabs.MINI_GAMES)}
                >
                  <span className={`text-xl max-md:text-sm ${getButtonTextStyle(tabs.MINI_GAMES)}`}>
                    {tabs.MINI_GAMES}
                  </span>
                </button>
              </div>
              <div className="text-white mt-4 w-full desktop:w-2/3">
                <SearchBar
                  searchIcon
                  placeholder="Search games"
                  type={tab === tabs.GAMES ? "gamesAll" : "gameHub"}
                  setResults={(v) => setSearchResults(v)}
                  onPress={(v) => onPressSearch(v)}
                />
              </div>
              {tab === tabs.GAMES ?
                <div className="w-full md:h-[calc(85vh-430px)] mt-5 flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-[50vh]">
                  {tabData?.length > 0 && tabData?.map((item, index) => (
                    <MatchCard
                      key={index}
                      id={`${index}`}
                      thumbnail={item.image}
                      item={item}
                      name={item.name}
                      selected={selected?._id === item._id}
                      onClick={handleClick}
                    />))}
                </div> :
                <div
                  className={`flex flex-col md:p-4 gap-[2em] max-md:gap-8 md:gap-[5em] h-[calc(80vh-200px)] max-md:h-[calc(90vh-200px)] ${miscsStyles.beautifulScrollbarHidden}`}
                >
                  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 desktop:grid-cols-5 ">
                    {tabData?.length > 0 ? (
                      tabData?.map((game: any) => (
                        <GameTile
                          key={game.id}
                          title={game.title}
                          image={game.image}
                          onPress={() => handleGameSelect(game)}
                        />
                      ))
                    ) : (
                      <p className="text-white text-center col-span-full">
                        No games found.
                      </p>
                    )}
                  </div>
                </div>
              }
              <div className='flex flex-row items-start'>
                {tabData?.length === 0 && <span className="text-lg text-center text-darkGray">- No data -</span>}
              </div>
              {tab === tabs.GAMES && <ButtonDc
                type="primary"
                className="absolute bottom-10 max-md:w-auto md:bottom-4  max-md:bottom-4"
                textClassName="max-md:text-sm"
                text={'Next'}
                action={handleNext}
                disabled={isNextDisabled}
              />}
              {tab === tabs.GAMES && <div className="flex absolute bottom-20 justify-center right-8 left-0 w-full">
                <ThemeProvider theme={darkTheme}>
                  <Pagination
                    count={page}
                    variant="outlined"
                    shape="rounded"
                    color="standard"
                    onChange={handlePageChange}
                  />
                </ThemeProvider>
              </div>}
            </div>
          </div>
          <ConsolModal
            visible={visible}
            onPress={(item: any) => {
              if (duelpath !== "DUEL") {
                dispatch(setTournamentConsole(item));
              } else {
                dispatch(setConsole(item));
              }
              dispatch(setMatchFromHome(false));
              setVisible(false);
              navigate("/duels/select-platform");
            }}
            data={selected?.consoles}
            onClose={() => setVisible(false)}
          />
          <LoadingAnim loading={matchMaking.loader} />
        </div>)}
    </>
  );
};

export default CardModal;
