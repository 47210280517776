import { useNavigate } from "react-router-dom";
import { RootState } from "store/index";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactComponent as SteamingIcon } from "assets/sideBar/streaming.svg";
import { getLiveStreams } from "store/streaming/actions";
import { streamModes } from "types/constants";
import { toast } from "react-toastify";
import {
  resetStream,
  setDefaultStream,
  setStreamDescription,
  setStreamTags,
} from "store/streaming/streamingSlice";

import miscsStyles from "styles/miscs/miscs.module.scss";
import LoadingAnim from "components/Loader";
import Sidebar from "components/SideBar";
import SearchBar from "components/Search";
import StreamingLiveViewer from "components/StreamingLiveViewer";
import Menu from "components/Menu";
import NotificationModal from "components/Notifications";
import ImageHolder from "components/ImagePlaceholder";
import { streamingCategories } from "constant/streamFilters";

export default function StreamHubScreen({ }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { liveStreams, loader } = useAppSelector(
    (state: RootState) => state.streaming
  );
  const user = useAppSelector((state: RootState) => state.user?.user);
  const userAuth = useAppSelector((state: RootState) => state.auth);
  const isSuspended = user?.isSuspended;
  const isMobile = window.innerWidth <= 768;
  const [results, setResults] = useState<any[]>([]);
  const [visibleOptionsMenu, setVisibleOptionsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(streamingCategories[0]);

  const userStream = liveStreams.length > 0 && liveStreams.find(
    (liveStreams: any) => liveStreams.userId._id === user?._id
  );

  useEffect(() => {
    dispatch(getLiveStreams({ query: streamModes.HUB, category: selectedCategory.label }));
  }, [dispatch, selectedCategory]);

  const handleGoLive = () => {
    if (isSuspended) return toast.warning('Account Suspended');
    if (userStream) return toast.warning('You are currently streaming. Please end your current stream to continue!');
    dispatch(resetStream());
    navigate(`/stream/live-setup`);
  };

  const handleOnPressStream = (item: any) => {
    if (isSuspended) return toast.warning('Account Suspended');
    const parseTags = (tags: string[]): string[] => {
      if (tags && tags.length > 0) {
        try {
          const parsedTags: string[] = JSON.parse(tags[0]);

          const uniqueTags = parsedTags.filter(
            (tag, index, self) => self.indexOf(tag) === index
          );
          return uniqueTags;
        } catch (error) {
          console.error("Error parsing tags:", error);
          return [];
        }
      }
      return [];
    };

    dispatch(setStreamTags(parseTags(item?.tags)));
    dispatch(setStreamDescription(item?.description));
    dispatch(
      setDefaultStream({
        streamRoomId: item.inApp?.streamRoomId,
        streamToken: item.inApp?.streamToken,
        streamTitle: item?.streamTitle,
        streamCategory: item?.category,
        streamerId: "",
        thumbnail: "",
        upstreamKey: "",
        upstreamUrl: "",
        isStreaming: true,
        streamType: "IN_APP",
        _id: item.userId._id,
        username: item?.userId?.username,
        profilePicture: item.userId?.profilePicture,
        tags: item?.tags,
        isHost: item.userId?._id === user?._id,
        createdAt: item.createdAt,
      })
    );
    navigate(`/stream/preview-live-stream/${"hub"}/${item._id}`);
  };

  const renderCategories = (item: any, index: number) => {
    const isSelected = selectedCategory.key === item.key;

    const selectedContainerStyles = isSelected ? 'bg-white' : 'bg-inputGray'
    const selectedTextStyles = isSelected ? 'text-black' : 'text-white'

    const onSelectCategory = () => {
      setSelectedCategory(item)
    }

    return (
      <button className={`${selectedContainerStyles} flex items-center p-1 px-4 rounded-md`} key={index} onClick={onSelectCategory}>
        <span className={`${selectedTextStyles} text-sm`}>{item.label}</span>
      </button>
    )
  }

  return (
    <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar
        onOptions={() => setVisibleOptionsMenu(true)}
        onNotifications={() => setVisibleNotifications(true)}
      />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto ">
        <div className="md:mt-[20px] flex flex-row max-md:flex-col justify-between items-center w-[80vw]">
          <span className="text-white text-3xl text-center  max-md:text-2xl">
            Stream Hub
          </span>
          <div className="flex flex-row max-md:flex-col gap-4 text-white z-10">
            <SearchBar
              searchIcon
              placeholder="Search lives"
              type="lives"
              setResults={(v) => setResults(v)}
            />
            {userAuth?.userToken && <div className="justify-center items-center flex animate-pulse">
              <button className="bg-primaryYellow p-2 h-[64px] md:w-56 w-full rounded-xl flex  justify-center items-center"
                onClick={handleGoLive}>
                <SteamingIcon className="mr-2 text-black" />
                <span className="text-black font-semibold md:text-xl">Go Live</span>
              </button>
            </div>}
            {!isMobile && <div className="flex flex-row gap-10 items-center right-5">
              {!userAuth?.userToken ? (
                <button
                  className="p-2 rounded-lg bg-primaryActive w-[145px] h-[48px] flex items-center justify-center"
                  onClick={() => navigate("/login")}
                >
                  <span className="text-sm text-black ">Sign in</span>
                </button>
              ) : (
                <button
                  onClick={() => {
                    navigate("/profile");
                  }}
                  disabled={!userAuth?.userToken}>
                  <ImageHolder
                    uri={user?.profilePicture}
                    className="flex w-[50px] h-[50px] rounded-full "
                  />
                </button>)}
            </div>}
          </div>
        </div>
        <div
          className={`flex flex-col md:p-4 gap-[2em] max-md:gap-8  md:backdrop-blur-sm con rounded-2xl  w-[80vw] h-[80vh] max-md:h-[58vh]  ${miscsStyles.beautifulScrollbarHidden}`}
        >
          <div className="flex flex-row items-center gap-2">
            {streamingCategories.map(renderCategories)}
          </div>
          {liveStreams.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {liveStreams?.map((stream: any, index: number) => (
                <StreamingLiveViewer
                  key={index}
                  paused
                  controls
                  userName={stream?.userId?.username}
                  profilePicture={stream?.userId?.profilePicture}
                  uri=""
                  watchingCount={0} //TODO
                  streamTitle={stream?.streamTitle}
                  streamRoomId={stream?.inApp?.streamRoomId}
                  streamToken={stream?.inApp?.streamToken}
                  thumbnail={stream?.thumbnail}
                  onPressStream={() => handleOnPressStream(stream)}
                />
              ))}
            </div>
          ) : (
            <div className="h-full w-full flex justify-center items-center">
              <span className="text-lg text-center text">- No Lives -</span>
            </div>
          )}
        </div>
      </div>

      <LoadingAnim loading={loader} />
      <Menu
        visible={visibleOptionsMenu}
        onClose={() => setVisibleOptionsMenu(false)}
      />
      <NotificationModal
        visible={visibleNotifications}
        onClose={() => setVisibleNotifications(false)}
      />
    </div>
  );
}
