import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import { requestPayout } from "store/wallet/action";
import { setAmount } from "store/wallet/walletSlice";
import { WalletStatus } from "types/constants";

import LoadingAnim from "components/Loader";
import ButtonDc from "components/Button";
import WalletIcon from "assets/walletIcon.png";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ConfirmationAlert from "components/ConfiamationAlert";


export default function WithdrawFundsScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { balance, loader } = useAppSelector((state) => state.wallet);
  const [value, setValue] = useState("");
  const [visibleConfirmBox, setVisibleConfirmBox] = useState(false);
  const onValueChange = (e: any) => {
    setValue(e.target.value);
  };

  const onCancel = () => {
    navigate("/");
  };
  const onBack = () => {
    navigate("/wallet/main");
  };
  const onContinue = () => {
    setVisibleConfirmBox(true);
  };

  const handleConfirmWithdraw = () => {
    if (balance < Number(value)) return toast.warning("Insufficient Balance!");
    dispatch(setAmount(value));
    dispatch(
      requestPayout({
        payload: { amount: value },
        callback: () => {
          navigate(`/wallet/transaction-complete/${WalletStatus.WITHDRAW}`);
        },
      })
    );
  }

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <div className="flex flex-row items-center gap-5">
                  <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                    <img src={BackIcon} alt="Close" />
                  </button>
                  <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                    Withdraw Funds
                  </span>
                </div>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center gap-8  max-md:flex-col ">
            <div className="flex flex-col items-center justify-center p-10 my-4 gap-1 border-2 w-full desktop:w-1/3 md:w-1/2 border-primary rounded-2xl">
              <img src={WalletIcon} alt="wallet" />
              <span className="text-white text-[26px]">Your Balance</span>
              <span className="text-white text-[26px]">
                {balance ? `$ ${balance.toFixed(2)}` : `$ 0`}
              </span>
            </div>
            <span className="text-white text-xl max-md:text-lg">
              Enter Amount to Withdraw
            </span>
            <div className="flex flex-col items-center justify-center">
              <input
                className="bg-inputGray rounded-lg h-16 text-center border-inputGray text-[22px] text-white md:w-[20vw]"
                placeholder="$ 00.00"
                value={value}
                onChange={(e) => onValueChange(e)}
              />
              <span className="text-placeholder text-center md:w-[20vw] max-md:text-sm">
                Withdrawl request are processed on business days 7.00 AM - 9.00
                PM
              </span>
            </div>
            <ButtonDc
              text="Withdraw"
              type={"primary"}
              action={() => onContinue()}
            />
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
      <ConfirmationAlert visible={visibleConfirmBox} message={`Are you sure you want to Withdraw $${value} ?`} onCancel={() => setVisibleConfirmBox(false)} onConfirm={handleConfirmWithdraw} confirmMessage="Withdraw" />
    </div>
  );
}
