import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { AppDispatch } from "store/index";
import CloseIcon from "assets/close-button.svg";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";
import TextInput from "components/TextInput";
import { setMatchNameAndLocation } from "store/physicalGames/physicalGamesSlice";

export default function SetupPhysicalGame({ }) {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 768;
    const [gameName, setGameName] = useState('');
    const [location, setLocation] = useState('');
    const [disable, setDisable] = useState(true);

    const onCancel = () => {
        navigate("/wagering");
    };

    const onBack = () => {
        navigate("/gameMode");
    };

    const handleInputGameNameChange = (
        e: {
            target: { value: React.SetStateAction<string> };
        }) => {
        const values = e.target.value;
        setGameName(values);
    };

    const handleInputLocationChange = (
        e: {
            target: { value: React.SetStateAction<string> };
        }) => {
        const values = e.target.value;
        setLocation(values);
    };

    useEffect(() => {
        if (gameName.length > 0 && location.length > 0) {
            setDisable(false)
        } else {
            setDisable(true)
        }
    }, [gameName, location])


    const handleNext = () => {
        dispatch(setMatchNameAndLocation({ name: gameName, location: location }));
        navigate("/physical-games/game-type");
    }

    return (
        <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center  h-screen ">
            <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-between">
                <div
                    className="backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
                >
                    <div className="w-full h-[calc(80vh-160px)] flex flex-col items-center overflow-y-auto">
                        <div className="w-full rounded-t-[34px] flex items-center justify-between">
                            <div className="flex flex-row items-center gap-5">
                                <button className="flex rotate-180 w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onBack}>
                                    <img src={BackIcon} alt="Close" />
                                </button>
                                <span className="text-3xl text-white items-center font-normal max-md:text-[16px]">
                                    Set Up Your Physical Match
                                </span>
                            </div>
                            <button className=" w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onCancel}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        <div className="flex flex-col items-center gap-[1em] h-full justify-center max-md:gap-4 max-md:mt-10 max-md:w-full">
                            <span className="text-white text-bold text-2xl max-md:text-xl text-center">Enter Your Game and Location</span>
                            <span className="text-white  text-lg text-center max-md:text-[12px]">Please type the name of the game you're going to play and where the match will take place.</span>
                            <div className="mt-6 flex flex-col justify-center items-center gap-4">
                                <TextInput
                                    placeholder="Type the name of the game"
                                    value={gameName}
                                    onChange={(event) => handleInputGameNameChange(event)}
                                    label="Game Name"
                                />
                                <TextInput
                                    placeholder="Where is the match?"
                                    value={location}
                                    onChange={(event) => handleInputLocationChange(event)}
                                    label="Location"
                                />
                            </div>
                        </div>
                    </div>
                    <ButtonDc
                        short={isMobile ? true : false}
                        type="primary"
                        className="max-md:w-4/5 "
                        textClassName="max-md:text-sm"
                        text={'Next'}
                        action={handleNext}
                        disabled={disable}
                    />
                </div>
            </div>
        </div>
    );
}
