import React, { useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import Carousel from "nuka-carousel";
import ButtonDc from "components/Button";
import Intro01 from "assets/withdraw/Intro01.png";
import Intro02 from "assets/withdraw/Intro02.png";
import Intro03 from "assets/withdraw/Intro03.png";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { setWithdrawnIntro } from "store/user/userSlice";

interface ItemProps {
  icon: string;
  name: string;
  text: string;
  lastStage: boolean;
}
const itemsData: ItemProps[] = [
  {
    icon: Intro01,
    name: "Prepare Your ID Document",
    text: "Ensure your government-issued ID is ready and legible for upload. Please check that all text on the ID is visible and free from any glare or obstructions.",
    lastStage: false,
  },
  {
    icon: Intro02,
    name: "Prepare to Take Your Selfie",
    text: "Ensure your face is well-lit and fully visible without any headwear or glasses. Position yourself in a brightly lit area with natural light if possible.",
    lastStage: false,
  },
  {
    icon: Intro03,
    name: "Ready to Start the Verification",
    text: "Once you start, you'll have 60 seconds to upload both your ID and selfie. Make sure you're ready to proceed smoothly.",
    lastStage: true,
  },
];

export default function WithdrawingIntroScreen({ }) {
  const navigate = useNavigate();
  const [sliderIndex, setSliderIndex] = useState(0);
  const isMobile = window.innerWidth <= 768;
  const isFrom = useAppSelector((state: RootState) => state.stake).from;
  const dispatch: AppDispatch = useAppDispatch();
  const onCancel = () => {
    navigate("/wallet/main");
  };

  const handleDonePress = () => {
    dispatch(setWithdrawnIntro());
    navigate("/wallet/upload-id");
  };

  const handleNextSlide = () => {
    if (sliderIndex < itemsData.length - 1) {
      setSliderIndex(sliderIndex + 1);
    } else {
      handleDonePress();
    }
  };

  const handlePreviousSlide = () => {
    if (sliderIndex > 0) {
      setSliderIndex(sliderIndex - 1);
    }
  };

  const Slider = () => {
    const info = {
      slidesToShow: 1,
      wrapAround: true,
      autoplay: false,
      autoplayInterval: 3000,
      slideIndex: sliderIndex,
    };

    return (
      <Carousel
        {...info}
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: "#FFD482",
            marginRight: "10px",
            position: "relative",
            bottom: isMobile ? "-15vh" : "-5vh",
          },
        }}
        renderCenterLeftControls={({ previousSlide }) => null}
        renderCenterRightControls={({ nextSlide }) => null}
      >
        {itemsData.map((item, index) => (
          <div key={index} className="flex justify-center">
            <Item {...item} />
          </div>
        ))}
      </Carousel>
    );

    function Item({ icon, name, text, lastStage }: ItemProps) {
      return (
        <div className="md:h-[45vh] flex mt-10 max-md:h-[50vh]">
          <div className="w-[234px] md:w-[509px]  h-[300px]">
            <div className="flex flex-row gap-3 justify-center mt-4 md:h-[20vh] max-md:h-[15vh]">
              <img src={icon} alt="icon" />
            </div>
            <div className="flex flex-col items-center gap-4 mt-4 md:px-16 px-8">
              <span className="flex text-center md:line-clamp-4 max-md:text-[16px] text-primary text-2xl">
                {name}
              </span>
              <span className="flex text-center md:line-clamp-4 max-md:text-[12px] text-subText">
                {text}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-120px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Withdraw
            </span>
            <button className="w-[25px] h-[25px]" onClick={onCancel}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col items-center gap-[5em] justify-center max-md:flex-row">
            <div className="w-[65%] max-md:w-[90%]">
              <Slider />
              <div className="flex flex-row items-center gap-5 absolute bottom-8 md:w-[calc(80%-176px)] max-md:w-[70%] max-md:bottom-5">
                <ButtonDc
                  short
                  text="Previous"
                  action={handlePreviousSlide}
                  type="background"
                  disabled={sliderIndex === 0}
                />
                <ButtonDc
                  short
                  text={sliderIndex === 2 ? "Start Verification" : "Next"}
                  action={handleNextSlide}
                  type="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
