import {
  GetAllEventsApi,
  GetAllLivesApi,
  GetAllStakingEventsApi,
  GetAllTournamentsApi,
  stakeAvailableEventsApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface WithCallback {
  payload?: any;
  callback?: (data?: any) => void;
}

export const getEvents = createAsyncThunk<any, void>(
  "event/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await GetAllEventsApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getLives = createAsyncThunk<any, any>(
  "event/getLives",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await GetAllLivesApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTournaments = createAsyncThunk<any, void>(
  "event/getTournaments",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await GetAllTournamentsApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getStakeAvailableEvents = createAsyncThunk<any, WithCallback>(
  "common/getStakeAvailableEvents",
  async ({payload,callback}, { rejectWithValue }) => {
    try {
      const data: any = await stakeAvailableEventsApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);