import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTournamentById } from "store/liveBrackets/actions";
import { gameFormats } from "screens/PhysicalGames/setupGameType.screen";
import { signOut } from "store/auth/actions";

export enum matchSchedule {
  NOW = "NOW",
  LATER = "LATER",
}

export enum gameModes {
  FREE_PLAY = "FREE_PLAY",
  PAID = "PAID",
}

interface PhysicalGamesSlice {
  scheduleMatch: matchSchedule;
  name: string;
  location: string;
  gameFormat: gameFormats;
  gameVarient: string;
  gameMode: gameModes;
  wager: number;
  matchDate: Date;
  isHost: boolean;
  loading: boolean;
  players: any[];
  paypalConnect?: boolean;
}

const initialState: PhysicalGamesSlice = {
  scheduleMatch: matchSchedule.NOW,
  name: "",
  location: "",
  gameFormat: gameFormats.SINGLE_ELIMINATION,
  gameVarient: "",
  gameMode: gameModes.FREE_PLAY,
  wager: 0,
  matchDate: new Date(),
  isHost: true,
  loading: false,
  players: [],
  paypalConnect: true,
};

export const physicalGameSlice = createSlice({
  name: "physicalGames",
  initialState,
  reducers: {
    resetPhysicalGameSlice: (state) => {
      return initialState;
    },
    setMatchScheduleStatus: (state, action: PayloadAction<matchSchedule>) => {
      state.scheduleMatch = action.payload;
    },
    setMatchNameAndLocation: (
      state,
      action: PayloadAction<{ name: string; location: string }>
    ) => {
      state.name = action.payload.name;
      state.location = action.payload.location;
    },
    setGameFormats: (state, action: PayloadAction<gameFormats>) => {
      state.gameFormat = action.payload;
    },
    setGameVarient: (state, action: PayloadAction<string>) => {
      state.gameVarient = action.payload;
    },
    setGameMode: (state, action: PayloadAction<gameModes>) => {
      state.gameMode = action.payload;
    },
    setGameWager: (state, action) => {
      state.wager = action.payload;
    },
    setGameHost: (state, action) => {
      state.isHost = action.payload;
    },
    setPaypalConnect: (state, action) => {
      state.paypalConnect = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, () => {
      return initialState;
    });
    builder.addCase(getTournamentById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTournamentById.fulfilled, (state, action) => {
      if (action.payload.isPhysicalGame) {
        state.name = action.payload?.physicalGame?.gameName;
        state.location = action.payload?.physicalGame?.location;
        state.wager = action.payload?.cubeWager;
        state.gameFormat = action.payload.tournamentType;
        state.gameVarient = action.payload.gameType;
        state.matchDate = action.payload.tournamentDate;
      }
      state.loading = false;
    });
     builder.addCase(getTournamentById.rejected, (state) => {
       state.loading = false;
     });
  },
});

export default physicalGameSlice.reducer;
export const {
  resetPhysicalGameSlice,
  setMatchScheduleStatus,
  setMatchNameAndLocation,
  setGameFormats,
  setGameVarient,
  setGameMode,
  setGameWager,
  setGameHost,
  setPaypalConnect,
} = physicalGameSlice.actions;
