import { getLobbyApi } from "api/lobby.api";
import {
  getAnnouncementsApi,
  getNotificationssApi,
  handleNotificationApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
}

export const getNotifications = createAsyncThunk<any, any>(
  "match/getNotifications",
  async ({ page }, { rejectWithValue }) => {
    try {
      const data: any = await getNotificationssApi(page);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const handleNotifications = createAsyncThunk<any, WithCallback>(
  "match/handleNotifications",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await handleNotificationApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.data?.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getMatchById = createAsyncThunk<any, any>(
  "duels/getCurrentMatch",
  async ({ matchId, callback, err }, { rejectWithValue }) => {
    try {
      const data: any = await getLobbyApi(matchId);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      err?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getAnnouncements = createAsyncThunk<any, WithCallback>(
  "user/getAnnouncements",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getAnnouncementsApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
