import React from "react";
import { AppDispatch, RootState } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setDuelPath,
  setJoiningMatchId,
  setMatchCubes,
  setMatchFromHome,
  setMatchSchedule,
  setSelectedGame,
  setSelectedGameImage,
  setSelectedGameType,
  setSelectedRound,
} from "store/matchMaking/matchMakingSlice";
import { useNavigate } from "react-router";
import { setScreenStatus } from "store/currentMatch/currentMatchSlice";
import { setStakeFrom } from "store/stake/stakeSlice";
import { setTournamentId } from "store/tournament/tournamentSlice";
import { ReactComponent as Stream } from "assets/stream.svg";
import miscsStyles from "styles/miscs/miscs.module.scss";
import ImageHolder from "./ImagePlaceholder";
import "../styles/components/events.scss";
import { toast } from "react-toastify";
import LoadingAnim from "./Loader";
import { setLoginModalVisible } from "store/auth/authSlice";
import { games } from "types/games";

enum GameTypes {
  ONE_VS_ONE = 'ONE_VS_ONE',
  TWO_VS_TWO = 'TWO_VS_TWO',
  THREE_VS_THREE = 'THREE_VS_THREE',
  FOUR_VS_FOUR = 'FOUR_VS_FOUR',
}

interface EventProps {
  onPress?: (data: any[]) => void;
  onPressInAppStream?: (data: any) => void;
}

const Event: React.FC<EventProps> = ({ onPress, onPressInAppStream }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state: RootState) => state.auth);
  const userData = useAppSelector((state: RootState) => state.user);
  const data = useAppSelector((state: RootState) => state.event);
  const isSuspended = useAppSelector(
    (state: RootState) => state.user
  ).user?.isSuspended;
  const events = data.duels;
  const lives = data.lives;
  const tournaments = data.tournaments;
  const stakingEvents = data.stakingEvents;

  const onPressEvent = (item: any) => {
    if (!user.userToken) return dispatch(setLoginModalVisible(true));
    if (isSuspended) return toast.warning('Account Suspended');
    const isHost = item.host === userData.user?._id;
    const isUserin =
      item.players.filter((player: any) => player === userData.user?._id)
        .length > 1;
    if (isHost) {
      dispatch(setDuelPath("DUEL"));
      dispatch(setScreenStatus("DUEL"));
      navigate(`/duels/lobby/${item._id}`);
      return;
    }
    if (isUserin) {
      dispatch(setDuelPath("JOIN_MATCH"));
      dispatch(setScreenStatus("JOIN_MATCH"));
      navigate(`/duels/lobby/${item._id}`);
      return;
    }
    dispatch(setSelectedGame(item.game));
    dispatch(setSelectedGameImage(item.coverImage));
    dispatch(setJoiningMatchId(item._id));
    dispatch(setDuelPath(item.matchType));
    dispatch(setMatchSchedule(item.matchStatus));
    dispatch(
      setSelectedGameType(
        item.game.gameTypes.find(
          (type: { type: string }) => type.type === item.gameType
        )
      )
    );
    dispatch(
      setSelectedRound(
        item.game.rounds.find(
          (round: { name: string }) => round.name === item.rounds
        )
      )
    );
    dispatch(setMatchCubes(item.cubeWager));
    dispatch(setMatchFromHome(true));
    dispatch(setScreenStatus("JOIN_MATCH"));
    dispatch(setDuelPath("JOIN_MATCH"));
    onPress?.(item.game);
  };

  const onClickTournament = (item: any) => {
    if (!user.userToken) return dispatch(setLoginModalVisible(true));
    if (isSuspended) return toast.warning('Account Suspended');
    const isHost = item?.host === userData.user?._id;
    if (isHost) {
      dispatch(setDuelPath("CREATE_TOURNAMENT"));
      dispatch(setScreenStatus("CREATE_TOURNAMENT"));
    } else {
      dispatch(setDuelPath("OPPONENT_JOIN"));
      dispatch(setScreenStatus("OPPONENT_JOIN"));
    }
    if (item.isUserIn) {
      dispatch(setTournamentId(item?._id));
      navigate(`/tournament/live-bracket/${item?._id}`);
    } else {
      const tournamentId = item._id;
      navigate(`/duels/duel-overview/${tournamentId}`);
    }
  };

  const formatEventTime = (timestamp: string | number | Date) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  };

  const renderEvents = (item: any, index: number) => {
    enum GameTypes {
      ONE_VS_ONE = "ONE_VS_ONE",
      TWO_VS_TWO = "TWO_VS_TWO",
      THREE_VS_THREE = "THREE_VS_THREE",
      FOUR_VS_FOUR = "FOUR_VS_FOUR",
    }
    const AvailableSlots = (gameType: string) => {
      switch (gameType) {
        case GameTypes.ONE_VS_ONE:
          return 2;
        case GameTypes.TWO_VS_TWO:
          return 4;
        case GameTypes.THREE_VS_THREE:
          return 6;
        case GameTypes.FOUR_VS_FOUR:
          return 8;
        default:
          return 10;
      }
    };

    const participatingPlayers: number = item.players.length;
    const availableSlotsForMatch: number =
      AvailableSlots(item.gameType) - participatingPlayers;
    const isOngoing = item.onGoing;
    const isUserin =
      item.players.filter((player: any) => player === userData.user?._id)
        .length > 1;
    const highlight = isOngoing || isUserin ? "border-2 border-primary" : "";

    return (
      <button
        className={`flex flex-col p-2 m1 items-center md:justify-center justify-between eve rounded-lg mx-1  ${highlight}`}
        key={index}
        onClick={() => onPressEvent(item)}
      >
        <span className="text font-semibold mb-2 text-[9px] ">
          {item.game.name}
        </span>
        <ImageHolder
          uri={item.coverImage}
          width={180}
          height={180}
          className="min-w-[256px] max-md:min-w-[128px] h-auto  transform transition-transform hover:scale-105 hover:shadow-lg rounded-2xl"
        />
        <div className="flex gap-5 mt-2 ">
          <span className="text  text-[9px] ">
            Available slots : {availableSlotsForMatch}
          </span>
          <span className="text  text-[9px] ">
            Entry fee : {item.cubeWager} $
          </span>
        </div>
        {item.matchStatus === "SCHEDULED" && item?.scheduledTime && (
          <span className="text-primary  text-[9px] ">
            On {formatEventTime(item?.scheduledTime)}
          </span>
        )}
      </button>
    );
  };

  const renderLives = (item: any, index: number) => {
    const handleClickLive = () => {
      if (isSuspended) return toast.warning('Account Suspended');
      const streamType = item.streamType;
      switch (streamType) {
        case "IN_APP":
          if (!user.userToken) return dispatch(setLoginModalVisible(true));
          onPressInAppStream?.(item);
          break;

        case "TWITCH":
          window.open(
            `https://www.twitch.tv/${item.twitch?.streamerId}`,
            "_blank"
          );
          break;
        default:
          break;
      }
    };

    const formatThumbnail = (url: string) => {
      if (!url) return "";
      return url.replace("{width}", "640").replace("{height}", "420");
    };
    return (
      <>
        {item?.isStreaming && (
          <div
            className="flex flex-col p-2 m1 items-center justify-center eve rounded-lg mx-1"
            key={index}
            onClick={handleClickLive}
          >
            <span className="text font-semibold mb-2 text-[9px] ">
              {item?.game?.name}
            </span>
            <ImageHolder
              uri={
                item.thumbnail
                  ? formatThumbnail(item.thumbnail)
                  : item.game?.image
              }
              width={180}
              height={180}
              className="min-w-[640] max-md:min-w-[128px] h-auto rounded-2xl transform transition-transform hover:scale-105 hover:shadow-lg"
            />
            <div className="flex flex-row gap-2 mt-1">
              <span className="text  text-[9px] truncate max-w-[200px]">
                {item?.streamTitle
                  ? item?.streamTitle
                  : `${item?.userId?.username} is stream with duel cube`}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderTournaments = (item: any, index: number) => {
    const isUserIn = item.isUserIn;
    const highlight = isUserIn ? "border-2 border-primary" : "";

    return (
      <button
        className={`flex flex-col p-2 m1 items-center justify-center eve rounded-lg mx-1 ${highlight}`}
        key={index}
        onClick={() => onClickTournament(item)}
      >
        <span className="text font-semibold mb-2 text-[9px] ">
          {item.game?.name}
        </span>
        <ImageHolder
          uri={item.game?.image}
          width={180}
          height={180}
          className="min-w-[256px] max-md:min-w-[128px] h-auto rounded-2xl transform transition-transform hover:scale-105 hover:shadow-lg"
        />
        <div className="flex flex-row gap-2">
          <span className="text  text-[10px] ">{item.gameType}</span>
          <span className="text  text-[10px] ">{item.matchId}</span>
        </div>
        {item?.tournamentDate && (
          <span className="text-primary  text-[9px] ">
            On {formatEventTime(item?.tournamentDate)}
          </span>
        )}
      </button>
    );
  };
  const handleStakes = () => {
    if (isSuspended) return toast.warning('Account Suspended');
    navigate("/stakes/available");
    dispatch(setStakeFrom("events"));
  };

  const renderStakingEvents = (item: any, index: number) => {
    const isStreaming = item.streamerId;
    const isChallenge = item.type === 'Challenge';

    const handleFilterBackgroundImage = (gameId: string) => {
      const selectedGame = games.find((game) => game.id === gameId);
      return selectedGame?.backgroundImage;
    };
    const handleFilterGame = (gameId: string) => {
      const selectedGame = games.find((game) => game.id === gameId);
      return selectedGame;
    };
    const Icon = handleFilterGame(item.gameId)?.image;
    const title = handleFilterGame(item.gameId)?.title;
    const AvailableSlots = (gameType: string) => {
      switch (gameType) {
        case GameTypes.ONE_VS_ONE:
          return 2;
        case GameTypes.TWO_VS_TWO:
          return 4;
        case GameTypes.THREE_VS_THREE:
          return 6;
        case GameTypes.FOUR_VS_FOUR:
          return 8;
        default:
          return 10;
      }
    };
    const participatingPlayers: number = item.players?.length;
    const availableSlotsForMatch: number = AvailableSlots(item.gameType) - participatingPlayers;
    const formatEventTime = (timestamp: string | number | Date) => {
      const date = new Date(timestamp);

      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };

      return date.toLocaleString(undefined, options);
    };
    return (
      <button
        className="flex flex-col p-2 m1 justify-center items-center eve rounded-lg mx-1"
        key={index}
        onClick={handleStakes}
      >
        <img
          src={
            item.coverImage
              ? item.coverImage
              : isChallenge
                ? handleFilterBackgroundImage(item.gameId)
                : item?.game?.image
          }
          alt="Game Cover"
          width={180}
          height={180}
          className="min-w-[256px] max-md:min-w-[128px] h-auto rounded-2xl transform transition-transform hover:scale-105 hover:shadow-lg"
        />
        {Icon && (
          <div className=" absolute  flex flex-col justify-center gap-4 items-center">
            <img src={Icon} alt="icon" />
            <p className="text-black text-[14px] font-semibold">{title}</p>
          </div>
        )}
        {isStreaming && <Stream width={30} />}
        <div className="flex flex-row gap-2 text-center">
          <span className="text  text-[10px] ">{item.gameType}</span>
          <span className="text  text-[10px] ">{item.matchId}</span>
        </div>
      </button>
    );
  };

  return (
    <div
      className={`flex flex-col right-10 top-20 backdrop-blur-sm con rounded-lg p-[10px] md:p-10 overflow-x-hidden max-h-[calc(100vh-360px)] ${miscsStyles.beautifulScrollbarHidden}`}
    >
      <div className="flex flex-col justify-center ">
        <div className="flex flex-row justify-between items-center p-4 ">
          <span className="text font-bold text-sm ">Watch Live Duels</span>
          <button
            onClick={() => {
              if (isSuspended) return toast.warning('Account Suspended');
              navigate("/more-events/Lives");
            }}
          >
            <span className="btnText text-sm">Browse All</span>
          </button>
        </div>
        {(
          lives.length > 0 ? (
            <div className="flex flex-row overflow-x-auto">
              {lives?.map(renderLives)}
            </div>
          ) : (
            <span className="text-lg text-center text">No Lives</span>
          )
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div className="flex flex-row justify-between items-center p-4">
          <span className="text font-bold text-sm ">Events</span>
          <button
            onClick={() => {
              if (isSuspended) return toast.warning('Account Suspended');
              navigate("/more-events/Events");
            }}
          >
            <span className="btnText text-sm">Browse All</span>
          </button>
        </div>
        {(
          events.length > 0 ? (
            <div
              className={`flex flex-row overflow-x-scroll ${miscsStyles.beautifulScrollbarHidden}`}
            >
              {events.map(renderEvents)}
            </div>
          ) : (
            <span className="text-lg text-center text">No Events</span>
          )
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div className="flex flex-row justify-between items-center p-4">
          <span className="text font-bold text-sm ">Tournaments</span>
          <button
            onClick={() => {
              if (isSuspended) return toast.warning('Account Suspended');
              navigate("/more-events/Tournaments");
            }}
          >
            <span className="btnText text-sm">Browse All</span>
          </button>
        </div>
        {(
          tournaments.length > 0 ? (
            <div
              className={`flex flex-row overflow-x-scroll ${miscsStyles.beautifulScrollbarHidden}`}
            >
              {tournaments.map(renderTournaments)}
            </div>
          ) : (
            <span className="text-lg text-center text">No Tournaments</span>
          )
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div className="flex flex-row justify-between items-center p-4">
          <span className="text font-bold text-sm ">Stake & Win</span>

          <button onClick={handleStakes}>
            <span className="btnText text-sm">Browse All</span>
          </button>
        </div>
        {(
          stakingEvents.length > 0 ? (
            <div
              className={`flex flex-row overflow-x-scroll ${miscsStyles.beautifulScrollbarHidden}`}
            >
              {stakingEvents.map(renderStakingEvents)}
            </div>
          ) : (
            <span className="text-lg text-center text">
              No Matches Available for Staking
            </span>
          )
        )}
      </div>
      <LoadingAnim
        loading={user.loader}
      />
    </div>
  );
};

export default Event;
