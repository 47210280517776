import { createSlice } from "@reduxjs/toolkit";
import { signOut } from "store/auth/actions";
import {
  createGameChallenge,
  endGameChallenge,
  getGameChallenge,
  getMinigamesLeaderBoard,
  joinGameChallenge,
  setReadyForChallenge,
  startGameChallenge,
} from "./action";

export type playerTypes = {
  _id: string;
  username: string;
  isChallengeAccepted?: boolean;
  profilePicture: string;
  isReady?: boolean;
};

export type gameId = {
  _id: string;
  name: string;
  genre?: string;
  thumbnail?: string;
  createdAt?: any;
};

type gameData = {
  id: string;
  title: string;
  route: string;
  isLandscape: boolean;
  backgroundImage: string;
  image?: string;
  gameLogo?: string;
  playButtonImage?: string;
  textColor?: string;
  textColor2?: string;
  gameType: string;
  staking: boolean;
};

type setMatch = {
  message: string;
  challengeId: string;
  displayId: string;
};

type currentChallengeType = {
  challengeStatus?: string | null;
  challengeId?: string | null;
  challengeType: string | null;
  coverImage: string | null;
  cubeWager: number;
  createdAt: any;
  invitedPlayers: any;
  readyUsers: any;
  players: playerTypes[];
  host: string;
  gameId: gameId;
  scheduledTime: any;
  results: {};
  winner: string;
  finishedUsers: any[];
  isDraw?: boolean;
};
interface GameHubSlice {
  _id: string;
  type: string;
  setMatch: setMatch;
  matchCubes: string;
  challengedPlayers: any[];
  loader: boolean;
  selectedGame: gameData | null;
  currentGame: currentChallengeType;
  joiningChallengeId: string;
}

const initialState: GameHubSlice = {
  _id: "",
  type: "",
  setMatch: {
    message: "",
    challengeId: "",
    displayId: "",
  },
  matchCubes: "",
  challengedPlayers: [],
  loader: false,
  selectedGame: {
    id: "",
    title: "",
    route: "",
    isLandscape: false,
    image: "",
    gameLogo: "",
    backgroundImage: "",
    textColor: "",
    textColor2: "",
    gameType: "",
    staking: false,
  },
  currentGame: {
    challengeId: null,
    challengeStatus: null,
    challengeType: null,
    coverImage: null,
    cubeWager: 0,
    createdAt: Date.now(),
    invitedPlayers: [],
    readyUsers: [],
    players: [],
    finishedUsers: [],
    host: "",
    gameId: {
      _id: "",
      name: "",
      genre: "",
      thumbnail: "",
      createdAt: Date.now(),
    },
    scheduledTime: Date.now(),
    results: {},
    winner: "",
    isDraw: false,
  },
  joiningChallengeId: "",
};

export const gameHubSlice = createSlice({
  name: "gameHub",
  initialState,
  reducers: {
    updatePlayerList: (state, action) => {
      state.currentGame.players = action.payload;
    },
    updateChallengedList: (state, action) => {
      state.currentGame.invitedPlayers = action.payload;
    },
    setMatchCubes: (state, action) => {
      state.matchCubes = action.payload;
    },
    setSelectedGame: (state, action) => {
      state.selectedGame = action.payload;
      if (state.selectedGame)
        state.selectedGame.staking = action.payload.challenge?.stakingEnabled;
    },
    setJoiningChallengeId: (state, action) => {
      state.joiningChallengeId = action.payload;
    },
    setResults: (state, action) => {
      state.currentGame.winner = action.payload.winner;
      state.currentGame.results = action.payload?.results;
    },
    setCurrentChallengeStaking: (state, action) => {
      if (state.selectedGame) state.selectedGame.staking = action.payload;
    },
    resetCurrentGame: (state) => {
      state.currentGame = {
        ...state.currentGame,
        ...initialState.currentGame,
      };
    },
    resetGameHubSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(createGameChallenge.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createGameChallenge.fulfilled, (state, action) => {
      state.setMatch = action.payload;
      state.loader = false;
    });
    builder.addCase(createGameChallenge.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getGameChallenge.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getGameChallenge.fulfilled, (state, action) => {
      const previousResults = state.currentGame?.results || {};
      state.currentGame = { ...state.currentGame, ...action.payload.challenge };
      state.currentGame.results = previousResults;
      if (state.selectedGame)
        state.selectedGame.staking = action.payload.challenge?.stakingEnabled;
      state.loader = false;
    });

    builder.addCase(getGameChallenge.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(startGameChallenge.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(startGameChallenge.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(startGameChallenge.rejected, (state, action) => {
      state.loader = false;
    });
    builder.addCase(endGameChallenge.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(endGameChallenge.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(endGameChallenge.rejected, (state, action) => {
      state.loader = false;
    });
    builder.addCase(joinGameChallenge.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(joinGameChallenge.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(joinGameChallenge.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(setReadyForChallenge.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(setReadyForChallenge.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(setReadyForChallenge.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getMinigamesLeaderBoard.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMinigamesLeaderBoard.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(getMinigamesLeaderBoard.rejected, (state) => {
      state.loader = false;
    });
  },
});

export default gameHubSlice.reducer;
export const {
  setMatchCubes,
  setSelectedGame,
  setJoiningChallengeId,
  updatePlayerList,
  resetCurrentGame,
  resetGameHubSlice,
  updateChallengedList,
  setResults,
  setCurrentChallengeStaking,
} = gameHubSlice.actions;
