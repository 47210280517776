import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";
import CloseIcon from "assets/close-button.svg";
import CustomSwitch from "components/Switch";
import { setEnableStaking } from "store/matchMaking/matchMakingSlice";
import { setEnableTournamentStaking } from "store/tournament/tournamentSlice";
import { ReactComponent as Coin } from "assets/stakes/staking-coin.svg";
import { ReactComponent as Eyes } from "assets/stakes/staking-eyes.svg";
import { ReactComponent as Fire } from "assets/stakes/staking-fire.svg";
import { setCurrentChallengeStaking } from "store/gameHub/gameHubSlice";

export default function EnableStakingScreen({ }) {
    let { from } = useParams();
    const { selectedDuelPath, enableStaking } = useAppSelector((state) => state.matchMaking);
    const { selectedGame } = useAppSelector((state) => state.gameHub);
    const tournaments = useAppSelector((state: RootState) => state.tournament);
    const staking = selectedDuelPath === "CREATE_TOURNAMENT" ? tournaments.enableStaking : from === 'Challenge' ? selectedGame?.staking : enableStaking;
    const [isSwitchOn, setIsSwitchOn] = useState<boolean>(staking || false);
    const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
    console.log(from, selectedGame?.staking)

    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const handleClose = () => {
        navigate("/wagering");
    };

    const onBack = () => {
        navigate("/duels/how-many-cubes");
    };

    const handleNext = () => {
        if (selectedDuelPath === "CREATE_MATCHMAKING")
            return navigate("/duels/matchMaking");
        if (selectedDuelPath === "CREATE_TOURNAMENT") {
            dispatch(setEnableTournamentStaking(isSwitchOn))
        } else if (from === 'Challenge') {
            dispatch(setCurrentChallengeStaking(isSwitchOn))
            return navigate("/gaming/overview");
        } else {
            dispatch(setEnableStaking(isSwitchOn))
        }
        navigate("/duels/duel-overview");
    };

    const handleSwitchChange = () => {
        setIsSwitchOn(!isSwitchOn);
    }


    return (
        <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex gap-[7em] flex-col items-center  h-screen ">
            <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
                <div
                    className="  backdrop-blur-sm con rounded-[34px] p-10
          overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]"
                >
                    {from !== 'Challenge' && <div className="w-full mb-5 flex justify-between">
                        {matchMaking?.selectedDuelPath === "CREATE_TOURNAMENT" ? <span className="text-lg text-subText items-center font-normal uppercase">
                            {matchMaking?.selectedDuelPath} {'>'} {tournaments?.selectedGame?.name} {'>'} {tournaments?.selectedPlatform} {'>'} {tournaments?.selectedGameType.type} {'>'} {tournaments?.selectedRound.name} {'>'} ${tournaments?.cubes}
                        </span>
                            :
                            <span className="text-lg text-subText items-center font-normal uppercase">
                                {matchMaking?.selectedDuelPath} {'>'} {matchMaking?.matchSchedule} {'>'} {matchMaking?.selectedGame?.name} {'>'} {matchMaking?.selectedPlatform} {'>'} {matchMaking?.selectedGameType.type} {'>'} ${matchMaking?.matchCubes}
                            </span>
                        }
                    </div>}
                    <div className="w-full rounded-t-[34px] flex items-center justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                                <img src={BackIcon} alt="Close" />
                            </button>
                            <span className="text-3xl text-white items-center font-normal">
                                Enable Staking
                            </span>
                        </div>
                        <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                            <img src={CloseIcon} alt="Close" />
                        </button>
                    </div>
                    <div className="flex flex-col items-center gap-5 justify-center mt-10 max-md:flex-col h-auto">
                        <span className="text-white md:text-2xl text-xl font-semibold">Why Enable Staking?</span>
                        <div className="grid md:grid-cols-3 gap-4 md:min-h-[150px] max-md:overflow-y-auto">
                            <div className="flex md:flex-col justify-evenly items-center border border-primary rounded-lg bg-borderColor p-2 desktop:h-[35vh]">
                                <Coin className="h-[10vh] w-[10vw] max-md:w-[12vh] max-md:h-[8vh] desktop:h-[15vh] desktop:w-[15vw]" />
                                <div className="flex flex-col w-full gap-2">
                                    <span className="text-white text-center">Earn Extra DC</span>
                                    <span className="text-center max-md:text-justify text-darkGray max-md:text-sm">Gain additional DC coins as rewards every time someone stakes on your match.</span>
                                </div>
                            </div>
                            <div className="flex md:flex-col justify-evenly items-center border border-primary rounded-lg bg-borderColor p-2 desktop:h-[35vh]">
                                <Eyes className="h-[10vh] w-[10vw] max-md:w-[12vh] max-md:h-[8vh] desktop:h-[15vh] desktop:w-[15vw]" />
                                <div className="flex flex-col w-full gap-2">
                                    <span className="text-white text-center">More Exposure</span>
                                    <span className="text-center max-md:text-justify text-darkGray max-md:text-sm">Your match will appear in the "Stake & Win" section, drawing more viewers and participants.</span>
                                </div>
                            </div>
                            <div className="flex md:flex-col justify-evenly items-center border border-primary rounded-lg bg-borderColor p-2 desktop:h-[35vh]">
                                <Fire className="h-[10vh] w-[10vw] max-md:w-[12vh] max-md:h-[8vh] desktop:h-[15vh] desktop:w-[15vw]" />
                                <div className="flex flex-col w-full gap-2">
                                    <span className="text-white text-center">Increase Engagement</span>
                                    <span className="text-center max-md:text-justify text-darkGray max-md:text-sm">Attract an audience and make your match more exciting with live predictions and betting.</span>
                                </div>
                            </div>
                        </div>

                        <div className="bottom-28 max-md:w-full absolute mm:relative mm:bottom-0 md:mt-20 flex bg-borderColor h-12 rounded-xl items-center px-7 justify-between w-full max-w-[350px]">
                            <span className="font-semibold text-white justify-center text-center text-xs md:text-lg w-full max-w-[350px]">
                                Enable Staking
                            </span>
                            <CustomSwitch
                                checked={isSwitchOn}
                                onChange={handleSwitchChange}
                                disabled={false}
                            />
                        </div>
                        <ButtonDc
                            type="primary"
                            className="bottom-10 max-md:w-full absolute mm:relative mm:bottom-0"
                            textClassName="max-md:text-sm"
                            text={'Next'}
                            action={handleNext}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
