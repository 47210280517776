import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOtherUser } from "store/user/action";
import { RootState } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import "../styles/components/header.scss";
import ImageHolder from "./ImagePlaceholder";
import SearchBar from "./Search";

const Header = ({ onClick, onPressUser }: any) => {
  const user = useAppSelector((state: RootState) => state.auth);
  const userData = useAppSelector((state: RootState) => state.user?.user);
  const [results, setResults] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSearch = (item: any) => {
    if (item._id === user.user._id) {
      return navigate(`/profile/${item._id}`);
    }
    dispatch(getOtherUser(item._id)).then(() =>
      navigate(`/profile/${item._id}`)
    );
  };
  return (
    <div className="hidden md:flex flex-row  justify-between w-full z-10 ">
      <div className="text-white min-w-[350px] md:w-1/2 absolute top-5">
        <SearchBar
          searchIcon
          placeholder="Search people"
          type="people"
          setResults={(v) => setResults(v)}
          onClick={handleSearch}
        />
      </div>
      <div className="flex flex-row gap-10 items-center absolute right-5">
        {user?.userToken ? (
          <div className="relative">
            <span className="text-lg font-semibold text cursor-pointer">
              Hi, {userData?.username}
            </span>
          </div>
        ) : (
          <button
          id="signIn"
            className="p-2 rounded-lg bg-primaryActive w-[145px] h-[48px] flex items-center justify-center"
            onClick={onClick}
          >
            <span className="text-sm ">Sign in</span>
          </button>
        )}
        <button id="profilePicture" onClick={onPressUser} disabled={!user?.userToken}>
          <ImageHolder
            uri={userData?.profilePicture}
            className="flex w-[50px] h-[50px] rounded-full "
          />
        </button>
      </div>
    </div>
  );
};

export default Header;
