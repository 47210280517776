import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { useDispatch } from "react-redux";
import PhysicalGamesCoverPng from 'assets/physical_games.png';

import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";
import { createPhysicalMatch } from "store/physicalGames/action";
import { gameModes, resetPhysicalGameSlice, setPaypalConnect } from "store/physicalGames/physicalGamesSlice";
import { setDuelPath } from "store/matchMaking/matchMakingSlice";
import { setTournamentId } from "store/tournament/tournamentSlice";
import { setScreenStatus } from "store/currentMatch/currentMatchSlice";
import { getTournamentById } from "store/liveBrackets/actions";
import { handleNotifications } from "store/notifications/actions";
import { getLinkedAccounts } from "store/linkedAccounts/action";

interface EditableRowProps {
    title: string;
    subtitle?: string;
    onEdit?: () => void;
    spectators?: boolean;
}

const EditableRow: React.FC<EditableRowProps> = ({
    title,
    subtitle,
    onEdit,
}) => {

    return (
        <div className="flex flex-row bg-borderColor items-center justify-between gap-5  w-1/2 h-16 rounded-lg p-2  max-md:w-11/12">
            <div className="flex flex-col justify-between gap-1">
                <span className="text-white">{title}</span>
                <span className="text-placeholder text-xs">{subtitle}</span>
            </div>

            {onEdit && <button
                className="flex w-[150px] h-[35px] rounded-lg p-1 items-center justify-center bg-primary hover:bg-primaryActive max-md:w-1/5"
                onClick={onEdit}
            >
                <span>Edit</span>
            </button>}

        </div>
    );
};

const SetupGameOverviewScreen = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const gameData = useAppSelector((state: RootState) => state.physicalGames);
    const params = useParams();
    let urlMatchId = params?.paramMatchId;
    const isFreePlay = gameData.gameMode === gameModes.FREE_PLAY;

    const linkedAccounts = useAppSelector(
        (state: RootState) => state.linkedAccounts
    );
    const notificationData = useAppSelector(
        (state) => state.notification.actionData
    );

    const handleClose = () => {
        navigate("/wagering");
    };

    useEffect(() => {
        dispatch(getLinkedAccounts());
    }, [])


    useEffect(() => {
        if (urlMatchId === 'paypalConnected') {
            if (gameData.isHost) return onPressCreate();
            urlMatchId = notificationData.actionData;
            onPressJoin();
        }

    }, [urlMatchId])

    const onPressCreate = () => {
        if (!linkedAccounts.accounts.paypal && !isFreePlay) {
            dispatch(setPaypalConnect(false))
            return navigate(`/manage-accounts/paypal`);
        }
        const tournamentData = {
            cubeWager: gameData.wager,
            gameType: gameData.gameVarient,
            tournamentType: gameData.gameFormat,
            tournamentDate: gameData.matchDate,
            savePreset: false,
            stakingEnabled: false,
            tournamentVarient: 'PHYSICAL_GAMES',
            gameName: gameData.name,
            location: gameData.location,
            tournamentStatus: 'PRIVATE',
            consoleType: 'CROSS_PLAY',
            providerType: 'EPIC',
            designatedSpectators: [],
            rounds: 'best 1 of 1'
        };

        dispatch(
            createPhysicalMatch({
                payload: { tournamentData },
                callback: (data) => {
                    dispatch(resetPhysicalGameSlice());
                    dispatch(setDuelPath('PHYSICAL_GAMES'));
                    dispatch(setScreenStatus('PHYSICAL_GAMES'));
                    dispatch(setTournamentId(data.tournamentId));
                    return navigate("/physical-games/invite-others");
                },
            })
        );
    };

    const onPressJoin = () => {
        dispatch(setTournamentId(urlMatchId));

        dispatch(
            handleNotifications({
                payload: {
                    consoleType: 'CROSS_PLAY',
                    providerType: 'EPIC',
                    actionData: urlMatchId,
                    actionType: "JOIN_TOURNAMENT",
                    actionState: true,
                },
                callback: () => {
                    dispatch(resetPhysicalGameSlice());
                    navigate(`/physical-games/live-bracket/${urlMatchId}`);
                }
            }));
    };

    useEffect(() => {
        if (gameData.isHost) return;
        dispatch(
            getTournamentById({
                payload: urlMatchId,
            }));
    }, [])



    const dateValue = new Date(gameData?.matchDate);
    const formattedDate = dateValue.toLocaleString("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });

    return (
        <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen ">
            <div className="container flex flex-col gap-y-[20px] md:my-16 items-center max-md:p-5">
                <div
                    className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
                >
                    <div className="w-full rounded-t-[34px] flex items-center justify-between">
                        <span className="text-3xl text-white items-center font-normal">
                        </span>
                        <div className="flex gap-6 items-center">
                            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col items-center mt-10">
                        <span className="text-white text-center font-aldo text-4xl mb-5">{gameData?.name}</span>
                        <img
                            className="md:h-[30vh] rounded-xl"
                            src={PhysicalGamesCoverPng}
                            alt="Game Banner"
                        />
                        <span className="text-white text-center mt-5">
                            Enter a competitive 1 vs 1 match with your opponent.
                            <br />
                            The player who achieves the highest score at the end of the game will be declared the winner and take home the prize.
                        </span>
                    </div>
                    <div className="flex flex-col items-center gap-5 md:mt-[10px] max-md:mt-5">
                        <span className="text-primary">{formattedDate}</span>
                        <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-center">
                            <EditableRow
                                title="Cube Wager"
                                subtitle={`${gameData?.wager > 0 ? '$' + gameData?.wager : gameData?.gameMode}`}
                            />
                        </div>
                        <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-center">
                            <EditableRow
                                title="Game Type"
                                subtitle={`${gameData?.gameFormat}`}
                            />
                        </div>
                        <div className="flex max-md:flex-col md:w-4/5 max-md:w-full gap-7 max-md:gap-5 md:justify-center">
                            <EditableRow
                                title="Game Varient"
                                subtitle={`${gameData?.gameVarient}`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-evenly items-center mt-8">
                        <div className="flex flex-col gap-8 max-md:w-2/3">
                            {!urlMatchId ? (
                                <ButtonDc
                                    text='Create Game'
                                    action={onPressCreate}
                                    type="primary"
                                />
                            )
                                :
                                (
                                    <ButtonDc
                                        text='Join Game'
                                        action={onPressJoin}
                                        type="primary"
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <LoadingAnim loading={gameData?.loading} />
        </div>
    );
};

export default SetupGameOverviewScreen;
