import React, { useEffect, useState } from "react";
import Accept from "assets/accept.svg";
import Cancel from "assets/cancel.svg";
import notificationImg from "assets/logo.png";

const actionTypes = [
  "JOIN_COMMUNITY",
  "JOIN_DUEL",
  "JOIN_MATCH",
  "FOLLOW_BACK",
  "OPPONENT_JOIN_MATCH",
  "REQUEST_MONEY",
  "JOIN_TOURNAMENT",
  "OPPONENT_JOIN_CHALLENGE",
  "READY_FOR_TOURNAMENT",
  'JOIN_PHYSICAL_GAME'
];

interface InAppNotificationProps {
  image: string;
  text: string;
  date: string;
  _id?: string;
  type?: string;
  state?: boolean;
  invited?: any[];
  onAccept?: () => void;
  onClose?: () => void;
  onPressNotification?: () => void;
}

const InAppNotifications: React.FC<InAppNotificationProps> = ({
  image,
  text,
  date,
  type,
  state,
  invited,
  _id,
  onAccept,
  onClose,
  onPressNotification,
}) => {
  const calculateDaysPassed = () => {
    const notificationDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - notificationDate.getTime();
    const secondsPassed = Math.floor(timeDifference / 1000);
    if (secondsPassed < 60) {
      return "just now";
    }
    const minutesPassed = Math.floor(secondsPassed / 60);
    if (minutesPassed < 60) {
      return `${minutesPassed} ${minutesPassed === 1 ? "minute" : "minutes"
        } ago`;
    }
    const hoursPassed = Math.floor(minutesPassed / 60);
    if (hoursPassed < 24) {
      return `${hoursPassed} ${hoursPassed === 1 ? "hour" : "hours"} ago`;
    }
    const daysPassed = Math.floor(hoursPassed / 24);
    if (daysPassed < 7) {
      return `${daysPassed} ${daysPassed === 1 ? "day" : "days"} ago`;
    }
    const weeksPassed = Math.floor(daysPassed / 7);
    if (weeksPassed < 4) {
      return `${weeksPassed} ${weeksPassed === 1 ? "week" : "weeks"} ago`;
    }
    const monthsPassed = Math.floor(daysPassed / 30);
    return `${monthsPassed} ${monthsPassed === 1 ? "month" : "months"} ago`;
  };
  return (
    <button className="bg-modalFade rounded-lg p-2 flex flex-row items-center justify-between gap-x-2" onClick={onPressNotification}>
      <img
        src={image ? image : notificationImg}
        alt="notification"
        className="w-[5em] rounded-lg max-md:w-[3em] max-md:h-[3em] "
      />
      <span className="text-white max-md:text-[8px] text-[12px] text-left">
        {text}
      </span>
      <div className="w-[5em] flex flex-col ">
        {actionTypes.includes(type || "") &&
          !state &&
          !invited?.includes(_id) ? (
          <div className="flex flex-row items-center justify-center gap-x-2">
            <button onClick={onAccept}>
              <img src={Accept} className="w-[35px] max-md:w-[50px]" />
            </button>
            <button onClick={onClose}>
              <img src={Cancel} className="w-[35px] max-md:w-[50px]" />
            </button>
          </div>
        ) : null}
        <span className="text-white max-md:text-[6px] text-sm">
          {calculateDaysPassed()}
        </span>
      </div>
    </button>
  );
};

export default InAppNotifications;
