import { messaging } from "firebaseConfig/firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { cleanFcmData, setMessage } from "store/fcm/fcmSlice";
import { useAppSelector } from "store/hooks";
import { handleChatNotificationCount, handleMatchCommentsNotificationCount, handleNotificationCount } from "utils/noitificationHandler";
import { AppDispatch, RootState } from "store/index";
import { ReactComponent as ChatIcon } from "assets/sideBar/chats.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentMatch } from "store/currentMatch/currentMatchActions";
import { setCommunityChatNotifications, setPrivateChatNotifications } from "store/notifications/notificationSlice";
import { getUser } from "store/user/action";

const FCMStore = () => {
  const dispatch = useDispatch<AppDispatch>();
  const route = useLocation();
  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const navigate = useNavigate();

  const freePlay = currentMatch?.cubeWager === 0;

  const checkScreen = ({ winner, matchId }: any) => {
    switch (winner) {
      case 2:
        //team 2 won
        const isUserInTeam2 = currentMatch.team2.find(
          (user: string) => user === userId
        );
        if (isUserInTeam2) return navigate(`/duels/won/${matchId}`);
        navigate(`/duels/lost/${matchId}`);
        break;
      case 1:
        //team 1 won
        const isUserInTeam1 = currentMatch.team1.find(
          (user: string) => user === userId
        );
        if (isUserInTeam1) return navigate(`/duels/won/${matchId}`);
        navigate(`/duels/lost/${matchId}`);
        break;
      default:
        break;
    }
  };

  onMessage(messaging, (payload) => {
    dispatch(setMessage(payload));
    if (userId === payload?.data?.senderId) {
      return;
    }
    switch (payload?.data?.type || payload?.data?.types || payload?.data?.FCMType) {
      case "DO NOT SHOW":
        break;

      case "PRIVATE_MESSAGE":
        if (route?.pathname !== ("/communication/main")) {
          dispatch(setPrivateChatNotifications(payload.data))
          handleChatNotificationCount(1);
          return toast(`You have a new message from ${payload?.data?.sender}`, {
            icon: <ChatIcon />,
          });
        }
        return;
      case "COMMUNITY_MESSAGE":
        if (route?.pathname !== ("/communication/main")) {
          dispatch(setCommunityChatNotifications(payload.data))
          handleChatNotificationCount(1);
          return toast(`You have a new message from ${payload?.data?.sender}`, {
            icon: <ChatIcon />,
          });
        }
        return;
      case "MATCH_MESSAGE":
        handleMatchCommentsNotificationCount(1);
        return toast(`You have a new match comment from ${payload?.data?.sender}`, {
          icon: <ChatIcon />,
        });

      case "TOURNAMENT_INVITATION":
      case "MATCH_CHALLENGE":
      case "CHALLENGE_INVITE":
        toast.info("You have received a new Invitation/Challenge");
        handleNotificationCount(1);
        return;

      case "READY_FOR_TOURNAMENT":
        toast.info("Your scheduled tournament will start in 10 minutes");
        return;

      case "APPROVE_WALLET":
        dispatch(getUser())
        toast.info(payload?.data?.message);
        handleNotificationCount(1);
        return;

      case "DECLINE_WALLET":
        toast.info(payload?.data?.message);
        handleNotificationCount(1);
        return;

      case "NEW_FOLLOWER":
        toast.info(payload?.data?.message);
        handleNotificationCount(1);
        return;

      case "REQUEST_MONEY":
      case "NEW_COIN_TRANSACTION":
      case "REQUEST_MONEY_RESPONSE":
        toast.info(payload?.data?.message);
        handleNotificationCount(1);
        return;


      case "MATCH_WINNER":
        const matchId = payload?.data?.matchId;
        const winner = payload?.data?.winner;
        dispatch(getCurrentMatch({ matchId, callback: () => { checkScreen({ winner: Number(winner), matchId }); } }));
        return
      case "PLAYER_READY_STATE":
      case "START_MATCH":
        if (payload?.data) {
          toast.info(payload.data?.message);
        }
        return dispatch(cleanFcmData());
      default:
        if (!freePlay) toast.info(payload?.data?.message);
    }
  });
  return <></>;
};

export default FCMStore;
