import React, { useState } from "react";
import CloseIcon from "../assets/close-button.svg";
import ButtonDc from "./Button";
import { MLB } from "constant/platForms";
import { toast } from "react-toastify";

interface PlatformModalProps {
  onPress?: (data: string) => void;
  onClose?: () => void;
  data: any[];
  visible?: boolean;
  platform?: string;
}

const PlatFormsModal: React.FC<PlatformModalProps> = ({
  visible,
  onPress,
  onClose,
  data,
  platform,
}) => {
  const messageObj = data?.find((item) => item.hasOwnProperty("message"));
  const platFormObj = data?.find((item) => item.hasOwnProperty("platForm"));
  const consoleObj = data?.find((item) => item.hasOwnProperty("console"));
  const headerObj = data?.find((item) => item.hasOwnProperty("header"));
  const Icon = data?.find((item) => item.hasOwnProperty("logo"));
  const btnIcon = data?.find((item) => item.hasOwnProperty("btnLogo"));
  const Logo = Icon?.logo;
  const btnLogo = btnIcon?.btnLogo;

  const patterns: any = {
    epic: /^[a-zA-Z0-9_-]{3,16}$/,
    playstation: /^[a-zA-Z0-9_-]{3,16}$/,
    ea: /^[a-zA-Z0-9_-]{3,16}$/,
    xbox: /^[a-zA-Z0-9_-]{3,16}$/,
    battlenet: /^[a-zA-Z0-9]+#\d{4,5}$/,
    nintendo: /^\d{4}-\d{4}-\d{4}$/,
    origin: /^[a-zA-Z0-9_-]{3,16}$/,
    steam: /^STEAM_[0-5]:[01]:\d+$/,
    mlb: /^[a-zA-Z0-9_-]{3,16}$/,
    streetfighter: /^[a-zA-Z0-9_-]{3,16}$/,
    activition: /^[a-zA-Z0-9_-]{3,16}#\d{4,12}$/,
  };

  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);

  const onValueChange = (e: any) => {
    if (e.target.value.length > 1) setDisabled(false);
    setValue(e.target.value);


  };

  const onContinue = () => {
    if (platform && patterns[platform]?.test(value)) {
      onPress?.(value);
      setValue("");
      setDisabled(true);
      onClose?.();
    } else {
      toast.error("Invalid ID");
    }

  };


  return (
    <>
      {visible && (
        <div className="w-full h-full bg-modalFade  fixed z-10 ">
          <div
            className="w-3/5 h-4/5 p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full"
          >
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-2xl text-white items-center font-normal"></span>
              <button className=" w-[25px] h-[25px]" onClick={onClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-col items-center md:justify-center md:gap-4 gap-4 p-2 h-full max-md:h-full">
              {Logo && (
                <img src={Logo} className="w-[20vh] h-[20vh]" alt="img" />
              )}
              <span className="text-3xl text-white max-md:text-2xl">
                {headerObj.header}
              </span>
              <span className="text-2xl text-placeholder max-md:text-[14px] text-center">
                {headerObj.message}
              </span>
              <input
                className="bg-inputGray rounded-2xl md:h-20 h-16 w-full md:w-1/2 text-center border-inputGray md:text-[22px] text-white"
                placeholder="Enter ID"
                value={value}
                onChange={(e) => onValueChange(e)}

              />
              <ButtonDc
                disabled={disabled}
                text="Save"
                type={"primary"}
                action={onContinue}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlatFormsModal;
