import { createSlice } from "@reduxjs/toolkit";
import {
  challengeToMatch,
  checkStream,
  createMatch,
  getCurrentMatchState,
  getGames,
  getMatchChat,
  getStream,
  inviteToMatch,
  joinLobby,
  voteMatch,
} from "./actions";
import { MatchStatus } from "constant/types";
import { signOut } from "store/auth/actions";
import { getMatchById } from "store/notifications/actions";

type platformType = {
  xbox: boolean;
  playstation: boolean;
  pc: boolean;
};
type providersType = {
  type: string;
  text: string;
  subtext: string;
};
type teamTypes = {
  name: string;
  roundOptions: number;
  _id: string;
};

type gameTypeTypes = {
  type: string;
  text: string;
  teams: teamTypes[];
  _id: string;
};

type roundTypes = {
  name: string;
  _id: string;
};

type consoleType = {
  type: string;
  provider: string;
  text: string;
  _id: string;
  subtext: string;
  consoleType: string;
};

type gameType = {
  platforms: platformType;
  _id: string;
  name: string;
  image: string;
  consoles: consoleType[];
  rounds: roundTypes[];
  gameTypes: gameTypeTypes[];
  providers: providersType[];
  streamRequired: boolean;
};

type setMatch = {
  message: string;
  matchId: string;
  displayId: string;
  matchCommunity: string;
};

type matchMakingState = {
  matchId: string;
  gamesList: gameType[] | [];
  invitedPlayers: any[];
  selectedGame: gameType | null;
  selectedDuelPath: string;
  matchSchedule: string;
  errorMessage: string;
  selectedPlatform: string;
  selectedGameType: gameTypeTypes;
  selectedTeam: string;
  selectedRound: {
    _id: string;
    name: string;
  };
  matchChatSuccess: boolean;
  matchChat: { messages: any[] };
  matchCubes: string;
  setMatch: setMatch;
  isFromHome: boolean;
  matchStatus: string;
  console: {
    type?: string;
    provider?: string;
    text?: string;
    _id?: string;
    subtext?: string;
    consoleType?: string;
  };

  joiningMatchId: string;
  fromPresets: boolean;
  loader: boolean;
  spectators: string[];
  date: Date | null;
  privacy: string;
  page: number;
  enableStaking?: boolean;
  twitchConnectedMatch?: string;
  backgroundMatch: {
    _id?: string;
    coverImage?: string;
    matchStatus: MatchStatus;
    cubeWager?: number;
    onGoing?: boolean;
    winner?: number;
    team1?: any[];
    team2?: any[];
    gameType?: string;
    rounds?: string;
    notLastStage?: boolean;
    team1Votes?: any[];
    team2Votes?: any[];
    tournamentId?: string;
  } | null;
  backgroundTournament?: {
    _id: string;
    tournamentId: string;
    coverImage: string;
    cubeWager: number;
    rounds: string;
    currentStage: number;
    host: string;
    invitedUsers: string[];
    players: any[];
    designatedSpectators: [];
    game: string;
    gameType: gameTypeTypes;
    tournamentType: string;
    tournamentVisibility: string;
    tournamentStatus: string;
    tournamentDate: string;
    isStarted: boolean;
    entryFee: number;
    matchList: any[];
    community: string;
    isPhysicalGame: boolean;
  } | null;
  backgroundChallenge?: {
    _id: string;
    challengeId: string;
    challengeType: string;
    challengeStatus: string;
    coverImage: string;
    finishedUsers: any[];
    gameId: string;
    onGoing: boolean;
    payedUsers: any[];
  } | null;
};

const initialState: matchMakingState = {
  matchId: "",
  gamesList: [],
  invitedPlayers: [],
  matchStatus: "",
  selectedGame: {
    _id: "",
    platforms: { xbox: false, playstation: false, pc: false },
    name: "",
    image: "",
    consoles: [],
    rounds: [],
    gameTypes: [],
    providers: [],
    streamRequired: false,
  },
  matchChatSuccess: false,
  matchChat: { messages: [] },
  errorMessage: "",
  selectedDuelPath: "",
  matchSchedule: "",
  selectedPlatform: "",
  selectedGameType: { _id: "", text: "", type: "", teams: [] },
  selectedTeam: "",
  selectedRound: {
    _id: "",
    name: "",
  },
  matchCubes: "",
  setMatch: {
    message: "",
    matchId: "",
    displayId: "",
    matchCommunity: "",
  },
  isFromHome: false,
  console: {},
  joiningMatchId: "",
  fromPresets: false,
  loader: false,
  spectators: [],
  date: null,
  privacy: "Public",
  page: 1,
  backgroundMatch: null,
  backgroundChallenge: null,
  enableStaking: false,
  twitchConnectedMatch: "",
};

export const matchMakingSlice = createSlice({
  name: "matchMaking",
  initialState,
  reducers: {
    setSelectedGame: (state, action) => {
      state.selectedGame = action.payload;
    },
    setSpectators: (state, action) => {
      state.spectators = action.payload;
    },
    setSelectedGameImage: (state, action) => {
      if (state.selectedGame) state.selectedGame.image = action.payload;
    },
    setDuelPath: (state, action) => {
      state.selectedDuelPath = action.payload;
    },
    setMatchSchedule: (state, action) => {
      state.matchSchedule = action.payload;
    },
    setMatchPlatform: (state, action) => {
      state.selectedPlatform = action.payload;
    },
    setSelectedGameType: (state, action) => {
      state.selectedGameType = action.payload;
    },
    setSelectedGameTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
    setSelectedRound: (state, action) => {
      state.selectedRound = action.payload;
    },
    setMatchCubes: (state, action) => {
      state.matchCubes = action.payload;
    },
    setMatchFromHome: (state, action) => {
      state.isFromHome = action.payload;
    },
    setConsole: (state, action) => {
      state.console = action.payload;
    },
    setJoiningMatchId: (state, action) => {
      state.joiningMatchId = action.payload;
    },
    setFromPresets: (state, action) => {
      state.fromPresets = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload?.date;
    },
    setPrivacy: (state, action) => {
      state.privacy = action.payload?.privacy;
    },
    clearDate: (state) => {
      state.date = new Date();
      state.privacy = "Public";
    },
    resetBackgroundMatch: (state) => {
      state.backgroundMatch = null;
      state.backgroundTournament = null;
      state.backgroundChallenge = null;
      state.twitchConnectedMatch = "";
    },
    setMatchStatus: (state, action) => {
      if (action.payload.matchStatus) {
        state.backgroundMatch = { ...state.backgroundMatch, ...action.payload };
        if (action.payload?.callback) action.payload?.callback();
        return;
      }
      state.backgroundMatch = {
        ...state.backgroundMatch,
        matchStatus: action.payload,
      };
    },
    updateMatchChatMessages: (state, action) => {
      state.matchChat = {
        ...state?.matchChat,
        messages: [...(state?.matchChat?.messages || []), action?.payload],
      };
    },
    resetMatchmakingSlice: (state) => {
      return initialState;
    },
    setEnableStaking: (state, action) => {
      state.enableStaking = action.payload;
    },
    setTwitchConnectedMatch: (state, action) => {
      state.twitchConnectedMatch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGames.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getGames.fulfilled, (state, action) => {
      state.gamesList = action.payload.games;
      state.errorMessage = "";
      state.loader = false;
      if (action.payload?.totalPages > state.page) {
        state.page += 1;
      }
    });
    builder.addCase(getGames.rejected, (state) => {
      state.errorMessage = "Failed to fetch events";
      state.loader = false;
    });
    builder.addCase(createMatch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createMatch.fulfilled, (state, action) => {
      state.setMatch = action.payload;
      state.loader = false;
    });
    builder.addCase(createMatch.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(inviteToMatch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(inviteToMatch.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(inviteToMatch.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(challengeToMatch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(challengeToMatch.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(challengeToMatch.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(joinLobby.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(joinLobby.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(joinLobby.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(voteMatch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(voteMatch.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(voteMatch.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(checkStream.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(checkStream.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(checkStream.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getMatchById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMatchById.fulfilled, (state, action) => {
      state.loader = false;
      state.invitedPlayers = action.payload?.invitedPlayers;
      state.matchStatus = action.payload.matchStatus;
    });
     builder.addCase(getMatchById.rejected, (state) => {
       state.loader = false;
     });
    builder.addCase(getCurrentMatchState.fulfilled, (state, action) => {
      state.backgroundMatch = action.payload?.currentMatch;
      state.backgroundChallenge = action.payload?.currentChallenge;

      state.matchId = action.payload?.currentMatch?._id;
      state.backgroundTournament = action.payload?.currentTournament;
      state.matchId = action?.payload?.currentMatch?._id;
      state.loader = false;
    });
    builder.addCase(getCurrentMatchState.rejected, (state) => {
      state.backgroundMatch = null;
      state.matchId = "";
      state.backgroundTournament = null;
      state.backgroundChallenge = null;
      state.loader = false;
    });
    builder.addCase(getMatchChat.fulfilled, (state, action) => {
      state.matchChatSuccess = true;
      state.matchChat = action.payload;
      state.loader = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(getStream.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getStream.fulfilled, (state) => {
      state.loader = false;
    });
    builder.addCase(getStream.rejected, (state) => {
      state.loader = false;
    });
  },
});

export default matchMakingSlice.reducer;
export const {
  setSelectedGame,
  setDuelPath,
  setMatchSchedule,
  setMatchPlatform,
  setSelectedGameType,
  setSelectedGameTeam,
  setSelectedRound,
  setMatchCubes,
  setMatchFromHome,
  setConsole,
  setJoiningMatchId,
  setFromPresets,
  setDate,
  setPrivacy,
  clearDate,
  resetBackgroundMatch,
  setMatchStatus,
  setSelectedGameImage,
  updateMatchChatMessages,
  resetMatchmakingSlice,
  setSpectators,
  setEnableStaking,
  setTwitchConnectedMatch,
} = matchMakingSlice.actions;
