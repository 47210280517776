import { createSlice } from "@reduxjs/toolkit";
import { getEvents, getLives, getStakeAvailableEvents, getTournaments } from "./action";
import { signOut } from "store/auth/actions";

interface duelInterface {
  _id: string;
  matchType: string;
  coverImage: string;
  rounds: string;
  cubeWager: number;
  gameType: string;
  host: string;
  players: string[];
  designatedSpectators: string[];
  game: string;
  tournamentType: string;
  tournamentStatus: string;
  tournamentDate: string;
  __v: number;
  isEnded: boolean;
  matchAvailability: boolean;
  onGoing: boolean;
  requestedDuelUser: null | string;
  requestedUsers: any[];
  tournamentPlayers: any[];
  streamerId: string;
  messages: any[];
  scheduledTime: any;
}

interface EventState {
  duels: duelInterface[];
  lives: any[];
  tournaments: any[];
  stakingEvents: any[];
  errorMessage: string;
  loader: boolean;
}

const initialState: EventState = {
  duels: [],
  lives: [],
  tournaments: [],
  stakingEvents: [],
  errorMessage: "",
  loader: false,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    resetEventsData: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvents.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.duels = action.payload.duels;
      state.errorMessage = "";
      state.loader = false;
    });
    builder.addCase(getEvents.rejected, (state) => {
      state.errorMessage = "Failed to fetch events";
      state.loader = false;
    });
    builder.addCase(getLives.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getLives.fulfilled, (state, action) => {
      const allStreams: any = [];
      action.payload?.lives.forEach((item: any) => {
        item.streams.forEach((stream: any) => {
          allStreams.push({
            ...stream,
            game: item.game,
          });
        });
      });
      state.lives = allStreams;
      state.errorMessage = "";
      state.loader = false;
    });
    builder.addCase(getLives.rejected, (state) => {
      state.errorMessage = "Failed to fetch events";
      state.loader = false;
    });
    builder.addCase(getTournaments.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTournaments.fulfilled, (state, action) => {
      state.tournaments = action.payload?.tournaments;
      state.errorMessage = "";
      state.loader = false;
    });
    builder.addCase(getTournaments.rejected, (state) => {
      state.errorMessage = "Failed to fetch events";
      state.loader = false;
    });
    builder.addCase(getStakeAvailableEvents.fulfilled, (state, action) => {
      state.stakingEvents = action.payload.latestEvents;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default eventSlice.reducer;
export const { resetEventsData } = eventSlice.actions;
