import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "store/index";
import { useAppDispatch } from "store/hooks";
import BackIcon from "assets/arrow-right.svg";
import { numberRegex } from "constant/validations";
import { toast } from "react-toastify";
import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";
import { gameModes, setGameMode, setGameWager } from "store/physicalGames/physicalGamesSlice";

const SetupGameWagerScreen = () => {
    const [value, setValue] = useState("");
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();

    const onCancel = () => {
        navigate("/wagering");
    };

    const onBack = () => {
        navigate("/physical-games/game-type");
    };

    const handleNext = () => {
        dispatch(setGameMode(gameModes.PAID));
        const cubeValue = Number(value);
        if (cubeValue < 5 || cubeValue > 10000) {
            toast.warning("Please enter a value between $5 and $10,000.");
            return;
        }
        dispatch(setGameWager(cubeValue));
        navigate("/physical-games/overview");
    };


    const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue === '') return setValue('0');
        const decimalRegex = /^\d+(\.\d{0,2})?$/;
        if (!numberRegex.test(inputValue) && inputValue !== "") {
            toast.warning("Please enter only numeric values.");
            return;
        }
        if (!decimalRegex.test(inputValue)) {
            toast.warning("Please enter a value with no more than two decimal places.");
            return;
        }
        setValue(inputValue);
    };

    const handleFreePlay = () => {
        dispatch(setGameMode(gameModes.FREE_PLAY));
        dispatch(setGameWager(0));
        navigate("/physical-games/overview");
    }


    return (
        <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen ">
            <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
                <div
                    className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-120px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
                >
                    <div className="w-full rounded-t-[34px] flex items-center justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <button className="flex rotate-180 w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onBack}>
                                <img src={BackIcon} alt="Close" />
                            </button>
                            <span className="text-3xl text-white items-center font-normal max-md:text-[16px]">
                                Wagering Amount
                            </span>
                        </div>
                        <button className=" w-[25px] h-[25px] max-md:w-[15px] max-md:h-[15px]" onClick={onCancel}>
                            <img src={CloseIcon} alt="Close" />
                        </button>
                    </div>
                    <div className="flex h-[60vh] justify-center items-center flex-col gap-20 mt-5 max-md:mt-10">
                        <div className="flex justify-center items-center flex-col gap-8">
                            <span className="text-3xl text-white items-center font-normal max-md:text-[20px]">
                                What’s Your Wager?
                            </span>
                            <input
                                type="text"
                                className="flex flex-row items-center justify-center px-2.5 pb-2.5 pt-5 eve rounded-2xl mr-4 w-[300px] text-white outline-none border-b-2 text-center font-inter font-semibold desktop:text-5xl tracking-wide leading-normal text-5xl desktop:h-[120px] h-14 focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                                placeholder="$0.00"
                                value={value}
                                onChange={handleInputTitle}
                            />
                            <span className="text-subText text-center text-lg font-normal max-md:text-[12px]">
                                *  The min Cubes per player is $5, and the <br />max is $10,000
                            </span>
                            <span className="text-white text-center text-lg font-normal max-md:text-[14px]">
                                <span className="text-white font-bold">  Note:</span> Duel Cube retains a 5% fee to help improve the platform, ensuring continued support and a high-quality experience for players. The rest is awarded to the winner!
                            </span>
                        </div>
                        <div className="w-full justify-center flex flex-row max-md:flex-col gap-4 justify-center items-center">
                            <ButtonDc
                                varient={"outlined"}
                                text="Free Play"
                                action={handleFreePlay}
                            />
                            <ButtonDc
                                type="primary"
                                text="Next"
                                action={handleNext}
                                disabled={Number(value) < 5 || Number(value) > 10000}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetupGameWagerScreen;
