import { createSlice } from "@reduxjs/toolkit";
import {
  getCurrentMatch,
  getCurrentMatches,
  getCurrentMatchWithCallback,
  startMatch,
} from "./currentMatchActions";
import { GameTypes } from "types/constants";
import { signOut } from "store/auth/actions";

export type playerTypes = {
  _id: string;
  username: string;
  isChallengeAccepted?: boolean;
  isInvitationAccepted?: boolean;
  profilePicture: string;
  isReady?: boolean;
};

type currentMatchType = {
  _id?: string | null;
  matchId?: string | null;
  matchType: string | null;
  coverImage: string | null;
  rounds: string | null;
  cubeWager: number;
  gameType: GameTypes;
  host: any; //TODO
  invitedPlayers: any; //TODO
  players: playerTypes[]; //TODO
  team1: [];
  team2: [];
  payedUsers: [];
  refundedUsers: [];
  readyUsers: [];
  requestedUsers: [];
  designatedSpectators: [];
  game: any;
  community: any;
  tournament: "";
  matchAvailability: false;
  onGoing: false;
  isEnded: false;
  matchStatus: string;
  voteRounds: 0;
  stakeAvailability: false;
  team1StakeFactor: 0;
  team2StakeFactor: 0;
  notLastStage: true;
  scheduledTime: any;
  team1Votes: [];
  team2Votes: [];
  comments: [];
  messages: [];
  stakes: [];
  startTime: any;
  endTime: any;
  winner: number;
  streamerId: string | null;
  createdAt: any;
  thumbnail?: string;
  isPrivate?: boolean;
  stakingEnabled?: boolean;
  lobbyTimeout?: any;
};

type currentMatchSliceTypes = {
  loading: boolean;
  errorMessage: string;
  currentMatch: currentMatchType;
  screenStatus: string;
  isPaymentSuccess: boolean;
};

const initialState: currentMatchSliceTypes = {
  loading: false,
  errorMessage: "",
  currentMatch: {
    _id: null,
    matchId: null,
    matchType: null,
    coverImage: null,
    rounds: null,
    cubeWager: 0,
    gameType: "ONE_VS_ONE",
    host: null,
    invitedPlayers: [],
    players: [],
    team1: [],
    team2: [],
    payedUsers: [],
    refundedUsers: [],
    readyUsers: [],
    requestedUsers: [],
    designatedSpectators: [],
    game: {},
    community: {},
    tournament: "",
    matchAvailability: false,
    onGoing: false,
    isEnded: false,
    matchStatus: "",
    voteRounds: 0,
    stakeAvailability: false,
    team1StakeFactor: 0,
    team2StakeFactor: 0,
    notLastStage: true,
    scheduledTime: Date.now(),
    team1Votes: [],
    team2Votes: [],
    comments: [],
    messages: [],
    stakes: [],
    startTime: Date.now(),
    endTime: Date.now(),
    winner: 0,
    streamerId: null,
    createdAt: Date.now(),
    thumbnail: "",
    isPrivate: false,
    stakingEnabled: false,
    lobbyTimeout: new Date().toISOString(),
  },
  screenStatus: "",
  isPaymentSuccess: false,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    updatePlayerList: (state, action) => {
      state.currentMatch.players = action.payload;
    },
    setScreenStatus: (state, action) => {
      state.screenStatus = action.payload;
    },
    resetCurrentMatch: (state) => {
      state.currentMatch = {
        ...state.currentMatch,
        ...initialState.currentMatch,
      };
    },
    setIsPaymentSuccess: (state, action) => {
      state.isPaymentSuccess = action.payload;
    },
    setReVote: (state) => {},
    setWinner: (state, action) => {
      state.currentMatch.winner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentMatch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCurrentMatch.fulfilled, (state, action) => {
      state.currentMatch = action.payload;
      state.loading = false;
    });
    builder.addCase(getCurrentMatchWithCallback.fulfilled, (state, action) => {
      state.currentMatch = action.payload;
      state.loading = false;
    });
    builder.addCase(getCurrentMatches.rejected, (state, action) => {
      state.currentMatch = {
        ...initialState.currentMatch,
      };
      state.loading = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(startMatch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(startMatch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(startMatch.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default eventSlice.reducer;
export const {
  updatePlayerList,
  setScreenStatus,
  resetCurrentMatch,
  setIsPaymentSuccess,
  setWinner,
} = eventSlice.actions;
